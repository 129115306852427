import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-tabContent c-tabContent--noSpace" }
const _hoisted_2 = { class: "l-grid l-grid--splitV l-grid--gXl" }
const _hoisted_3 = { class: "l-grid__row" }
const _hoisted_4 = { class: "l-grid__item l-grid__item--6col" }
const _hoisted_5 = { class: "c-outlineBox c-outlineBox--sm" }
const _hoisted_6 = { class: "c-outlineBox__content" }
const _hoisted_7 = { class: "c-outlineBox c-outlineBox--sm" }
const _hoisted_8 = { class: "c-outlineBox__content" }
const _hoisted_9 = { class: "c-outlineBox c-outlineBox--sm" }
const _hoisted_10 = { class: "c-outlineBox__content" }
const _hoisted_11 = { class: "c-outlineBox c-outlineBox--sm" }
const _hoisted_12 = { class: "c-outlineBox__content" }
const _hoisted_13 = { class: "l-grid__item l-grid__item--6col" }
const _hoisted_14 = { class: "l-section l-section--md" }
const _hoisted_15 = { class: "l-section__head" }
const _hoisted_16 = { class: "l-section__actions" }
const _hoisted_17 = { class: "l-section__content" }
const _hoisted_18 = {
  ref: "pieChart",
  class: "c-pieChart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "c-outlineBox__head" }, [
              _createElementVNode("div", { class: "c-outlineBox__main" }, [
                _createElementVNode("div", { class: "c-outlineBox__title" }, "累積銷售總額")
              ]),
              _createElementVNode("div", { class: "c-outlineBox__desc" }, "門市＋線上店取")
            ], -1)),
            _createElementVNode("div", _hoisted_6, " ＄" + _toDisplayString(_ctx.formatNumber(
                  _ctx.getNumber(
                    _ctx.performance.storeSale +
                      _ctx.performance.storePre +
                      _ctx.performance.storeOnline +
                      _ctx.performance.storePreOnline +
                      _ctx.performance.storePartialPickup
                  )
                )), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "c-outlineBox__head" }, [
              _createElementVNode("div", { class: "c-outlineBox__main" }, [
                _createElementVNode("div", { class: "c-outlineBox__title" }, "平均銷售總額")
              ]),
              _createElementVNode("div", { class: "c-outlineBox__desc" }, "門市＋線上店取")
            ], -1)),
            _createElementVNode("div", _hoisted_8, " $" + _toDisplayString(_ctx.formatNumber(
                  _ctx.getNumber(
                    (_ctx.performance.storeSale +
                      _ctx.performance.storePre +
                      _ctx.performance.storePartialPickup +
                      _ctx.performance.storeOnline +
                      _ctx.performance.storePreOnline) /
                      Math.max(
                        _ctx.performance.quantity +
                          _ctx.performance.onlineQuantity +
                          _ctx.performance.preOnlineQuantity +
                          _ctx.performance.preQuantity +
                          _ctx.performance.partialPickupQuantity,
                        1
                      )
                  )
                )), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "c-outlineBox__head" }, [
              _createElementVNode("div", { class: "c-outlineBox__main" }, [
                _createElementVNode("div", { class: "c-outlineBox__title" }, "訂金總額")
              ]),
              _createElementVNode("div", { class: "c-outlineBox__desc" }, "門市")
            ], -1)),
            _createElementVNode("div", _hoisted_10, " $" + _toDisplayString(_ctx.formatNumber(_ctx.getNumber(_ctx.performance.unfinishPreOrderAmount))), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "c-outlineBox__head" }, [
              _createElementVNode("div", { class: "c-outlineBox__main" }, [
                _createElementVNode("div", { class: "c-outlineBox__title" }, "寄存總額")
              ]),
              _createElementVNode("div", { class: "c-outlineBox__desc" }, "門市")
            ], -1)),
            _createElementVNode("div", _hoisted_12, " $" + _toDisplayString(_ctx.formatNumber(_ctx.performance.storePartialPickup)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("ul", _hoisted_16, [
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "c-btn c-btn--primary c-btn--thin",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
                  }, _cache[5] || (_cache[5] = [
                    _createElementVNode("span", null, "Excel報表匯出", -1)
                  ]))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, null, 512)
            ])
          ])
        ])
      ])
    ])
  ]))
}