import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "c-listBox" }
const _hoisted_2 = { class: "c-listBox__head" }
const _hoisted_3 = { class: "c-listBox__main" }
const _hoisted_4 = { class: "c-listBox__subject" }
const _hoisted_5 = {
  key: 0,
  class: "c-listBox__action"
}
const _hoisted_6 = { class: "c-listBox__list" }
const _hoisted_7 = { class: "c-listBox__item" }
const _hoisted_8 = { class: "c-listBox__main" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "c-listBox__title" }
const _hoisted_11 = {
  key: 0,
  class: "c-listBox__minor"
}
const _hoisted_12 = {
  key: 0,
  class: "c-listBox__subList"
}

import type { PropType } from 'vue'
import { useSlots, ref, computed } from 'vue'

export interface IList {
  id: string
  title: string
  [key: string]: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'listBox',
  props: {
  title: {
    type: String,
    required: true
  },
  // 是否有收合
  hasCollapse: {
    type: Boolean,
    default: false
  },
  defaultCollapseOpenIds: {
    type: Array as PropType<string[]>
  },
  lists: {
    type: Array as PropType<IList[]>,
    required: true
  }
},
  setup(__props) {

const props = __props

// 收合功能
const openLists = ref<string[]>([])
openLists.value = props.defaultCollapseOpenIds || []

const toggleList = (id: string) => {
  const index = openLists.value.indexOf(id)

  if (index > -1) {
    openLists.value.splice(index, 1)
  } else {
    openLists.value.push(id)
  }
}

const hasListOpen = computed(() => {
  return (id: string) => {
    return openLists.value.includes(id)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(props.title), 1)
      ]),
      (!!useSlots().headSide)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "headSide")
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("ul", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.lists, (list, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: list.id,
          class: _normalizeClass({ 'is-open': hasListOpen.value(list.id) })
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (props.hasCollapse)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: ($event: any) => (toggleList(list.id)),
                    class: "c-listBox__action"
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["c-icon u-pointer", [hasListOpen.value(list.id) ? 'icon-minus' : 'icon-add']])
                    }, null, 2)
                  ], 8, _hoisted_9))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_10, _toDisplayString(list.title), 1)
            ]),
            (!!useSlots().minorSide)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _renderSlot(_ctx.$slots, "minorSide", { row: list })
                ]))
              : _createCommentVNode("", true)
          ]),
          (!!useSlots().collapseContent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _renderSlot(_ctx.$slots, "collapseContent", {
                  row: list,
                  index: index
                })
              ]))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ])
  ]))
}
}

})