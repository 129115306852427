import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect } from "vue"
import _imports_0 from '@/assets/images/others/noimg.svg'


const _hoisted_1 = { class: "c-tabContent" }
const _hoisted_2 = { class: "l-searchbar" }
const _hoisted_3 = { class: "l-searchbar__select" }
const _hoisted_4 = { class: "l-searchbar__keyword" }
const _hoisted_5 = { class: "l-searchbar__search" }
const _hoisted_6 = { class: "l-table u-mtMd" }
const _hoisted_7 = { class: "c-table" }
const _hoisted_8 = { class: "c-img" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { src: _imports_0 }
const _hoisted_11 = { style: {"max-width":"480px","width":"100%"} }
const _hoisted_12 = { class: "u-text-ellipsis-1 u-w100" }
const _hoisted_13 = {
  class: "u-bold",
  style: {"line-height":"32px"}
}
const _hoisted_14 = { class: "u-textCenter" }
const _hoisted_15 = { class: "u-textCenter" }
const _hoisted_16 = { class: "c-switch" }
const _hoisted_17 = ["onUpdate:modelValue", "onChange"]
const _hoisted_18 = { class: "l-tool" }
const _hoisted_19 = { class: "c-pager" }
const _hoisted_20 = { class: "c-pager__content" }
const _hoisted_21 = { class: "c-pager__select" }
const _hoisted_22 = ["value"]
const _hoisted_23 = { class: "c-pager__totalNum" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_checkbox_item = _resolveComponent("dropdown-checkbox-item")!
  const _component_dropdown_checkbox_toggle = _resolveComponent("dropdown-checkbox-toggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_dropdown_checkbox_toggle, {
          modelValue: _ctx.keywordTypes,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keywordTypes) = $event)),
          placeholder: "關鍵字類別"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dropdown_checkbox_item, { value: "productNo" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" 商品編號 ")
              ])),
              _: 1
            }),
            _createVNode(_component_dropdown_checkbox_item, { value: "productName" }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" 品名 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.keyword) = $event)),
          type: "text",
          class: "c-textBox c-textBox--full",
          placeholder: "請輸入關鍵字",
          onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.searchLists && _ctx.searchLists(...args)), ["enter"]))
        }, null, 544), [
          [
            _vModelText,
            _ctx.keyword,
            void 0,
            { trim: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          type: "button",
          class: "c-btn c-btn--primary c-btn--full",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.searchLists && _ctx.searchLists(...args)))
        }, _cache[10] || (_cache[10] = [
          _createElementVNode("span", null, "搜尋", -1)
        ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("table", _hoisted_7, [
        _cache[12] || (_cache[12] = _createElementVNode("colgroup", null, [
          _createElementVNode("col", { width: "80px" }),
          _createElementVNode("col", { width: "148px" }),
          _createElementVNode("col"),
          _createElementVNode("col", { width: "128px" }),
          _createElementVNode("col", { width: "100px" })
        ], -1)),
        _cache[13] || (_cache[13] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "商品圖"),
            _createElementVNode("th", null, "商品編號"),
            _createElementVNode("th", null, "品名"),
            _createElementVNode("th", { class: "u-textCenter" }, "價格"),
            _createElementVNode("th", { class: "u-textCenter" }, "APP顯示")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.products, (productList) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: productList.productId
            }, [
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_8, [
                  _withDirectives(_createElementVNode("img", {
                    src: productList.imgUrl
                  }, null, 8, _hoisted_9), [
                    [_vShow, productList.imgUrl !== '']
                  ]),
                  _withDirectives(_createElementVNode("img", _hoisted_10, null, 512), [
                    [_vShow, productList.imgUrl === '']
                  ])
                ])
              ]),
              _createElementVNode("td", null, _toDisplayString(productList.productNo), 1),
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("span", _hoisted_13, _toDisplayString(productList.productName), 1)
                  ])
                ])
              ]),
              _createElementVNode("td", _hoisted_14, "$" + _toDisplayString(_ctx.formatNumber(productList.price)), 1),
              _createElementVNode("td", _hoisted_15, [
                _createElementVNode("label", _hoisted_16, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    class: "c-switch__input",
                    "onUpdate:modelValue": ($event: any) => ((productList.isVisible) = $event),
                    onChange: ($event: any) => (
                    _ctx.switchAppVisible(
                      productList.productId,
                      productList.isVisible
                    )
                  )
                  }, null, 40, _hoisted_17), [
                    [_vModelCheckbox, productList.isVisible]
                  ]),
                  _cache[11] || (_cache[11] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                ])
              ])
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("span", {
          class: "c-pager__arrow",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.prePage && _ctx.prePage(...args)))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["icon-arrow_left c-icon c-icon--fsSm", [
              _ctx.paginationData.page > 1
                ? 'u-pointer c-icon--gy60'
                : 'u-notAllowed c-icon--gy40'
            ]])
          }, null, 2)
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _withDirectives(_createElementVNode("select", {
              class: "c-toolSelect",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.paginationData.page) = $event)),
              onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goPage($event.target.value)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.lastPage, (page) => {
                return (_openBlock(), _createElementBlock("option", {
                  value: page,
                  key: page
                }, _toDisplayString(page), 9, _hoisted_22))
              }), 128))
            ], 544), [
              [_vModelSelect, _ctx.paginationData.page]
            ])
          ]),
          _cache[14] || (_cache[14] = _createElementVNode("span", { class: "c-pager__text" }, "頁", -1)),
          _cache[15] || (_cache[15] = _createElementVNode("span", { class: "c-pager__separator" }, "，", -1)),
          _cache[16] || (_cache[16] = _createElementVNode("span", { class: "c-pager__text" }, "共", -1)),
          _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.paginationData.total), 1),
          _cache[17] || (_cache[17] = _createElementVNode("span", { class: "c-pager__text" }, "筆", -1))
        ]),
        _createElementVNode("span", {
          class: "c-pager__arrow",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["icon-arrow_right c-icon c-icon--fsSm", [
              _ctx.paginationData.page < _ctx.paginationData.lastPage
                ? 'u-pointer c-icon--gy60'
                : 'u-notAllowed c-icon--gy40'
            ]])
          }, null, 2)
        ])
      ])
    ])
  ]))
}