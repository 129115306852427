import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "l-card" }
const _hoisted_2 = { class: "c-outlineBox" }
const _hoisted_3 = { class: "c-outlineBox__content" }
const _hoisted_4 = { class: "l-table" }
const _hoisted_5 = { class: "c-wireTable" }
const _hoisted_6 = { class: "u-textCenter" }
const _hoisted_7 = { class: "c-wireTable__box" }
const _hoisted_8 = { class: "u-textCenter" }
const _hoisted_9 = { class: "c-wireTable__box u-textOg70" }
const _hoisted_10 = { class: "u-textCenter" }
const _hoisted_11 = { class: "c-wireTable__box" }
const _hoisted_12 = { class: "u-textCenter" }
const _hoisted_13 = { class: "c-wireTable__box u-textOg70" }
const _hoisted_14 = { class: "c-outlineBox" }
const _hoisted_15 = { class: "c-outlineBox__content" }
const _hoisted_16 = { class: "c-outlineBox__head" }
const _hoisted_17 = { class: "c-outlineBox__main" }
const _hoisted_18 = { class: "c-outlineBox__title" }
const _hoisted_19 = { class: "c-outlineBox__content" }
const _hoisted_20 = { class: "u-flex u-justifyContentBetween" }
const _hoisted_21 = { class: "u-bold" }
const _hoisted_22 = { class: "l-table u-mtMd" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "c-outlineBox__head" }, [
        _createElementVNode("div", { class: "c-outlineBox__main" }, [
          _createElementVNode("div", { class: "c-outlineBox__title" }, "目前使用狀況")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("table", _hoisted_5, [
            _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "項目"),
                _createElementVNode("th", { class: "u-textCenter" }, "可使用數"),
                _createElementVNode("th", { class: "u-textCenter" }, "已使用數")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _cache[0] || (_cache[0] = _createElementVNode("td", null, [
                  _createElementVNode("span", { class: "c-wireTable__name" }, "授權帳號")
                ], -1)),
                _createElementVNode("td", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.storeQuantity ?? 0), 1)
                ]),
                _createElementVNode("td", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.usedStoreQuantity ?? 0), 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[1] || (_cache[1] = _createElementVNode("td", null, [
                  _createElementVNode("span", { class: "c-wireTable__name" }, "授權設備")
                ], -1)),
                _createElementVNode("td", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.deviceQuantity ?? 0), 1)
                ]),
                _createElementVNode("td", _hoisted_12, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.usedDeviceQuantity ?? 0), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "c-outlineBox__head" }, [
        _createElementVNode("div", { class: "c-outlineBox__main" }, [
          _createElementVNode("div", { class: "c-outlineBox__title" }, "門市設備使用狀況")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_15, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeDevices, (storeDevice, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["c-outlineBox", { 'c-outlineBox--og': index === 0 }])
          }, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(storeDevice.storeName), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("p", null, [
                  _cache[4] || (_cache[4] = _createTextVNode(" POS帳號： ")),
                  _createElementVNode("span", _hoisted_21, _toDisplayString(storeDevice.account), 1)
                ]),
                _createElementVNode("p", null, "設備使用數量：" + _toDisplayString(storeDevice.devices.length), 1)
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("table", {
                  class: _normalizeClass(["c-table", { 'c-table--og': index === 0 }])
                }, [
                  _cache[5] || (_cache[5] = _createElementVNode("colgroup", null, [
                    _createElementVNode("col", { width: "200px" }),
                    _createElementVNode("col"),
                    _createElementVNode("col", { width: "200px" })
                  ], -1)),
                  _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "設備名稱"),
                      _createElementVNode("th", null, "設備編號"),
                      _createElementVNode("th", null, "ADD-ON 加值項目")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(storeDevice.devices, (device, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: device.id,
                        class: _normalizeClass({ 'u-bold': index === 0 })
                      }, [
                        _createElementVNode("td", null, _toDisplayString(device.name), 1),
                        _createElementVNode("td", null, _toDisplayString(device.id), 1),
                        _createElementVNode("td", null, _toDisplayString(device.addonName), 1)
                      ], 2))
                    }), 128))
                  ])
                ], 2)
              ])
            ])
          ], 2))
        }), 128))
      ])
    ])
  ]))
}