import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-section" }
const _hoisted_2 = { class: "l-section__content" }
const _hoisted_3 = { class: "c-stripCard" }
const _hoisted_4 = { class: "c-stripCard__head" }
const _hoisted_5 = { class: "c-stripCard__item" }
const _hoisted_6 = { class: "c-stripCard__actions" }
const _hoisted_7 = { class: "c-gadget c-gadget--gLg" }
const _hoisted_8 = { class: "c-switch" }
const _hoisted_9 = { class: "c-stripCard__actions" }
const _hoisted_10 = { class: "c-gadget c-gadget--gLg" }
const _hoisted_11 = { class: "c-countBtn" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = ["min", "max"]
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "c-stripCard__item" }
const _hoisted_16 = { class: "c-stripCard__actions" }
const _hoisted_17 = { class: "c-gadget c-gadget--gLg" }
const _hoisted_18 = { class: "c-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_head = _resolveComponent("app-head")!
  const _component_Fixed = _resolveComponent("Fixed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_app_head, { "prop-title": _ctx.propTitle }, null, 8, ["prop-title"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "c-stripCard__main" }, [
                _createElementVNode("div", { class: "c-stripCard__title" }, "交易明細列印")
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("ul", _hoisted_7, [
                  _createElementVNode("li", null, [
                    _createElementVNode("label", _hoisted_8, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        class: "c-switch__input",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.storePrinter.isPrintOrderList) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.storePrinter.isPrintOrderList]
                      ]),
                      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["c-stripCard__item", [_ctx.storePrinter.isPrintOrderList ? '' : 'u-hidden']])
            }, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "c-stripCard__main" }, [
                _createElementVNode("div", { class: "c-stripCard__title" }, "交易明細列印數"),
                _createElementVNode("div", { class: "c-stripCard__desc" }, " 如訂單使用統一編號，交易明細將與發票/收據一同列印，單據不裁切，列印張數為一張。 ")
              ], -1)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("ul", _hoisted_10, [
                  _createElementVNode("li", null, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "c-countBtn__btn",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.minusPrinterOrderListCount && _ctx.minusPrinterOrderListCount(...args))),
                        disabled: 
                        _ctx.storePrinter.orderListQuantity <=
                        _ctx.orderListQuantitySetting.min
                      
                      }, _cache[7] || (_cache[7] = [
                        _createElementVNode("i", { class: "icon-minus c-icon c-icon--fsXs c-icon--wt" }, null, -1)
                      ]), 8, _hoisted_12),
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        class: "c-countBtn__input",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.storePrinter.orderListQuantity) = $event)),
                        min: _ctx.orderListQuantitySetting.min,
                        max: _ctx.orderListQuantitySetting.max,
                        readonly: ""
                      }, null, 8, _hoisted_13), [
                        [_vModelText, _ctx.storePrinter.orderListQuantity]
                      ]),
                      _createElementVNode("button", {
                        type: "button",
                        class: "c-countBtn__btn",
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.addPrinterOrderListCount && _ctx.addPrinterOrderListCount(...args))),
                        disabled: 
                        _ctx.storePrinter.orderListQuantity >=
                        _ctx.orderListQuantitySetting.max
                      
                      }, _cache[8] || (_cache[8] = [
                        _createElementVNode("i", { class: "icon-add c-icon c-icon--fsXs c-icon--wt" }, null, -1)
                      ]), 8, _hoisted_14)
                    ])
                  ])
                ])
              ])
            ], 2),
            _createElementVNode("div", _hoisted_15, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "c-stripCard__main" }, [
                _createElementVNode("div", { class: "c-stripCard__title" }, "交易明細-金額為商品數量總計")
              ], -1)),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("ul", _hoisted_17, [
                  _createElementVNode("li", null, [
                    _createElementVNode("label", _hoisted_18, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        class: "c-switch__input",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.storePrinter.isCalculateProdPrice) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.storePrinter.isCalculateProdPrice]
                      ]),
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Fixed, {
      onSave: _ctx.save,
      onCancel: _ctx.cancel
    }, null, 8, ["onSave", "onCancel"])
  ], 64))
}