
import { defineComponent, reactive, computed, watch, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from '@/http'
import Fixed from '@/views/components/fixed.vue'
import { employeeForm, notifyInstance } from '@/type'

export default defineComponent({
  emits: ['refresh'],
  props: ['propEmployees', 'propGroups', 'employeeId'],
  components: {
    Fixed
  },
  setup(props, { emit }) {
    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const router = useRouter()
    const employee = reactive({
      name: '',
      email: '',
      no: '',
      password: null,
      groupId: null
    } as employeeForm)

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    function init() {
      Object.assign(
        employee,
        props.propEmployees.find((propEmployee: any) => {
          return parseInt(propEmployee.id) === parseInt(props.employeeId)
        })
      )

      const group = props.propGroups.find((group: any) => {
        return group.employeeIds.includes(parseInt(props.employeeId))
      })

      employee.groupId = group ? group.id : null
    }

    function save() {
      axios
        .patch(`/employees/${storeId.value}`, {
          employee: employee
        })
        .then((res) => {
          router.push({
            name: 'refresh'
          })
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function cancel() {
      init()
    }

    function remove() {
      axios
        .delete(`/employees/${storeId.value}/${props.employeeId}`)
        .then((res) => {
          emit('refresh')
          router.push({
            name: 'authPermission'
          })
          notify(
            {
              classType: 'success',
              message: '刪除成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `刪除失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function inputPassword(event: any) {
      const value = event.target.value
      employee.password = value.replace(/\D/g, '')
    }

    watch(
      () => props.propGroups,
      () => {
        init()
      },
      {
        deep: true
      }
    )

    watch(
      () => props.propEmployees,
      () => {
        init()
      },
      {
        deep: true
      }
    )

    init()

    return {
      employee,
      save,
      cancel,
      remove,
      inputPassword
    }
  }
})
