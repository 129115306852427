import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-section u-mvSm u-mhMd" }
const _hoisted_2 = { class: "l-section__content" }
const _hoisted_3 = { class: "l-formGroup" }
const _hoisted_4 = { class: "l-formGroup__content" }
const _hoisted_5 = { class: "l-formGroup__input" }
const _hoisted_6 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("label", { class: "l-formGroup__title l-formGroup__title--strong" }, [
          _createElementVNode("span", null, "快速註冊連結")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("input", {
              type: "text",
              class: "c-textBox",
              readonly: "",
              value: _ctx.shopsUrl + 'index/member_login?type=posLogin',
              style: {"max-width":"70%","margin-right":"20px"}
            }, null, 8, _hoisted_6),
            _createElementVNode("button", {
              type: "button",
              class: "c-btn c-btn--primary",
              style: {"max-width":"10%"},
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)))
            }, " 複製 ")
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("p", {
            class: "u-mbSm",
            style: {"margin-top":"10px"}
          }, " 可應用於LINE@官方帳號或製作QRcode供客人掃描 ", -1))
        ])
      ])
    ])
  ]))
}