import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-section u-mvSm u-mhMd" }
const _hoisted_2 = { class: "l-section__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "l-section__head" }, [
      _createElementVNode("div", { class: "l-section__main" }, [
        _createElementVNode("div", { class: "l-section__title" }, [
          _createElementVNode("h1", { class: "c-heading" }, "離職員工名單")
        ])
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        class: "c-btn c-btn--lg c-btn--primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("span", null, "Excel 報表匯出", -1)
      ]))
    ])
  ]))
}