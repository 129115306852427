import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "c-tabContent" }
const _hoisted_2 = { class: "l-gradientCard" }
const _hoisted_3 = { class: "l-gradientCard__item" }
const _hoisted_4 = { class: "c-gradientCard" }
const _hoisted_5 = { class: "c-gradientCard__main" }
const _hoisted_6 = { class: "c-gradientCard__num" }
const _hoisted_7 = { class: "l-gradientCard__item" }
const _hoisted_8 = { class: "c-gradientCard" }
const _hoisted_9 = { class: "c-gradientCard__main" }
const _hoisted_10 = { class: "c-gradientCard__num" }
const _hoisted_11 = { class: "l-gradientCard__item" }
const _hoisted_12 = { class: "c-gradientCard" }
const _hoisted_13 = { class: "c-gradientCard__main" }
const _hoisted_14 = { class: "c-gradientCard__num" }
const _hoisted_15 = { class: "l-gradientCard__item" }
const _hoisted_16 = { class: "c-gradientCard" }
const _hoisted_17 = { class: "c-gradientCard__main" }
const _hoisted_18 = { class: "c-gradientCard__num" }
const _hoisted_19 = { class: "l-section l-section--md" }
const _hoisted_20 = { class: "l-section__content" }
const _hoisted_21 = { class: "l-table" }
const _hoisted_22 = { class: "c-table c-table--mutiData" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "u-textRight" }
const _hoisted_25 = { class: "c-pager" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "c-pager__content" }
const _hoisted_28 = { class: "c-pager__select" }
const _hoisted_29 = ["onUpdate:modelValue", "onChange"]
const _hoisted_30 = ["value"]
const _hoisted_31 = { class: "c-pager__totalNum" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "c-pager" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { class: "c-table__accordion" }
const _hoisted_36 = { colspan: "6" }
const _hoisted_37 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
              _createElementVNode("span", null, "累積小結營業淨額")
            ], -1)),
            _createElementVNode("div", _hoisted_6, " ＄" + _toDisplayString(_ctx.formatNumber(_ctx.totalAmount)), 1)
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "c-gradientCard__icon c-gradientCard__icon--og" }, [
            _createElementVNode("i", { class: "icon-money_bag c-icon c-icon--fsLg c-icon--og60" })
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
              _createElementVNode("span", null, "累積小結作廢金額")
            ], -1)),
            _createElementVNode("div", _hoisted_10, " ＄" + _toDisplayString(_ctx.formatNumber(_ctx.totalVoidedAmount)), 1)
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "c-gradientCard__icon c-gradientCard__icon--rd" }, [
            _createElementVNode("i", { class: "icon-block c-icon c-icon--fsLg c-icon--rd40" })
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
              _createElementVNode("span", null, "累積小結短溢")
            ], -1)),
            _createElementVNode("div", _hoisted_14, " ＄" + _toDisplayString(_ctx.formatNumber(_ctx.totalOverShort)), 1)
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "c-gradientCard__icon c-gradientCard__icon--bu" }, [
            _createElementVNode("i", { class: "icon-coin c-icon c-icon--fsLg c-icon--bu40" })
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
              _createElementVNode("span", null, "累積小結次數")
            ], -1)),
            _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.totalTimes), 1)
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "c-gradientCard__icon c-gradientCard__icon--gn" }, [
            _createElementVNode("i", { class: "icon-document c-icon c-icon--fsLg c-icon--gn50" })
          ], -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "l-section__head" }, [
        _createElementVNode("div", { class: "l-section__main" }, [
          _createElementVNode("div", { class: "l-section__title" }, [
            _createElementVNode("h2", { class: "c-heading c-heading--fsMd c-heading--fwNormal" }, " 所有支付小結 ")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("table", _hoisted_22, [
            _cache[16] || (_cache[16] = _createElementVNode("colgroup", null, [
              _createElementVNode("col", { width: "50px" }),
              _createElementVNode("col", { width: "200px" }),
              _createElementVNode("col", { width: "200px" }),
              _createElementVNode("col", { width: "200px" }),
              _createElementVNode("col", { width: "200px" }),
              _createElementVNode("col")
            ], -1)),
            _cache[17] || (_cache[17] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th"),
                _createElementVNode("th", null, "支付方式"),
                _createElementVNode("th", null, "累積小結營業淨額"),
                _createElementVNode("th", null, "累積作廢營業金額"),
                _createElementVNode("th", null, "累積小結短溢"),
                _createElementVNode("th")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkOutRecords, (group, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createElementVNode("tr", {
                    class: _normalizeClass([group.isOpen ? 'is-open' : ''])
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("i", {
                        onClick: ($event: any) => (_ctx.GroupClick(group)),
                        class: _normalizeClass(["c-icon c-icon--fsXs c-icon--gy60 u-pointer", [group.isOpen ? 'icon-minus' : 'icon-add']])
                      }, null, 10, _hoisted_23)
                    ]),
                    _createElementVNode("td", {
                      class: _normalizeClass([group.isEnable ? 'u-textOg50' : ''])
                    }, _toDisplayString(_ctx.paymentsMap[group.payment] ?? group.payment), 3),
                    _createElementVNode("td", null, "$" + _toDisplayString(_ctx.formatNumber(group.totalAmount ?? 0)), 1),
                    _createElementVNode("td", null, "$" + _toDisplayString(_ctx.formatNumber(group.totalVoidedAmount ?? 0)), 1),
                    _createElementVNode("td", null, "$" + _toDisplayString(_ctx.formatNumber(group.totalOverShort ?? 0)), 1),
                    _createElementVNode("td", _hoisted_24, [
                      _withDirectives(_createElementVNode("div", _hoisted_25, [
                        (group.pagination !== null)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("span", {
                                class: "c-pager__arrow",
                                onClick: ($event: any) => (
                            _ctx.prePage(group.pagination, group.paginationData)
                          )
                              }, [
                                _createElementVNode("i", {
                                  class: _normalizeClass(["icon-arrow_left c-icon c-icon--fsSm", [
                              group.paginationData.page > 1
                                ? 'u-pointer c-icon--gy60'
                                : 'u-notAllowed c-icon--gy40'
                            ]])
                                }, null, 2)
                              ], 8, _hoisted_26),
                              _createElementVNode("div", _hoisted_27, [
                                _createElementVNode("div", _hoisted_28, [
                                  _withDirectives(_createElementVNode("select", {
                                    class: "c-toolSelect",
                                    "onUpdate:modelValue": ($event: any) => ((group.paginationData.page) = $event),
                                    onChange: ($event: any) => (
                                _ctx.goPage(
                                  group.pagination,
                                  group.paginationData,
                                  $event.target.value
                                )
                              )
                                  }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.paginationData.lastPage, (page) => {
                                      return (_openBlock(), _createElementBlock("option", {
                                        value: page,
                                        key: page
                                      }, _toDisplayString(page), 9, _hoisted_30))
                                    }), 128))
                                  ], 40, _hoisted_29), [
                                    [_vModelSelect, group.paginationData.page]
                                  ])
                                ]),
                                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "c-pager__text" }, "頁", -1)),
                                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "c-pager__separator" }, "，", -1)),
                                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "c-pager__text" }, "共", -1)),
                                _createElementVNode("span", _hoisted_31, _toDisplayString(group.paginationData.total), 1),
                                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "c-pager__text" }, "筆", -1))
                              ]),
                              _createElementVNode("span", {
                                class: "c-pager__arrow",
                                onClick: ($event: any) => (
                            _ctx.nextPage(group.pagination, group.paginationData)
                          )
                              }, [
                                _createElementVNode("i", {
                                  class: _normalizeClass(["icon-arrow_right c-icon c-icon--fsSm", [
                              group.paginationData.page <
                              group.paginationData.lastPage
                                ? 'u-pointer c-icon--gy60'
                                : 'u-notAllowed c-icon--gy40'
                            ]])
                                }, null, 2)
                              ], 8, _hoisted_32)
                            ], 64))
                          : _createCommentVNode("", true)
                      ], 512), [
                        [_vShow, group.isOpen]
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_33, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "c-btn c-btn--primary c-btn--thin",
                          onClick: ($event: any) => (_ctx.exportExcelClick(group.payment))
                        }, _cache[12] || (_cache[12] = [
                          _createElementVNode("span", null, "Excel報表匯出", -1)
                        ]), 8, _hoisted_34)
                      ], 512), [
                        [_vShow, !group.isOpen]
                      ])
                    ])
                  ], 2),
                  _createElementVNode("tr", _hoisted_35, [
                    _createElementVNode("td", _hoisted_36, [
                      _createElementVNode("table", null, [
                        _cache[14] || (_cache[14] = _createElementVNode("colgroup", null, [
                          _createElementVNode("col", { width: "50px" }),
                          _createElementVNode("col", { width: "200px" }),
                          _createElementVNode("col", { width: "200px" }),
                          _createElementVNode("col", { width: "200px" }),
                          _createElementVNode("col")
                        ], -1)),
                        _cache[15] || (_cache[15] = _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th"),
                            _createElementVNode("th", null, "操作時間"),
                            _createElementVNode("th", null, "營業淨額"),
                            _createElementVNode("th", null, "作廢金額"),
                            _createElementVNode("th", null, "小結短溢")
                          ])
                        ], -1)),
                        (group.pagination !== null)
                          ? (_openBlock(), _createElementBlock("tbody", _hoisted_37, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.paginationData
                            .handOverRecords, (handOverRecord, index) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: `${group.paginationData.currentPage}_${index}`
                                }, [
                                  _cache[13] || (_cache[13] = _createElementVNode("td", null, null, -1)),
                                  _createElementVNode("td", null, _toDisplayString(handOverRecord.endAt), 1),
                                  _createElementVNode("td", null, " $" + _toDisplayString(_ctx.formatNumber(handOverRecord.totalAmount)), 1),
                                  _createElementVNode("td", null, " $" + _toDisplayString(_ctx.formatNumber(handOverRecord.totalVoidedAmount)), 1),
                                  _createElementVNode("td", null, " $" + _toDisplayString(_ctx.formatNumber(handOverRecord.totalOverShort)), 1)
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ], 64))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}