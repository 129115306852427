import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/others/error.svg'
import _imports_1 from '@/assets/images/logo/waca_logo.svg'


const _hoisted_1 = { class: "l-statusPage" }
const _hoisted_2 = { class: "l-statusPage__content" }
const _hoisted_3 = { class: "l-statusPage__wrap" }
const _hoisted_4 = { class: "c-statusPage" }
const _hoisted_5 = { class: "c-statusPage__actions" }
const _hoisted_6 = { class: "l-actions" }
const _hoisted_7 = { class: "l-actions__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"c-statusPage__icon\" data-v-1b802a62><img src=\"" + _imports_0 + "\" alt=\"notFound\" data-v-1b802a62></div><div class=\"c-statusPage__content\" data-v-1b802a62><div class=\"c-statusPage__title\" data-v-1b802a62>404</div><div class=\"c-statusPage__subTitle\" data-v-1b802a62>Page Not Found</div><div class=\"c-statusPage__subTitle\" data-v-1b802a62>抱歉，你所指定的頁面不存在</div></div>", 2)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  class: "c-btn c-btn--secondary",
                  to: { name: 'index' }
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("span", null, "回首頁", -1)
                  ])),
                  _: 1
                })
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "l-actions__item" }, [
                _createElementVNode("button", {
                  type: "button",
                  class: "c-btn c-btn--secondary"
                }, [
                  _createElementVNode("span", null, "聯絡我們")
                ])
              ], -1))
            ])
          ])
        ])
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "l-statusPage__footer" }, [
      _createElementVNode("div", { class: "l-statusPage__wrap" }, [
        _createElementVNode("a", { href: "#" }, [
          _createElementVNode("img", {
            src: _imports_1,
            class: "c-logo",
            alt: "logo"
          })
        ])
      ])
    ], -1))
  ]))
}