import { createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: ".l-container"
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "l-mask" }, [
          _createElementVNode("div", { class: "l-mask__container" }, [
            _createElementVNode("div", { class: "c-loading" }, [
              _createElementVNode("div", { class: "c-loading__item" }),
              _createElementVNode("div", { class: "c-loading__item" }),
              _createElementVNode("div", { class: "c-loading__item" })
            ])
          ])
        ], -1))
      ]))
    : _createCommentVNode("", true)
}