import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-modal__header" }
const _hoisted_2 = {
  key: 0,
  class: "c-modal__remind"
}
const _hoisted_3 = { class: "c-alertBox" }
const _hoisted_4 = { class: "c-alertBox__text" }
const _hoisted_5 = { class: "l-formGroup l-formGroup--vertical" }
const _hoisted_6 = { class: "l-formGroup__content" }
const _hoisted_7 = { class: "l-formGroup__input" }
const _hoisted_8 = { class: "l-formGroup l-formGroup--vertical" }
const _hoisted_9 = { class: "l-formGroup__content" }
const _hoisted_10 = { class: "l-formGroup__input" }
const _hoisted_11 = { class: "l-actions__item" }
const _hoisted_12 = {
  key: 0,
  class: "l-actions__item"
}
const _hoisted_13 = ["disabled"]

import { ref, reactive, watch, inject } from 'vue'
import axios from '@/http'
import { modalInstance } from '@/type'
import Lightbox from '@/views/components/lightbox.vue'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'forgetPwdLightbox',
  setup(__props, { expose: __expose }) {

const modal = inject('modal') as modalInstance
const router = useRouter()

const lightboxRef = ref()
const resetMailErrorMessage = ref(null)
const isSendForget = ref(false)

const forgetPwdModal = reactive({
  modalSize: 'md',
  contentAlign: 'left'
})

const forgetPwdInfo = reactive({
  shopsUrl: ref(''),
  email: ref('')
})

// 忘記密碼disable狀態
const forgetButtonDisabled = ref(true)
watch(forgetPwdInfo, () => {
  if (
    forgetPwdInfo.shopsUrl.trim() !== '' &&
    forgetPwdInfo.email.trim() !== ''
  ) {
    forgetButtonDisabled.value = false
  } else {
    forgetButtonDisabled.value = true
  }
})

// 驗證忘記密碼表單
function submitForgetPwd() {
  resetMailErrorMessage.value = null

  axios
    .post('/resetMail', {
      email: forgetPwdInfo.email,
      shopsUrl: forgetPwdInfo.shopsUrl
    })
    .then((res) => {
      // 成功送出後
      resetMailErrorMessage.value = null
      isSendForget.value = true

      modal({
        classType: 'alert',
        cancelBtn: false,
        content:
          '我們已將認證信件寄至您的信箱<br> 請前往收取並於10分鐘內修改密碼，謝謝。',
        confirmBtnClick: () => {
          lightboxRef.value.close('modal')
          router.go(0)
        }
      }).show()
    })
    .catch((err) => {
      resetMailErrorMessage.value = err.response.errors.detail
    })
}

function resetForgetPwd() {
  forgetPwdInfo.shopsUrl = ''
  forgetPwdInfo.email = ''
  resetMailErrorMessage.value = null
}

__expose({
  lightboxRef
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Lightbox, _mergeProps({
    ref_key: "lightboxRef",
    ref: lightboxRef
  }, forgetPwdModal, { onReset: resetForgetPwd }), _createSlots({
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "c-modal__title" }, "忘記密碼", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "c-modal__desc" }, " 請輸入商店網址及 E-mail , 我們將協助您重新設定密碼 ", -1)),
        (resetMailErrorMessage.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "c-alertBox__icon" }, [
                  _createElementVNode("i", { class: "icon-alert" })
                ], -1)),
                _createElementVNode("span", _hoisted_4, _toDisplayString(resetMailErrorMessage.value), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    button: _withCtx(() => [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("button", {
          type: "button",
          class: "c-btn c-btn--bu",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (lightboxRef.value.close('modal')))
        }, _cache[8] || (_cache[8] = [
          _createElementVNode("span", null, "關閉", -1)
        ]))
      ]),
      (!isSendForget.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("button", {
              type: "button",
              class: "c-btn c-btn--primary",
              onClick: submitForgetPwd,
              disabled: forgetButtonDisabled.value
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("span", null, "確定送出", -1)
            ]), 8, _hoisted_13)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (!isSendForget.value)
      ? {
          name: "content",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _cache[6] || (_cache[6] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                _createElementVNode("span", null, "商店網址")
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "c-textBox c-textBox--full",
                    placeholder: "請輸入商店網址",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((forgetPwdInfo.shopsUrl) = $event))
                  }, null, 512), [
                    [_vModelText, forgetPwdInfo.shopsUrl]
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[7] || (_cache[7] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                _createElementVNode("span", null, "E-mail")
              ], -1)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "c-textBox c-textBox--full",
                    placeholder: "請輸入E-mail",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((forgetPwdInfo.email) = $event))
                  }, null, 512), [
                    [_vModelText, forgetPwdInfo.email]
                  ])
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1040))
}
}

})