import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-tabContent" }
const _hoisted_2 = { class: "l-table" }
const _hoisted_3 = { class: "c-mutiTable" }
const _hoisted_4 = { class: "c-mutiTable__input" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "u-textCenter" }
const _hoisted_7 = { class: "c-mutiTable__action" }
const _hoisted_8 = { class: "c-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fixed = _resolveComponent("Fixed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _cache[6] || (_cache[6] = _createElementVNode("colgroup", null, [
            _createElementVNode("col", { width: "222px" }),
            _createElementVNode("col"),
            _createElementVNode("col", { width: "80px" }),
            _createElementVNode("col", { width: "80px" })
          ], -1)),
          _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "店名"),
              _createElementVNode("th", null, "POS帳號"),
              _createElementVNode("th", { class: "u-textCenter" }, "啟用"),
              _createElementVNode("th", { class: "u-textCenter" })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_4, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.storeName) = $event)),
                    placeholder: "請輸入店名",
                    class: "c-textBox"
                  }, null, 512), [
                    [_vModelText, _ctx.storeName]
                  ])
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("select", {
                  class: "c-select",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.storeEmail) = $event))
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("option", {
                    value: "",
                    hidden: ""
                  }, "請選擇帳號", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectEmails, (email, index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: index,
                      value: email
                    }, _toDisplayString(email), 9, _hoisted_5))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _ctx.storeEmail]
                ])
              ]),
              _createElementVNode("td", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      class: "c-switch__input",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isEnable) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.isEnable]
                    ]),
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                  ])
                ])
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("td", { class: "u-textCenter" }, null, -1))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Fixed, {
      onSave: _ctx.save,
      onCancel: _ctx.cancel
    }, null, 8, ["onSave", "onCancel"])
  ], 64))
}