import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "c-tabs" }
const _hoisted_2 = { key: 0 }

import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import store from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'nav',
  setup(__props) {

const route = useRoute()
const nowStore = computed(() => store.getters.nowStore)

const categorys = ref([
  [
    {
      routeName: 'authStore',
      title: '門市設置',
      activeRoutes: ['authStore', 'authStoreCreate'],
      requiredNowStore: false
    },
    {
      routeName: 'authPermission',
      title: '權限管理',
      activeRoutes: [
        'authPermission',
        'authGroup',
        'authGroupCreate',
        'authEmployee',
        'authEmployeeCreate',
        'authResigned'
      ],
      requiredNowStore: true
    }
  ],
  [
    {
      routeName: 'authPOS',
      title: '權限管理',
      activeRoutes: ['authPOS'],
      requiredNowStore: true
    }
  ]
])

const tabs = computed(() => {
  return categorys.value.find((category) => {
    return category.some((item) =>
      item.activeRoutes.includes(route.name as string)
    )
  })
})

function show(tab: any) {
  if (tab.requiredNowStore && !nowStore.value.id) {
    return false
  }
  return true
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value, (tab) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: tab.routeName
      }, [
        (show(tab))
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createVNode(_component_router_link, {
                to: { name: tab.routeName },
                class: _normalizeClass(["c-tabs__panel", {
          'is-active': tab.activeRoutes.includes(_unref(route).name as string)
        }])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(tab.title), 1)
                ]),
                _: 2
              }, 1032, ["to", "class"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}
}

})