
import { defineComponent, ref, computed, inject, watch } from 'vue'
import { useRoute } from 'vue-router'
import axios from '@/http'
import { useStore } from 'vuex'
import { notifyInstance } from '@/type'
import elDialog from '@/views/components/dialog.vue'
import elDescription from '@/views/components/description.vue'

interface Promotion {
  id: number
  name: string
  startAt: string | null
  endAt: string | null
  availablePeriod: string
  channelDescription: string
  usedTarget: string
  slogan: string
  isProgression: boolean
  isIncrementProgression: boolean
  sort: number
  isEnable: boolean
}

export default defineComponent({
  components: {
    elDialog,
    elDescription
  },
  setup() {
    const notify = inject('notify') as notifyInstance

    const route = useRoute()

    const promotionTypeMapping = {
      // route name
      storeActivity_promotionsN: {
        api: 'promotionsN',
        label: '任選'
      },
      storeActivity_promotionsAB: {
        api: 'promotionsAB',
        label: '紅配綠'
      }
    }

    const promotionType = computed(() =>
      promotionTypeMapping[route.name as keyof typeof promotionTypeMapping]
    )

    const store = useStore()
    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const promotionDetailModalVisible = ref(false)
    const promotionDetailModalTitle = ref('')
    const promotionDetailModalList = ref<Promotion | null>(null)

    const promotions = ref<Promotion[]>([])

    promotions.value.sort((a, b) => {
      if (a.isEnable !== b.isEnable) {
        return Number(b.isEnable) - Number(a.isEnable)
      }

      return (
        new Date(a.endAt as string).getTime() -
        new Date(b.endAt as string).getTime()
      )
    })

    function viewPromotionDetail(promotion: Promotion) {
      promotionDetailModalVisible.value = true
      promotionDetailModalTitle.value = promotion.name
      promotionDetailModalList.value = promotion
    }

    function switchPromotionEnable(promotion: Promotion) {
      axios
        .post(`/store/${storeId.value}/promotions/status`, {
          status: promotion.isEnable ? 1 : 0,
          promotionId: promotion.id,
          promotionType: promotionType.value.api
        })
        .then(() => {
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function init() {
      axios
        .get(
          `/store/${storeId.value}/promotions/${promotionType.value.api
            .replace('promotions', '')
            .toLocaleLowerCase()}`
        )
        .then((res) => {
          promotions.value = res.data.items
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    init()

    watch(
      () => route.name,
      () => {
        if (
          promotionTypeMapping[route.name as keyof typeof promotionTypeMapping]
        ) {
          init()
        }
      }
    )

    return {
      promotionDetailModalVisible,
      promotionDetailModalTitle,
      promotionDetailModalList,
      promotions,
      viewPromotionDetail,
      switchPromotionEnable,
      promotionType
    }
  }
})
