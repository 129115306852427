import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "l-section" }
const _hoisted_2 = { class: "l-section__content" }
const _hoisted_3 = { class: "c-stripCard is-open" }
const _hoisted_4 = { class: "c-stripCard__head" }
const _hoisted_5 = { class: "c-stripCard__item" }
const _hoisted_6 = { class: "c-stripCard__actions" }
const _hoisted_7 = { class: "c-gadget c-gadget--gLg" }
const _hoisted_8 = { class: "u-textGy60" }
const _hoisted_9 = {
  key: 0,
  class: "c-stripCard__accordion"
}
const _hoisted_10 = { class: "c-stripBox c-stripBox--lg" }
const _hoisted_11 = { class: "c-stripBox__content" }
const _hoisted_12 = { class: "l-form u-mvLg" }
const _hoisted_13 = { class: "l-form__wrap" }
const _hoisted_14 = { class: "l-formGroup" }
const _hoisted_15 = { class: "l-formGroup__content" }
const _hoisted_16 = { class: "l-formGroup__input" }
const _hoisted_17 = { class: "l-formGroup" }
const _hoisted_18 = { class: "l-formGroup__content" }
const _hoisted_19 = { class: "l-formGroup__input" }
const _hoisted_20 = { class: "l-formAction" }
const _hoisted_21 = {
  key: 1,
  class: "c-stripCard__accordion"
}
const _hoisted_22 = { class: "c-stripBox c-stripBox--lg" }
const _hoisted_23 = { class: "c-stripBox__content" }
const _hoisted_24 = { class: "l-form u-mvLg" }
const _hoisted_25 = { class: "l-form__wrap" }
const _hoisted_26 = { class: "l-formGroup" }
const _hoisted_27 = { class: "l-formGroup__content" }
const _hoisted_28 = { class: "l-formGroup__input" }
const _hoisted_29 = { class: "l-formGroup" }
const _hoisted_30 = { class: "l-formGroup__content" }
const _hoisted_31 = { class: "l-formGroup__input" }
const _hoisted_32 = { class: "l-formGroup" }
const _hoisted_33 = { class: "l-formGroup__content" }
const _hoisted_34 = { class: "l-formGroup__input" }
const _hoisted_35 = {
  key: 0,
  class: "c-stripCard is-open"
}
const _hoisted_36 = { class: "c-stripCard__head" }
const _hoisted_37 = { class: "c-stripCard__item" }
const _hoisted_38 = { class: "c-stripCard__actions" }
const _hoisted_39 = { class: "c-gadget c-gadget--gLg" }
const _hoisted_40 = { class: "u-textGy60" }
const _hoisted_41 = {
  key: 0,
  class: "c-stripCard__accordion"
}
const _hoisted_42 = { class: "c-stripBox__head" }
const _hoisted_43 = { class: "c-stripBox__main" }
const _hoisted_44 = { class: "c-stripBox__num" }
const _hoisted_45 = { class: "c-stripBox__action" }
const _hoisted_46 = { class: "c-stripBox__action" }
const _hoisted_47 = ["onClick"]
const _hoisted_48 = { class: "c-stripBox__accordion" }
const _hoisted_49 = { class: "c-stripBox__item u-mbSm" }
const _hoisted_50 = { class: "c-stripBox__action" }
const _hoisted_51 = { class: "c-switch" }
const _hoisted_52 = ["onUpdate:modelValue"]
const _hoisted_53 = { class: "l-form u-mvLg" }
const _hoisted_54 = { class: "l-form__wrap" }
const _hoisted_55 = { class: "l-formGroup" }
const _hoisted_56 = { class: "l-formGroup__content" }
const _hoisted_57 = ["onUpdate:modelValue"]
const _hoisted_58 = { class: "l-formGroup" }
const _hoisted_59 = { class: "l-formGroup__content" }
const _hoisted_60 = ["onUpdate:modelValue"]
const _hoisted_61 = { class: "c-notice" }
const _hoisted_62 = { class: "c-notice__action" }
const _hoisted_63 = { class: "c-switch" }
const _hoisted_64 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_head = _resolveComponent("app-head")!
  const _component_Fixed = _resolveComponent("Fixed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_app_head, { "prop-title": _ctx.propTitle }, null, 8, ["prop-title"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "c-stripCard__main" }, [
                _createElementVNode("div", { class: "c-stripCard__title" }, "電子發票功能")
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("ul", _hoisted_7, [
                  _createElementVNode("li", null, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("i", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isEnable = !_ctx.isEnable)),
                        class: _normalizeClass(["c-icon u-pointer", [_ctx.isEnable ? 'icon-minus' : 'icon-add']])
                      }, null, 2)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          (_ctx.isEnable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[12] || (_cache[12] = _createStaticVNode("<div class=\"c-stripBox__head\"><div class=\"c-stripBox__main\"><div class=\"c-stripBox__title c-stripBox__title--fsLg\"> 加值中心功能 </div></div><div class=\"c-stripBox__select\"><select class=\"c-select\"><option value=\"\">PayNow</option></select></div></div>", 1)),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _cache[9] || (_cache[9] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                            _createElementVNode("span", null, "帳號")
                          ], -1)),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "c-textBox",
                                placeholder: "請輸入帳號",
                                autocomplete: "storeInvoiceSettingAccount",
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.storeInvoiceSetting.account) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.storeInvoiceSetting.account]
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _cache[10] || (_cache[10] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                            _createElementVNode("span", null, "密碼")
                          ], -1)),
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, [
                              _withDirectives(_createElementVNode("input", {
                                type: "password",
                                class: "c-textBox",
                                placeholder: "請輸入密碼",
                                autocomplete: "new-password",
                                id: "new-password",
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.storeInvoiceSetting.password) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.storeInvoiceSetting.password]
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "c-btn c-btn--primary",
                            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.validateSetting && _ctx.validateSetting(...args)))
                          }, _cache[11] || (_cache[11] = [
                            _createElementVNode("span", null, "驗證", -1)
                          ]))
                        ])
                      ])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isEnable && _ctx.trackListInfo.mem_cid)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _cache[16] || (_cache[16] = _createElementVNode("div", { class: "c-stripBox__head" }, [
                    _createElementVNode("div", { class: "c-stripBox__main" }, [
                      _createElementVNode("div", { class: "c-stripBox__title c-stripBox__title--fsLg" }, " 發票點數資訊 ")
                    ]),
                    _createElementVNode("div", { class: "c-stripBox__desc" }, " ※點數資訊由PayNow 系統提供，如有問題請至PayNow系統後台查詢確認。 ")
                  ], -1)),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _cache[13] || (_cache[13] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                            _createElementVNode("span", null, "剩餘點數")
                          ], -1)),
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "c-textBox",
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.invoiceCount) = $event)),
                                disabled: ""
                              }, null, 512), [
                                [_vModelText, _ctx.invoiceCount]
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_29, [
                          _cache[14] || (_cache[14] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                            _createElementVNode("span", null, "帳號可開立總張數")
                          ], -1)),
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("div", _hoisted_31, [
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "c-textBox",
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.invoiceTotalUseful) = $event)),
                                disabled: ""
                              }, null, 512), [
                                [_vModelText, _ctx.invoiceTotalUseful]
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_32, [
                          _cache[15] || (_cache[15] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                            _createElementVNode("span", null, "剩餘張數")
                          ], -1)),
                          _createElementVNode("div", _hoisted_33, [
                            _createElementVNode("div", _hoisted_34, [
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "c-textBox",
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.invoiceTrackCount) = $event)),
                                disabled: ""
                              }, null, 512), [
                                [_vModelText, _ctx.invoiceTrackCount]
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.deviceInvoiceSettings.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  _cache[17] || (_cache[17] = _createElementVNode("div", { class: "c-stripCard__main" }, [
                    _createElementVNode("div", { class: "c-stripCard__title" }, "裝置設定")
                  ], -1)),
                  _createElementVNode("div", _hoisted_38, [
                    _createElementVNode("ul", _hoisted_39, [
                      _createElementVNode("li", null, [
                        _createElementVNode("div", _hoisted_40, [
                          _createElementVNode("i", {
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isDeviceEnable = !_ctx.isDeviceEnable)),
                            class: _normalizeClass(["c-icon u-pointer", [_ctx.isDeviceEnable ? 'icon-minus' : 'icon-add']])
                          }, null, 2)
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              (_ctx.isDeviceEnable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deviceInvoiceSettings, (deviceInvoiceSetting, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["c-stripBox", deviceInvoiceSetting.show ? 'is-open' : '']),
                        key: index
                      }, [
                        _createElementVNode("div", _hoisted_42, [
                          _createElementVNode("div", _hoisted_43, [
                            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "c-stripBox__title" }, "設備編號", -1)),
                            _createElementVNode("div", _hoisted_44, _toDisplayString(deviceInvoiceSetting.ipadId), 1),
                            _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.getDeviceName(deviceInvoiceSetting.ipadId)), 1)
                          ]),
                          _createElementVNode("div", _hoisted_46, [
                            _createElementVNode("i", {
                              class: "icon-setting c-icon c-icon--fsSm u-pointer",
                              onClick: ($event: any) => (
                    deviceInvoiceSetting.show = !deviceInvoiceSetting.show
                  )
                            }, null, 8, _hoisted_47)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_48, [
                          _createElementVNode("div", _hoisted_49, [
                            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "c-stripBox__title" }, "發票開立", -1)),
                            _createElementVNode("div", _hoisted_50, [
                              _createElementVNode("label", _hoisted_51, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "checkbox",
                                  class: "c-switch__input",
                                  "onUpdate:modelValue": ($event: any) => ((deviceInvoiceSetting.isEnable) = $event)
                                }, null, 8, _hoisted_52), [
                                  [_vModelCheckbox, deviceInvoiceSetting.isEnable]
                                ]),
                                _cache[19] || (_cache[19] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                              ])
                            ])
                          ]),
                          _cache[25] || (_cache[25] = _createElementVNode("hr", { class: "c-separator" }, null, -1)),
                          _createElementVNode("div", _hoisted_53, [
                            _createElementVNode("div", _hoisted_54, [
                              _createElementVNode("div", _hoisted_55, [
                                _cache[21] || (_cache[21] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                                  _createElementVNode("span", null, "取得發票數量")
                                ], -1)),
                                _createElementVNode("div", _hoisted_56, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "number",
                                    class: "c-textBox",
                                    "onUpdate:modelValue": ($event: any) => ((deviceInvoiceSetting.number) = $event)
                                  }, null, 8, _hoisted_57), [
                                    [_vModelText, deviceInvoiceSetting.number]
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_58, [
                                _cache[22] || (_cache[22] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                                  _createElementVNode("span", null, "庫存發票最少數量")
                                ], -1)),
                                _createElementVNode("div", _hoisted_59, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "number",
                                    class: "c-textBox",
                                    "onUpdate:modelValue": ($event: any) => ((deviceInvoiceSetting.min) = $event)
                                  }, null, 8, _hoisted_60), [
                                    [_vModelText, deviceInvoiceSetting.min]
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_61, [
                            _cache[24] || (_cache[24] = _createStaticVNode("<div class=\"c-notice__main\"><div class=\"c-notice__title\"> 發票用盡將停止結帳（離線模式開立發票） </div><div class=\"c-notice__content\"><div class=\"c-notice__text\"> 如開啟，當發票用盡，將無法進行結帳，設備需重新連上網路，才會下載新的發票字軌， </div><div class=\"c-notice__text u-textRd60\"> 執行離線模式開立發票前，設備需有網路連線下載發票字軌，下載完成設備才能將網路斷線。 </div><div class=\"c-notice__text\"> 如關閉，當發票用盡，結帳時將只開立交易明細。 </div></div></div>", 1)),
                            _createElementVNode("div", _hoisted_62, [
                              _createElementVNode("label", _hoisted_63, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "checkbox",
                                  class: "c-switch__input",
                                  "onUpdate:modelValue": ($event: any) => ((deviceInvoiceSetting.isOffline) = $event)
                                }, null, 8, _hoisted_64), [
                                  [_vModelCheckbox, deviceInvoiceSetting.isOffline]
                                ]),
                                _cache[23] || (_cache[23] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                              ])
                            ])
                          ])
                        ])
                      ], 2))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_Fixed, {
      onSave: _ctx.save,
      onCancel: _ctx.cancel
    }, null, 8, ["onSave", "onCancel"])
  ], 64))
}