
import { defineComponent, ref, computed, inject } from 'vue'
import axios from '@/http'
import { useStore } from 'vuex'
import { notifyInstance } from '@/type'
import elDialog from '@/views/components/dialog.vue'
import elDescription from '@/views/components/description.vue'

interface Coupon {
  id: number
  name: string
  code: string
  startAt: string | null
  endAt: string | null
  availablePeriod: string
  channelDescription: string
  usedTarget: string
  slogan: string
  manual: string
  memo: string
  usedCount: number
  isEnable: boolean
  isReadonly: boolean
}

export default defineComponent({
  components: {
    elDialog,
    elDescription
  },
  setup() {
    const couponDetailModalVisible = ref(false)
    const couponDetailModalTitle = ref('')
    const couponDetailModalList = ref<Coupon | null>(null)

    const notify = inject('notify') as notifyInstance

    const store = useStore()
    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const coupons = ref<Coupon[]>([])

    coupons.value.sort((a, b) => {
      if (a.isEnable !== b.isEnable) {
        return Number(b.isEnable) - Number(a.isEnable)
      }

      return (
        new Date(a.endAt as string).getTime() -
        new Date(b.endAt as string).getTime()
      )
    })

    function viewCouponDetail(coupon: Coupon) {
      couponDetailModalVisible.value = true
      couponDetailModalTitle.value = coupon.name
      couponDetailModalList.value = coupon
    }

    function switchCouponEnable(coupon: Coupon) {
      axios
        .post(`/store/${storeId.value}/promotions/status`, {
          status: coupon.isEnable ? 1 : 0,
          promotionId: coupon.id,
          promotionType: 'coupon'
        })
        .then(() => {
          notify(
            {
              classType: 'success',
              message: '儲存成功',
              closeAble: false
            },
            3000
          )
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `儲存失敗 ${err.response.errors.detail}`,
              closeAble: false
            },
            3000
          )
        })
    }

    function init() {
      axios
        .get(`/store/${storeId.value}/promotions/coupon`)
        .then((res) => {
          coupons.value = res.data.items
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    init()

    return {
      couponDetailModalVisible,
      couponDetailModalTitle,
      couponDetailModalList,
      coupons,
      viewCouponDetail,
      switchCouponEnable
    }
  }
})
