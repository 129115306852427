import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-listBox" }
const _hoisted_2 = { class: "c-listBox__head" }
const _hoisted_3 = { class: "c-listBox__main" }
const _hoisted_4 = { class: "c-listBox__subject" }
const _hoisted_5 = { class: "c-listBox__action" }
const _hoisted_6 = { class: "c-listBox__list" }
const _hoisted_7 = { class: "c-listBox__item" }
const _hoisted_8 = { class: "c-listBox__main" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "c-listBox__title" }
const _hoisted_11 = { class: "c-listBox__subList" }
const _hoisted_12 = { class: "c-outlineList" }
const _hoisted_13 = { class: "c-outlineList__head" }
const _hoisted_14 = { class: "c-outlineList__action" }
const _hoisted_15 = ["disabled", "value"]
const _hoisted_16 = { class: "c-outlineList__list" }
const _hoisted_17 = {
  key: 0,
  class: "c-outlineList__item"
}
const _hoisted_18 = { class: "c-outlineList__main" }
const _hoisted_19 = { class: "c-outlineList__title" }
const _hoisted_20 = { class: "c-outlineList__action" }
const _hoisted_21 = ["disabled", "value", "onUpdate:modelValue"]

import { computed, inject, ref } from 'vue'
import { useStore } from 'vuex'
import axios from '@/http'
import Fixed from '@/views/components/fixed.vue'
import { notifyInstance } from '@/type'
import router from '@/router'

export interface shopAdmin {
  id: number
  name: string
  isMaster: boolean
  email: string
}

export interface authPermissions {
  shopAdminId: number
  permissionKeys: Array<string>
}


export default /*@__PURE__*/_defineComponent({
  __name: 'main',
  setup(__props) {

const store = useStore()
const notify = inject('notify') as notifyInstance
const shopAdmins = ref(new Array<shopAdmin>())
const authPermissionKeys = ref(new Array<authPermissions>())

const permissionLists = computed(() => store.getters.permissionLists)
const nowStore = computed(() => store.getters.nowStore)
const storeId = computed(() => nowStore.value.id)

const getShopsAdminList = () =>
  axios
    .get('/shopAdmin/list')
    .then((res) => {
      // 不可指定主帳號 & 店長權限
      const data = res.data.filter(
        (item: any) => !item.isMaster && item.id !== nowStore.value.shopAdminId
      )
      Object.assign(shopAdmins.value, data)
    })
    .catch((err) => console.log(err))

const getAuthPermissionKeys = () =>
  axios
    .get(`/storeAdmin/permissions/${storeId.value}`)
    .then((res) => {
      Object.assign(authPermissionKeys.value, res.data.authPermissions)
    })
    .catch((err) => console.log(err))

function init() {
  shopAdmins.value = []
  authPermissionKeys.value = []
  Promise.all([getAuthPermissionKeys(), getShopsAdminList()]).then(function () {
    shopAdmins.value.forEach((shopAdmin) => {
      const shopAdminPermission = authPermissionKeys.value.find(
        (item) => item.shopAdminId === shopAdmin.id
      )
      if (!shopAdminPermission) {
        const newShopAdminPermission = {
          shopAdminId: shopAdmin.id,
          permissionKeys: []
        }
        authPermissionKeys.value.push(newShopAdminPermission)
      }
    })
  })
}

function actionTypes(module: any) {
  const actionTypes: any[] = []
  const groups = module.groups
  groups.forEach((group: any) => {
    const actions = group.actions
    actions.forEach((action: any) => {
      const isKeyExists = actionTypes.some(
        (existingAction) => existingAction.key === action.key
      )
      if (!isKeyExists) {
        actionTypes.push(action)
      }
    })
  })
  return actionTypes
}

const openLists = ref<string[]>([])

const toggleList = (id: string) => {
  const index = openLists.value.indexOf(id)

  if (index > -1) {
    openLists.value.splice(index, 1)
  } else {
    openLists.value.push(id)
  }
}

const hasListOpen = computed(() => {
  return (id: string) => {
    return openLists.value.includes(id)
  }
})

function groupHasAction(group: any, actionKey: string) {
  const actions = group.actions
  return actions.some((action: any) => action.key === actionKey)
}

function getKey(module: any, group: any, action: any) {
  return `${module.key}.${group.key}.${action.key}`
}

function getShopAdminPermission(shopAdminId: number) {
  return authPermissionKeys.value.find(
    (item) => item.shopAdminId === shopAdminId
  )
}

function checkAll(event: any) {
  const value = event.target.value
  const checked = event.target.checked

  if (checked) {
    authPermissionKeys.value.forEach((item) => {
      if (item.permissionKeys.indexOf(value) === -1) {
        item.permissionKeys.push(value)
      }
    })
  } else {
    authPermissionKeys.value.forEach((item) => {
      const index = item.permissionKeys.indexOf(value)
      if (index !== -1) {
        item.permissionKeys.splice(index, 1)
      }
    })
  }
}

function save() {
  axios
    .post(`/storeAdmin/permissions/${storeId.value}`, {
      authPermissions: authPermissionKeys.value
    })
    .then((res) => {
      router.push({
        name: 'refresh'
      })
      notify(
        {
          classType: 'success',
          message: '儲存成功',
          closeAble: false
        },
        3000
      )
    })
    .catch((err) => {
      notify(
        {
          classType: 'error',
          message: `儲存失敗 ${err.response.errors.detail}`,
          closeAble: false
        },
        3000
      )
    })
}

function cancel() {
  router.push({
    name: 'refresh'
  })
}

init()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(permissionLists.value, (module) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "c-tabContent",
        key: module.key
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(module.name), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(actionTypes(module), (actionType, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: _normalizeClass(["u-textGy90", { 'u-mlLg': index !== 0 }]),
                  key: actionType.key
                }, _toDisplayString(actionType.name), 3))
              }), 128))
            ])
          ]),
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(module.groups, (group) => {
              return (_openBlock(), _createElementBlock("li", {
                key: group.key,
                class: _normalizeClass({ 'is-open': hasListOpen.value(group.key) })
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", {
                      onClick: ($event: any) => (toggleList(group.key)),
                      class: "c-listBox__action"
                    }, [
                      _createElementVNode("i", {
                        class: _normalizeClass(["c-icon u-pointer", [hasListOpen.value(group.key) ? 'icon-minus' : 'icon-add']])
                      }, null, 2)
                    ], 8, _hoisted_9),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(group.name), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "c-outlineList__main" }, [
                        _createElementVNode("span", { class: "c-outlineList__subject" }, "全選")
                      ], -1)),
                      _createElementVNode("div", _hoisted_14, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(actionTypes(module), (actionType, index) => {
                          return (_openBlock(), _createElementBlock("label", {
                            class: _normalizeClass(["c-checkbox", {
                      'u-mlMd': index !== 0,
                      hidden: !groupHasAction(group, actionType.key)
                    }]),
                            key: actionType.key
                          }, [
                            _createElementVNode("input", {
                              type: "checkbox",
                              class: "c-checkbox__input",
                              disabled: !groupHasAction(group, actionType.key),
                              onInput: checkAll,
                              value: getKey(module, group, actionType)
                            }, null, 40, _hoisted_15),
                            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "c-checkbox__text" }, null, -1))
                          ], 2))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("ul", _hoisted_16, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shopAdmins.value, (shopAdmin) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: shopAdmin.id
                        }, [
                          (getShopAdminPermission(shopAdmin.id))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                _createElementVNode("div", _hoisted_18, [
                                  _createElementVNode("span", _hoisted_19, _toDisplayString(shopAdmin.name), 1)
                                ]),
                                _createElementVNode("div", _hoisted_20, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(actionTypes(module), (actionType, index) => {
                                    return (_openBlock(), _createElementBlock("label", {
                                      class: _normalizeClass(["c-checkbox", {
                          'u-mlMd': index !== 0,
                          hidden: !groupHasAction(group, actionType.key)
                        }]),
                                      key: actionType.key
                                    }, [
                                      _withDirectives(_createElementVNode("input", {
                                        type: "checkbox",
                                        class: "c-checkbox__input",
                                        disabled: !groupHasAction(group, actionType.key),
                                        value: getKey(module, group, actionType),
                                        "onUpdate:modelValue": ($event: any) => ((
                            getShopAdminPermission(shopAdmin.id).permissionKeys
                          ) = $event)
                                      }, null, 8, _hoisted_21), [
                                        [_vModelCheckbox, 
                            getShopAdminPermission(shopAdmin.id).permissionKeys
                          ]
                                      ]),
                                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "c-checkbox__text" }, null, -1))
                                    ], 2))
                                  }), 128))
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ], 2))
            }), 128))
          ])
        ])
      ]))
    }), 128)),
    _createVNode(Fixed, {
      onSave: save,
      onCancel: cancel
    })
  ], 64))
}
}

})