import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "l-mask__container" }
const _hoisted_2 = { class: "c-dialog__head" }
const _hoisted_3 = { class: "c-dialog__title" }
const _hoisted_4 = { class: "u-text-ellipsis-1 u-w100" }
const _hoisted_5 = { class: "c-dialog__container" }
const _hoisted_6 = { class: "c-dialog__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: ".l-container"
      }, [
        _createElementVNode("div", {
          class: "l-mask",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeAble && _ctx.handleCancel()))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "c-dialog",
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createElementVNode("h2", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1)
                  ])
                ], true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "footer", {}, () => [
                  _withDirectives(_createElementVNode("button", {
                    type: "button",
                    class: "c-dialog__btn",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
                  }, _toDisplayString(_ctx.cancelBtnText), 513), [
                    [_vShow, _ctx.cancelBtn]
                  ]),
                  _withDirectives(_createElementVNode("button", {
                    type: "button",
                    class: "c-dialog__btn",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)))
                  }, _toDisplayString(_ctx.confirmBtnText), 513), [
                    [_vShow, _ctx.confirmBtn]
                  ])
                ], true)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}