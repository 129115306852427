import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "c-tabContent" }
const _hoisted_2 = { class: "l-table" }
const _hoisted_3 = { class: "c-table" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { style: {"max-width":"500px","width":"100%"} }
const _hoisted_6 = { class: "u-text-ellipsis-1 u-w100" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "u-textCenter" }
const _hoisted_9 = { class: "c-switch" }
const _hoisted_10 = ["onUpdate:modelValue", "onChange"]
const _hoisted_11 = { class: "u-phMd" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_description = _resolveComponent("el-description")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("colgroup", null, [
            _createElementVNode("col", { width: "280px" }),
            _createElementVNode("col"),
            _createElementVNode("col", { width: "100px" })
          ], -1)),
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _cache[1] || (_cache[1] = _createElementVNode("th", { class: "u-textCenter" }, "活動期間", -1)),
              _createElementVNode("th", null, _toDisplayString(_ctx.promotionType.label) + "活動名稱", 1),
              _cache[2] || (_cache[2] = _createElementVNode("th", { class: "u-textCenter" }, "門市啟用", -1))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promotions, (promotion) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: promotion.id
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", {
                    innerHTML: promotion.availablePeriod
                  }, null, 8, _hoisted_4)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("a", {
                        href: "#",
                        class: "c-link u-inline",
                        onClick: _withModifiers(($event: any) => (_ctx.viewPromotionDetail(promotion)), ["prevent"])
                      }, _toDisplayString(promotion.name), 9, _hoisted_7)
                    ])
                  ])
                ]),
                _createElementVNode("td", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      class: "c-switch__input",
                      "onUpdate:modelValue": ($event: any) => ((promotion.isEnable) = $event),
                      onChange: ($event: any) => (_ctx.switchPromotionEnable(promotion))
                    }, null, 40, _hoisted_10), [
                      [_vModelCheckbox, promotion.isEnable]
                    ]),
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.promotionDetailModalVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.promotionDetailModalVisible) = $event)),
      title: _ctx.promotionDetailModalTitle,
      cancelBtnText: "關閉"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_el_description, { label: "活動期間" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.promotionDetailModalList?.availablePeriod
              }, null, 8, _hoisted_12)
            ]),
            _: 1
          }),
          _createVNode(_component_el_description, { label: "適用通路" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.promotionDetailModalList?.channelDescription
              }, null, 8, _hoisted_13)
            ]),
            _: 1
          }),
          _createVNode(_component_el_description, { label: "使用方式" }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.promotionDetailModalList?.slogan
              }, null, 8, _hoisted_14)
            ]),
            _: 1
          }),
          _createVNode(_component_el_description, { label: "活動累計" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.promotionDetailModalList?.isProgression ? '累計' : '不累計'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_description, { label: "階層累計" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.promotionDetailModalList?.isIncrementProgression ? '累計' : '不累計'), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}