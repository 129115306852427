import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "l-card l-card--pvSm" }
const _hoisted_2 = { class: "l-section l-section--md" }
const _hoisted_3 = { class: "l-section__head l-section__head--underLine" }
const _hoisted_4 = { class: "l-section__actions" }
const _hoisted_5 = ["value"]
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "l-section__content" }
const _hoisted_8 = { class: "l-table" }
const _hoisted_9 = { class: "c-table" }
const _hoisted_10 = { class: "l-tool" }
const _hoisted_11 = { class: "c-pager" }
const _hoisted_12 = { class: "c-pager__content" }
const _hoisted_13 = { class: "c-pager__select" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "c-pager__totalNum" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DateRangePicker, {
      propRange: _ctx.range,
      onUpdate: _ctx.updateRange
    }, null, 8, ["propRange", "onUpdate"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "l-section__main" }, [
            _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h2", { class: "c-heading c-heading--fsMd c-heading--fwNormal" }, " 權限紀錄 ")
            ])
          ], -1)),
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", null, [
              _withDirectives(_createElementVNode("button", {
                type: "button",
                class: _normalizeClass(["c-btn c-btn--thin", [_ctx.status ? 'c-btn--primary ' : 'c-btn--secondary']]),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.statusChange && _ctx.statusChange(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.status ? '在職中' : '已離職'), 1)
              ], 2), [
                [_vShow, _ctx.selectedEmployee !== '']
              ])
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("select", {
                class: "c-select",
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeEmployee($event.target.value))),
                value: _ctx.selectedEmployee
              }, [
                _cache[7] || (_cache[7] = _createElementVNode("option", { value: "" }, "全體員工", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterEmployee, (employee, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: employee.no
                  }, _toDisplayString(employee.name), 9, _hoisted_6))
                }), 128))
              ], 40, _hoisted_5)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("button", {
                type: "button",
                class: "c-btn c-btn--primary c-btn--thin",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("span", null, "Excel報表匯出", -1)
              ]))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("table", _hoisted_9, [
              _cache[10] || (_cache[10] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "操作時間"),
                  _createElementVNode("th", null, "員工姓名"),
                  _createElementVNode("th", null, "操作動作"),
                  _createElementVNode("th", null, "設備名稱")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.permissionRecords, (permissionRecord, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `${_ctx.paginationData.page}_${index}`
                  }, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatDateTime(permissionRecord.createdAt)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getEmployeeName(permissionRecord.employeeNo)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.aclItemMap[permissionRecord.action] ??
                    permissionRecord.action), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getDeviceName(permissionRecord.deviceId)), 1)
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", {
                class: "c-pager__arrow",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.prePage && _ctx.prePage(...args)))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["icon-arrow_left c-icon c-icon--fsSm", [
                  _ctx.paginationData.page > 1
                    ? 'u-pointer c-icon--gy60'
                    : 'u-notAllowed c-icon--gy40'
                ]])
                }, null, 2)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _withDirectives(_createElementVNode("select", {
                    class: "c-toolSelect",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.paginationData.page) = $event)),
                    onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goPage($event.target.value)))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.lastPage, (page) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: page,
                        key: page
                      }, _toDisplayString(page), 9, _hoisted_14))
                    }), 128))
                  ], 544), [
                    [_vModelSelect, _ctx.paginationData.page]
                  ])
                ]),
                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "c-pager__text" }, "頁", -1)),
                _cache[12] || (_cache[12] = _createElementVNode("span", { class: "c-pager__separator" }, "，", -1)),
                _cache[13] || (_cache[13] = _createElementVNode("span", { class: "c-pager__text" }, "共", -1)),
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.paginationData.total), 1),
                _cache[14] || (_cache[14] = _createElementVNode("span", { class: "c-pager__text" }, "筆", -1))
              ]),
              _createElementVNode("span", {
                class: "c-pager__arrow",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["icon-arrow_right c-icon c-icon--fsSm", [
                  _ctx.paginationData.page < _ctx.paginationData.lastPage
                    ? 'u-pointer c-icon--gy60'
                    : 'u-notAllowed c-icon--gy40'
                ]])
                }, null, 2)
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}