import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "l-card l-card--pvSm" }
const _hoisted_2 = { class: "l-grid u-mbLg u-pNone" }
const _hoisted_3 = { class: "l-grid__row" }
const _hoisted_4 = { class: "l-grid__item" }
const _hoisted_5 = { class: "c-gradientCard" }
const _hoisted_6 = { class: "c-gradientCard__main" }
const _hoisted_7 = { class: "c-gradientCard__num" }
const _hoisted_8 = { class: "l-grid__item" }
const _hoisted_9 = { class: "c-gradientCard" }
const _hoisted_10 = { class: "c-gradientCard__main" }
const _hoisted_11 = { class: "c-gradientCard__num" }
const _hoisted_12 = { class: "l-grid__item" }
const _hoisted_13 = { class: "c-gradientCard" }
const _hoisted_14 = { class: "c-gradientCard__main" }
const _hoisted_15 = { class: "c-gradientCard__num" }
const _hoisted_16 = { class: "l-grid__item" }
const _hoisted_17 = { class: "c-gradientCard" }
const _hoisted_18 = { class: "c-gradientCard__main" }
const _hoisted_19 = { class: "c-gradientCard__num" }
const _hoisted_20 = { class: "l-grid__item" }
const _hoisted_21 = { class: "c-gradientCard" }
const _hoisted_22 = { class: "c-gradientCard__main" }
const _hoisted_23 = { class: "c-gradientCard__num" }
const _hoisted_24 = { class: "l-section l-section--md" }
const _hoisted_25 = { class: "l-section__head l-section__head--underLine" }
const _hoisted_26 = { class: "l-section__actions" }
const _hoisted_27 = { class: "l-section__content" }
const _hoisted_28 = { class: "l-table" }
const _hoisted_29 = { class: "c-table c-table--group" }
const _hoisted_30 = { class: "c-operateTime" }
const _hoisted_31 = { class: "c-operateTime__time" }
const _hoisted_32 = { class: "is-center" }
const _hoisted_33 = { class: "is-center" }
const _hoisted_34 = { class: "is-center" }
const _hoisted_35 = { class: "is-center" }
const _hoisted_36 = { class: "is-center" }
const _hoisted_37 = { class: "u-textRight" }
const _hoisted_38 = { class: "u-bold is-first" }
const _hoisted_39 = { class: "c-operateTime" }
const _hoisted_40 = { class: "c-operateTime__time" }
const _hoisted_41 = { class: "is-center" }
const _hoisted_42 = { class: "is-center" }
const _hoisted_43 = { class: "is-center" }
const _hoisted_44 = { class: "is-center" }
const _hoisted_45 = { class: "is-center" }
const _hoisted_46 = { class: "u-textRight" }
const _hoisted_47 = { class: "c-operateTime" }
const _hoisted_48 = { class: "c-operateTime__time" }
const _hoisted_49 = { class: "is-center" }
const _hoisted_50 = { class: "is-center" }
const _hoisted_51 = { class: "is-center" }
const _hoisted_52 = { class: "is-center" }
const _hoisted_53 = { class: "is-center" }
const _hoisted_54 = { class: "u-textRight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DatePicker, {
      "prop-date": _ctx.date,
      "onUpdate:propDate": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
      onUpdate: _ctx.updateDate,
      onPreDate: _ctx.preDate,
      onNextDate: _ctx.nextDate
    }, null, 8, ["prop-date", "onUpdate", "onPreDate", "onNextDate"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                  _createElementVNode("span", null, "當日營業額")
                ], -1)),
                _createElementVNode("div", _hoisted_7, " $" + _toDisplayString(_ctx.formatNumber(_ctx.totalAmount - _ctx.totalVoidedAmount)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                  _createElementVNode("span", null, "當日作廢發票數")
                ], -1)),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.totalVoidedQuantity), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                  _createElementVNode("span", null, "當日短溢金額")
                ], -1)),
                _createElementVNode("div", _hoisted_15, " $" + _toDisplayString(_ctx.formatNumber(_ctx.totalOverShort)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                  _createElementVNode("span", null, "當日關帳次數")
                ], -1)),
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.checkOutRecordsLength), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                  _createElementVNode("span", null, "當日小結次數")
                ], -1)),
                _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.handOverRecordsLength), 1)
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "l-section__main" }, [
            _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h2", { class: "c-heading c-heading--fsMd c-heading--fwNormal" }, " 小結 | 關帳 ")
            ])
          ], -1)),
          _createElementVNode("ul", _hoisted_26, [
            _createElementVNode("li", null, [
              _createElementVNode("button", {
                type: "button",
                class: "c-btn c-btn--primary c-btn--thin",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("span", null, "Excel報表匯出", -1)
              ]))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("table", _hoisted_29, [
              _cache[15] || (_cache[15] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "操作時間"),
                  _createElementVNode("th", { class: "is-center" }, "經手人員"),
                  _createElementVNode("th", { class: "is-center" }, "營業額"),
                  _createElementVNode("th", { class: "is-center" }, "作廢發票數"),
                  _createElementVNode("th", { class: "is-center" }, "短溢"),
                  _createElementVNode("th", { class: "is-center" }, "設備名稱"),
                  _createElementVNode("th")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_ctx.handOverRecords)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.handOverRecords, (handOverRecord, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: `handOverRecords_${index}_${handOverRecord.handOverId}`
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_30, [
                            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "c-operateTime__title" }, " 小結 ", -1)),
                            _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.formatDateTime(handOverRecord.endAt)), 1)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_32, _toDisplayString(_ctx.getEmployeeName(handOverRecord.employeeNo)), 1),
                        _createElementVNode("td", _hoisted_33, " $" + _toDisplayString(_ctx.formatNumber(
                        handOverRecord.totalAmount -
                          handOverRecord.totalVoidedAmount
                      )), 1),
                        _createElementVNode("td", _hoisted_34, _toDisplayString(handOverRecord.totalVoidedQuantity), 1),
                        _createElementVNode("td", _hoisted_35, " $" + _toDisplayString(_ctx.formatNumber(handOverRecord.totalOverShort)), 1),
                        _createElementVNode("td", _hoisted_36, _toDisplayString(_ctx.getDeviceName(handOverRecord.deviceId)), 1),
                        _createElementVNode("td", _hoisted_37, [
                          _createVNode(_component_router_link, {
                            class: "u-inlineBlock",
                            to: {
                        name: 'handoverDetail',
                        params: {
                          type: 'handOver',
                          handOverId: handOverRecord.handOverId,
                          date: _ctx.date
                        }
                      }
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createElementVNode("i", { class: "icon-arrow_right c-icon c-icon--fsSm c-icon--gy60" }, null, -1)
                            ])),
                            _: 2
                          }, 1032, ["to"])
                        ])
                      ]))
                    }), 128))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkOutRecords, (checkOutRecord, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: `${index}`
                  }, [
                    _createElementVNode("tr", _hoisted_38, [
                      _createElementVNode("td", null, [
                        _createElementVNode("div", _hoisted_39, [
                          _cache[11] || (_cache[11] = _createElementVNode("span", { class: "c-operateTime__title c-operateTime__title--circleOg" }, " 關帳 ", -1)),
                          _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.formatDateTime(checkOutRecord.endAt)), 1)
                        ])
                      ]),
                      _createElementVNode("td", _hoisted_41, _toDisplayString(_ctx.getEmployeeName(checkOutRecord.employeeNo)), 1),
                      _createElementVNode("td", _hoisted_42, " $" + _toDisplayString(_ctx.formatNumber(
                        _ctx.total(checkOutRecord.handOvers, 'totalAmount') -
                          _ctx.total(checkOutRecord.handOvers, 'totalVoidedAmount')
                      )), 1),
                      _createElementVNode("td", _hoisted_43, _toDisplayString(_ctx.total(checkOutRecord.handOvers, 'totalVoidedQuantity')), 1),
                      _createElementVNode("td", _hoisted_44, " $" + _toDisplayString(_ctx.formatNumber(
                        _ctx.total(checkOutRecord.handOvers, 'totalOverShort')
                      )), 1),
                      _createElementVNode("td", _hoisted_45, _toDisplayString(_ctx.getDeviceName(checkOutRecord.deviceId)), 1),
                      _createElementVNode("td", _hoisted_46, [
                        _createVNode(_component_router_link, {
                          class: "u-inlineBlock",
                          to: {
                        name: 'handoverDetail',
                        params: {
                          type: 'checkOut',
                          checkOutId: checkOutRecord.checkOutId,
                          date: _ctx.date
                        }
                      }
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createElementVNode("i", { class: "icon-arrow_right c-icon c-icon--fsSm c-icon--gy60" }, null, -1)
                          ])),
                          _: 2
                        }, 1032, ["to"])
                      ])
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkOutRecord.handOvers, (handOver, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: `checkOutRecord_${index}_${handOver.handOverId}`
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_47, [
                            _cache[13] || (_cache[13] = _createElementVNode("span", { class: "c-operateTime__title" }, " 小結 ", -1)),
                            _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.formatDateTime(handOver.endAt)), 1)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_49, _toDisplayString(_ctx.getEmployeeName(handOver.employeeNo)), 1),
                        _createElementVNode("td", _hoisted_50, " $" + _toDisplayString(_ctx.formatNumber(
                        handOver.totalAmount - handOver.totalVoidedAmount
                      )), 1),
                        _createElementVNode("td", _hoisted_51, _toDisplayString(handOver.totalVoidedQuantity), 1),
                        _createElementVNode("td", _hoisted_52, " $" + _toDisplayString(_ctx.formatNumber(handOver.totalOverShort)), 1),
                        _createElementVNode("td", _hoisted_53, _toDisplayString(_ctx.getDeviceName(handOver.deviceId)), 1),
                        _createElementVNode("td", _hoisted_54, [
                          _createVNode(_component_router_link, {
                            class: "u-inlineBlock",
                            to: {
                        name: 'handoverDetail',
                        params: {
                          type: 'handOver',
                          handOverId: handOver.handOverId,
                          date: _ctx.date
                        }
                      }
                          }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createElementVNode("i", { class: "icon-arrow_right c-icon c-icon--fsSm c-icon--gy60" }, null, -1)
                            ])),
                            _: 2
                          }, 1032, ["to"])
                        ])
                      ]))
                    }), 128))
                  ], 64))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}