import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-section u-mvSm u-mhMd" }
const _hoisted_2 = { class: "l-section__content" }
const _hoisted_3 = { class: "l-formGroup" }
const _hoisted_4 = { class: "l-formGroup__content" }
const _hoisted_5 = { class: "l-formGroup__input" }
const _hoisted_6 = { class: "l-formGroup" }
const _hoisted_7 = { class: "l-formGroup__content" }
const _hoisted_8 = { class: "l-formGroup__input" }
const _hoisted_9 = { class: "l-formGroup" }
const _hoisted_10 = { class: "l-formGroup__content" }
const _hoisted_11 = { class: "l-formGroup__input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fixed = _resolveComponent("Fixed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "l-section__head" }, [
        _createElementVNode("div", { class: "l-section__main" }, [
          _createElementVNode("div", { class: "l-section__title" }, [
            _createElementVNode("h1", { class: "c-heading" }, "員工設定")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { class: "l-formGroup__title l-formGroup__title--wSm" }, [
            _createElementVNode("span", null, "員工名稱")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "c-textBox c-textBox--full",
                placeholder: "請輸入名稱",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.employee.name) = $event))
              }, null, 512), [
                [_vModelText, _ctx.employee.name]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { class: "l-formGroup__title l-formGroup__title--wSm" }, [
            _createElementVNode("span", null, "電子信箱")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                type: "email",
                class: "c-textBox c-textBox--full",
                placeholder: "請輸入電子信箱",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.employee.email) = $event))
              }, null, 512), [
                [_vModelText, _ctx.employee.email]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { class: "l-formGroup__title l-formGroup__title--wSm" }, [
            _createElementVNode("span", null, "權限密碼")
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "c-textBox c-textBox--full",
                placeholder: "請輸入4位員工識別碼，不得於其他識別碼相同",
                onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.inputPassword && _ctx.inputPassword(...args))),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.employee.password) = $event)),
                maxlength: "4"
              }, null, 544), [
                [_vModelText, _ctx.employee.password]
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Fixed, {
      onSave: _ctx.save,
      onCancel: _ctx.cancel
    }, null, 8, ["onSave", "onCancel"])
  ], 64))
}