import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "c-tabContent" }
const _hoisted_2 = { class: "l-table" }
const _hoisted_3 = { class: "c-mutiTable" }
const _hoisted_4 = { class: "c-mutiTable__inner" }
const _hoisted_5 = { colspan: "5" }
const _hoisted_6 = {
  key: 1,
  class: "is-empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StoreList = _resolveComponent("StoreList")!
  const _component_DeviceList = _resolveComponent("DeviceList")!
  const _component_DeviceRecord = _resolveComponent("DeviceRecord")!
  const _component_Fixed = _resolveComponent("Fixed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _cache[7] || (_cache[7] = _createElementVNode("colgroup", null, [
            _createElementVNode("col", { width: "222px" }),
            _createElementVNode("col"),
            _createElementVNode("col", { width: "80px" }),
            _createElementVNode("col", { width: "80px" })
          ], -1)),
          _cache[8] || (_cache[8] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "店名"),
              _createElementVNode("th", null, "POS帳號"),
              _createElementVNode("th", { class: "u-textCenter" }, "啟用"),
              _createElementVNode("th", { class: "u-textCenter" }, "編輯")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            _createVNode(_component_StoreList, {
              "prop-select-emails": _ctx.selectEmails,
              "prop-store-name": _ctx.storeName,
              "onUpdate:propStoreName": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.storeName) = $event)),
              "prop-store-email": _ctx.storeEmail,
              "onUpdate:propStoreEmail": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.storeEmail) = $event)),
              "prop-is-enable": _ctx.isEnable,
              "onUpdate:propIsEnable": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isEnable) = $event)),
              "prop-store-id": _ctx.storeId,
              "onUpdate:propStoreId": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.storeId) = $event))
            }, null, 8, ["prop-select-emails", "prop-store-name", "prop-store-email", "prop-is-enable", "prop-store-id"]),
            _createElementVNode("tr", _hoisted_4, [
              _createElementVNode("td", _hoisted_5, [
                _createElementVNode("table", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("colgroup", null, [
                    _createElementVNode("col", { width: "222px" }),
                    _createElementVNode("col"),
                    _createElementVNode("col"),
                    _createElementVNode("col"),
                    _createElementVNode("col"),
                    _createElementVNode("col"),
                    _createElementVNode("col", { width: "80px" }),
                    _createElementVNode("col", { width: "80px" })
                  ], -1)),
                  _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "設備名稱"),
                      _createElementVNode("th", null, "設備編號"),
                      _createElementVNode("th"),
                      _createElementVNode("th"),
                      _createElementVNode("th"),
                      _createElementVNode("th"),
                      _createElementVNode("th"),
                      _createElementVNode("th")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_ctx.devices.length > 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (device, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              (device.status)
                                ? (_openBlock(), _createBlock(_component_DeviceList, {
                                    key: 0,
                                    propDevice: device,
                                    onDeleteDeviceClick: _ctx.deleteDeviceClick
                                  }, null, 8, ["propDevice", "onDeleteDeviceClick"]))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (device, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              (!device.status)
                                ? (_openBlock(), _createBlock(_component_DeviceRecord, {
                                    key: 0,
                                    propDevice: device
                                  }, null, 8, ["propDevice"]))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ], 64))
                      : (_openBlock(), _createElementBlock("tr", _hoisted_6, _cache[4] || (_cache[4] = [
                          _createElementVNode("td", null, [
                            _createElementVNode("div", { class: "c-mutiTable__input" })
                          ], -1),
                          _createElementVNode("td", {
                            colspan: "5",
                            style: {"width":"100%"}
                          }, null, -1),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", { class: "c-mutiTable__action" })
                          ], -1),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", { class: "c-mutiTable__action" })
                          ], -1)
                        ])))
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Fixed, {
      onSave: _ctx.save,
      onCancel: _ctx.cancel
    }, null, 8, ["onSave", "onCancel"])
  ], 64))
}