import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/logo/waca_logo.svg'
import _imports_1 from '@/assets/images/logo/waca_logo_wt.svg'


const _hoisted_1 = { class: "l-container l-container--login" }
const _hoisted_2 = { class: "l-login" }
const _hoisted_3 = { class: "l-login__wrap" }
const _hoisted_4 = { class: "l-login__area" }
const _hoisted_5 = { class: "c-login" }
const _hoisted_6 = { class: "c-login__content" }
const _hoisted_7 = { class: "c-login__box" }
const _hoisted_8 = {
  key: 0,
  class: "c-login__msg u-mtNone"
}
const _hoisted_9 = { class: "c-login__formGroup" }
const _hoisted_10 = { class: "c-login__input" }
const _hoisted_11 = {
  key: 0,
  class: "c-login__msg"
}
const _hoisted_12 = { class: "c-login__remind" }
const _hoisted_13 = { class: "c-iconWord" }
const _hoisted_14 = { class: "c-iconWord__text" }
const _hoisted_15 = { class: "c-login__formGroup" }
const _hoisted_16 = { class: "c-login__input" }
const _hoisted_17 = {
  key: 0,
  class: "c-login__msg"
}
const _hoisted_18 = { class: "c-login__remind" }
const _hoisted_19 = { class: "c-iconWord" }
const _hoisted_20 = { class: "c-iconWord__text" }
const _hoisted_21 = { class: "c-login__btn" }
const _hoisted_22 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[11] || (_cache[11] = _createElementVNode("header", { class: "l-header" }, [
      _createElementVNode("div", { class: "l-header__item" }, [
        _createElementVNode("div", { class: "l-header__wrap l-header__wrap--login" }, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "c-logo",
            alt: ""
          })
        ])
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"c-login__head\"><div class=\"c-login__main\"><div class=\"c-login__logo\"><img src=\"" + _imports_1 + "\" alt=\"logo\"></div></div><div class=\"c-login__desc\">重設密碼</div></div>", 1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_ctx.isReset)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[3] || (_cache[3] = [
                      _createStaticVNode("<div class=\"c-login__remind\"><div class=\"c-iconWord\"><span class=\"c-iconWord__icon\"><i class=\"icon-alert c-icon c-icon--fsXxs c-icon--gy40\"></i></span><span class=\"c-iconWord__text\"> 密碼已設定完成，請重新登入！ </span></div></div>", 1)
                    ])))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "c-login__title" }, "新密碼", -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_PasswordInput, {
                      placeholder: "請輸入密碼",
                      modelValue: _ctx.resetPwdInfo.password,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.resetPwdInfo.password) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  (_ctx.resetPwdForm?.reminds.password)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "c-iconWord__icon" }, [
                              _createElementVNode("i", { class: "icon-alert c-icon c-icon--fsXxs c-icon--gy40" })
                            ], -1)),
                            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.resetPwdForm?.reminds.password), 1)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "c-login__title" }, "再次輸入新密碼", -1)),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_PasswordInput, {
                      placeholder: "再次輸入密碼",
                      modelValue: _ctx.resetPwdInfo.confirmPassword,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.resetPwdInfo.confirmPassword) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  (_ctx.resetPwdForm?.reminds.confirmPassword)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "c-iconWord__icon" }, [
                              _createElementVNode("i", { class: "icon-alert c-icon c-icon--fsXxs c-icon--gy40" })
                            ], -1)),
                            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.resetPwdForm?.reminds.confirmPassword), 1)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_21, [
                  (!_ctx.isReset)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "c-btn c-btn--primary c-btn--full",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submitResetPwd && _ctx.submitResetPwd(...args)))
                      }, _cache[8] || (_cache[8] = [
                        _createElementVNode("span", null, "確定送出", -1)
                      ])))
                    : (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        to: { name: 'history' },
                        custom: ""
                      }, {
                        default: _withCtx(({ navigate }) => [
                          _createElementVNode("button", {
                            type: "button",
                            class: "c-btn c-btn--primary c-btn--full",
                            role: "link",
                            onClick: navigate
                          }, _cache[9] || (_cache[9] = [
                            _createElementVNode("span", null, "回到登入頁", -1)
                          ]), 8, _hoisted_22)
                        ]),
                        _: 1
                      }))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}