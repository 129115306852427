import { toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-datePicker" }
const _hoisted_2 = { class: "l-datePicker__main" }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_date_picker, {
        locale: "zh-TW",
        modelValue: _ctx.range,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.range) = $event)),
        mode: "dateTime",
        is24hr: "",
        "is-range": "",
        color: "orange",
        columns: 2,
        popover: _ctx.popover,
        masks: _ctx.masks,
        "max-date": _ctx.maxDate,
        "min-date": _ctx.minDate,
        onDayclick: _ctx.dayClick
      }, {
        default: _withCtx(({ inputValue, inputEvents }) => [
          _createElementVNode("input", _mergeProps({
            class: "c-textBox",
            value: `${inputValue.start} ~ ${inputValue.end}`
          }, _toHandlers(inputEvents.start || inputEvents.end, true), { readonly: "" }), null, 16, _hoisted_3)
        ]),
        _: 1
      }, 8, ["modelValue", "popover", "masks", "max-date", "min-date", "onDayclick"])
    ])
  ]))
}