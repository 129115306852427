import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "l-section u-mvSm u-mhMd" }
const _hoisted_2 = { class: "l-section__head" }
const _hoisted_3 = { class: "l-section__actions" }
const _hoisted_4 = { class: "l-section__content" }
const _hoisted_5 = { class: "l-formGroup" }
const _hoisted_6 = { class: "l-formGroup__content" }
const _hoisted_7 = { class: "l-formGroup__select" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "l-formGroup" }
const _hoisted_10 = { class: "l-formGroup__content" }
const _hoisted_11 = { class: "l-formGroup__input" }
const _hoisted_12 = { class: "l-formGroup" }
const _hoisted_13 = { class: "l-formGroup__content" }
const _hoisted_14 = { class: "l-formGroup__input" }
const _hoisted_15 = { class: "l-formGroup" }
const _hoisted_16 = { class: "l-formGroup__content" }
const _hoisted_17 = { class: "l-formGroup__input" }
const _hoisted_18 = { class: "l-formGroup" }
const _hoisted_19 = { class: "l-formGroup__content" }
const _hoisted_20 = { class: "l-formGroup__input" }
const _hoisted_21 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fixed = _resolveComponent("Fixed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "l-section__main" }, [
          _createElementVNode("div", { class: "l-section__title" }, [
            _createElementVNode("h1", { class: "c-heading" }, "員工設定")
          ])
        ], -1)),
        _createElementVNode("ul", _hoisted_3, [
          _createElementVNode("li", null, [
            _createElementVNode("i", {
              class: "icon-garbage c-icon c-icon--rd40 u-pointer",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args)))
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[7] || (_cache[7] = _createElementVNode("label", { class: "l-formGroup__title l-formGroup__title--wSm" }, [
            _createElementVNode("span", null, "員工群組")
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createElementVNode("select", {
                class: "c-select c-select--full",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.employee.groupId) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propGroups, (group, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: group.id
                  }, _toDisplayString(group.name), 9, _hoisted_8))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.employee.groupId]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[8] || (_cache[8] = _createElementVNode("label", { class: "l-formGroup__title l-formGroup__title--wSm" }, [
            _createElementVNode("span", null, "員工名稱")
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "c-textBox c-textBox--full",
                placeholder: "請輸入名稱",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.employee.name) = $event))
              }, null, 512), [
                [_vModelText, _ctx.employee.name]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[9] || (_cache[9] = _createElementVNode("label", { class: "l-formGroup__title l-formGroup__title--wSm" }, [
            _createElementVNode("span", null, "電子信箱")
          ], -1)),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _withDirectives(_createElementVNode("input", {
                type: "email",
                class: "c-textBox c-textBox--full",
                placeholder: "請輸入電子信箱",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.employee.email) = $event))
              }, null, 512), [
                [_vModelText, _ctx.employee.email]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[10] || (_cache[10] = _createElementVNode("label", { class: "l-formGroup__title l-formGroup__title--wSm" }, [
            _createElementVNode("span", null, "權限密碼")
          ], -1)),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "c-textBox c-textBox--full",
                placeholder: "如無需更改識別碼，請此欄位請留空即可。",
                onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.inputPassword && _ctx.inputPassword(...args))),
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.employee.password) = $event)),
                maxlength: "4"
              }, null, 544), [
                [_vModelText, _ctx.employee.password]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _cache[11] || (_cache[11] = _createElementVNode("label", { class: "l-formGroup__title l-formGroup__title--wSm" }, [
            _createElementVNode("span", null, "員工編號")
          ], -1)),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("input", {
                type: "text",
                class: "c-textBox c-textBox--full",
                value: _ctx.employee.no,
                readonly: ""
              }, null, 8, _hoisted_21)
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Fixed, {
      onSave: _ctx.save,
      onCancel: _ctx.cancel
    }, null, 8, ["onSave", "onCancel"])
  ], 64))
}