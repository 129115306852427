import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "c-tabs" }
const _hoisted_2 = { class: "c-tabContent" }
const _hoisted_3 = { class: "l-section l-section--sm" }
const _hoisted_4 = { class: "l-section__content" }
const _hoisted_5 = { class: "l-grid__row" }
const _hoisted_6 = { class: "l-grid__item" }
const _hoisted_7 = { class: "c-gradientCard" }
const _hoisted_8 = { class: "c-gradientCard__main" }
const _hoisted_9 = { class: "c-gradientCard__title" }
const _hoisted_10 = { class: "l-grid__item" }
const _hoisted_11 = { class: "c-gradientCard" }
const _hoisted_12 = { class: "c-gradientCard__main" }
const _hoisted_13 = { class: "c-gradientCard__num" }
const _hoisted_14 = { class: "l-grid__item" }
const _hoisted_15 = { class: "c-gradientCard" }
const _hoisted_16 = { class: "c-gradientCard__main" }
const _hoisted_17 = { class: "c-gradientCard__num" }
const _hoisted_18 = { class: "l-grid__item" }
const _hoisted_19 = { class: "c-gradientCard" }
const _hoisted_20 = { class: "c-gradientCard__main" }
const _hoisted_21 = { class: "c-gradientCard__num" }
const _hoisted_22 = {
  key: 0,
  class: "c-separator u-mvSm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          href: "#",
          class: _normalizeClass(["c-tabs__panel", _ctx.view ? 'is-active' : '']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeViewType(true)))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("span", null, "本日", -1)
        ]), 2)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("a", {
          href: "#",
          class: _normalizeClass(["c-tabs__panel", _ctx.view ? '' : 'is-active']),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeViewType(false)))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("span", null, "本月", -1)
        ]), 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "l-section__head" }, [
          _createElementVNode("div", { class: "l-section__main" }, [
            _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h3", { class: "c-heading c-heading--fsSm" }, "營業現況")
            ])
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stores, (store, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "l-grid u-pNone",
              key: index
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                        _createElementVNode("span", null, "店名")
                      ], -1)),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(store.storeName), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                        _createElementVNode("span", null, "總營業額")
                      ], -1)),
                      _createElementVNode("div", _hoisted_13, " ＄" + _toDisplayString(_ctx.formatNumber(
                        _ctx.getNumber(
                          store.storeSale +
                            store.storePre -
                            store.voidedOrder -
                            store.voidedPreOrder +
                            store.storeOnline -
                            store.voidedOnlineOrder +
                            store.storePartialPickup -
                            store.voidedPartialPickup
                        )
                      )), 1)
                    ]),
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "c-gradientCard__icon c-gradientCard__icon--rd" }, [
                      _createElementVNode("i", { class: "icon-coin_bag c-icon c-icon--fsLg c-icon--rd40" })
                    ], -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                        _createElementVNode("span", null, "門市銷售")
                      ], -1)),
                      _createElementVNode("div", _hoisted_17, " ＄" + _toDisplayString(_ctx.formatNumber(
                        _ctx.getNumber(
                          store.storeSale +
                            store.storePre -
                            store.voidedOrder -
                            store.voidedPreOrder +
                            store.storePartialPickup -
                            store.voidedPartialPickup
                        )
                      )), 1)
                    ]),
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "c-gradientCard__icon c-gradientCard__icon--og" }, [
                      _createElementVNode("i", { class: "icon-store c-icon c-icon--fsLg c-icon--og50" })
                    ], -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                        _createElementVNode("span", null, "線上店取付款")
                      ], -1)),
                      _createElementVNode("div", _hoisted_21, " ＄" + _toDisplayString(_ctx.formatNumber(
                        _ctx.getNumber(store.storeOnline - store.voidedOnlineOrder)
                      )), 1)
                    ]),
                    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "c-gradientCard__icon c-gradientCard__icon--bu" }, [
                      _createElementVNode("i", { class: "icon-screen c-icon c-icon--fsLg c-icon--bu40" })
                    ], -1))
                  ])
                ])
              ]),
              (index != _ctx.stores.length - 1)
                ? (_openBlock(), _createElementBlock("hr", _hoisted_22))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ])
  ], 64))
}