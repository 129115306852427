import { createElementVNode as _createElementVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-datePicker" }
const _hoisted_2 = { class: "l-datePicker__btn" }
const _hoisted_3 = { class: "l-datePicker__main l-datePicker__main--single" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "l-datePicker__btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        class: "c-arrowBox",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.preDate && _ctx.preDate(...args)))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("i", { class: "icon-arrow_left c-icon c-icon--fsSm" }, null, -1)
      ]))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_date_picker, {
        locale: "zh-TW",
        modelValue: _ctx.date,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.date) = $event)),
        mode: "date",
        color: "orange",
        masks: _ctx.masks,
        popover: _ctx.popover
      }, {
        default: _withCtx(({ inputValue, inputEvents }) => [
          _createElementVNode("input", _mergeProps({
            class: "c-textBox",
            value: `${inputValue}`
          }, _toHandlers(inputEvents, true), { readonly: "" }), null, 16, _hoisted_4)
        ]),
        _: 1
      }, 8, ["modelValue", "masks", "popover"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        type: "button",
        class: "c-arrowBox",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextDate && _ctx.nextDate(...args)))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", { class: "icon-arrow_right c-icon c-icon--fsSm" }, null, -1)
      ]))
    ])
  ]))
}