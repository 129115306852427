import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "l-mask" }
const _hoisted_2 = { class: "l-mask__container" }
const _hoisted_3 = { class: "c-modal" }
const _hoisted_4 = { class: "c-modal__container" }
const _hoisted_5 = { class: "c-modal__header" }
const _hoisted_6 = { class: "c-modal__title" }
const _hoisted_7 = { class: "c-modal__content" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "c-modal__footer" }
const _hoisted_10 = { class: "l-actions" }
const _hoisted_11 = { class: "l-actions__item" }
const _hoisted_12 = { class: "l-actions__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: ".l-container"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _renderSlot(_ctx.$slots, "header", {}, () => [
                      _cache[3] || (_cache[3] = _createTextVNode("門市分店選擇"))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedStore) = $event)),
                    class: "c-select"
                  }, [
                    _cache[4] || (_cache[4] = _createElementVNode("option", {
                      value: null,
                      disabled: ""
                    }, "請選擇門市", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stores, (store, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: store,
                        key: index
                      }, _toDisplayString(store.name), 9, _hoisted_8))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, _ctx.checkedStore]
                  ]),
                  (_ctx.alertPasswordChange)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "u-textLeft u-textRd60" }, "提醒您：為了加強帳戶安全，需定期變更密碼，", -1)),
                        _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "u-textLeft u-textRd60" }, "請前往 『 WACA 賣家中心』設定。", -1))
                      ], 64))
                    : _createCommentVNode("", true),
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "c-btn c-btn--secondary",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.go && _ctx.go(...args)))
                      }, _cache[8] || (_cache[8] = [
                        _createElementVNode("span", null, "前往", -1)
                      ]))
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      (_ctx.storeQuantity > _ctx.usedStoreQuantity)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "c-btn c-btn--primary",
                            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.createNewStore && _ctx.createNewStore(...args)))
                          }, _cache[9] || (_cache[9] = [
                            _createElementVNode("span", null, "建立新門市", -1)
                          ])))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}