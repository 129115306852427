import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "l-card l-card--pvSm" }
const _hoisted_2 = {
  key: 0,
  class: "l-section l-section--md"
}
const _hoisted_3 = { class: "l-section__head l-section__head--underLine" }
const _hoisted_4 = { class: "l-section__main" }
const _hoisted_5 = { class: "l-section__title" }
const _hoisted_6 = { class: "c-heading c-heading--fsMd c-heading--fwNormal" }
const _hoisted_7 = { class: "l-section__actions" }
const _hoisted_8 = ["value"]
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "l-section__content" }
const _hoisted_11 = { class: "l-grid u-pNone u-mbMd" }
const _hoisted_12 = { class: "l-grid__row" }
const _hoisted_13 = { class: "l-grid__item" }
const _hoisted_14 = { class: "c-gradientCard" }
const _hoisted_15 = { class: "c-gradientCard__main" }
const _hoisted_16 = { class: "c-gradientCard__num" }
const _hoisted_17 = { class: "l-grid__item" }
const _hoisted_18 = { class: "c-gradientCard" }
const _hoisted_19 = { class: "c-gradientCard__main" }
const _hoisted_20 = { class: "c-gradientCard__num" }
const _hoisted_21 = { class: "l-grid__item" }
const _hoisted_22 = { class: "c-gradientCard" }
const _hoisted_23 = { class: "c-gradientCard__main" }
const _hoisted_24 = { class: "c-gradientCard__num" }
const _hoisted_25 = { class: "c-punchBoard__main" }
const _hoisted_26 = { class: "c-punchBoard__item" }
const _hoisted_27 = { class: "c-punchBoard__time" }
const _hoisted_28 = { class: "c-punchBoard__item" }
const _hoisted_29 = { class: "c-punchBoard__time" }
const _hoisted_30 = { class: "c-punchBoard__hours" }
const _hoisted_31 = { class: "l-tool" }
const _hoisted_32 = { class: "c-pager" }
const _hoisted_33 = { class: "c-pager__content" }
const _hoisted_34 = { class: "c-pager__select" }
const _hoisted_35 = ["value"]
const _hoisted_36 = { class: "c-pager__totalNum" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      to: {
      name: 'clockRecords',
      params: {
        originDate: _ctx.originDate
      }
    },
      class: "c-iconWord u-mbSm"
    }, {
      default: _withCtx(() => _cache[7] || (_cache[7] = [
        _createElementVNode("span", { class: "c-iconWord__icon" }, [
          _createElementVNode("i", { class: "icon-arrow_left c-icon c-icon--gy60" })
        ], -1),
        _createElementVNode("span", { class: "c-iconWord__text u-textGy60" }, "返回打卡紀錄", -1)
      ])),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_DateRangePicker, {
      propRange: _ctx.range,
      onUpdate: _ctx.updateRange
    }, null, 8, ["propRange", "onUpdate"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.employee)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.employee.name), 1)
                ])
              ]),
              _createElementVNode("ul", _hoisted_7, [
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(["c-btn c-btn--thin", [_ctx.status ? 'c-btn--primary ' : 'c-btn--secondary']]),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.status = !_ctx.status))
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.status ? '在職中' : '已離職'), 1)
                  ], 2)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("select", {
                    class: "c-select",
                    value: _ctx.employee.no,
                    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeEmployee($event.target.value)))
                  }, [
                    _cache[8] || (_cache[8] = _createElementVNode("option", { hidden: "" }, "請選擇員工", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterEmployee, (employee, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: index,
                        value: employee.no
                      }, _toDisplayString(employee.name), 9, _hoisted_9))
                    }), 128))
                  ], 40, _hoisted_8)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "c-btn c-btn--primary c-btn--thin",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
                  }, _cache[9] || (_cache[9] = [
                    _createElementVNode("span", null, "Excel報表匯出", -1)
                  ]))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.formatTime(_ctx.statistics.totalWorkingSeconds)), 1),
                        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                          _createElementVNode("span", null, "累計工作時間")
                        ], -1))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.statistics.totalClockInTimes), 1),
                        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                          _createElementVNode("span", null, "累積上班打卡次數")
                        ], -1))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.statistics.totalClockOutTimes), 1),
                        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                          _createElementVNode("span", null, "累積下班打卡次數")
                        ], -1))
                      ])
                    ])
                  ]),
                  _cache[13] || (_cache[13] = _createElementVNode("div", { class: "l-grid__item" }, null, -1))
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.clockRecords, (clockRecord) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "c-punchBoard",
                  key: clockRecord.id
                }, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "c-punchBoard__arrow" }, [
                        _createElementVNode("i", { class: "icon-checkin c-icon c-icon--fsSm c-icon--og50" })
                      ], -1)),
                      _cache[15] || (_cache[15] = _createElementVNode("div", { class: "c-punchBoard__title" }, "上班", -1)),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("span", null, _toDisplayString(clockRecord.clockIn), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "c-punchBoard__arrow" }, [
                        _createElementVNode("i", { class: "icon-checkout c-icon c-icon--fsSm c-icon--og20" })
                      ], -1)),
                      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "c-punchBoard__title" }, "下班", -1)),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("span", null, _toDisplayString(clockRecord.clockOut), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    (
                clockRecord.clockIn !== null && clockRecord.clockOut !== null
              )
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(_ctx.formatTime(_ctx.timeDiff(clockRecord.clockOut, clockRecord.clockIn))), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("span", {
                    class: "c-pager__arrow",
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.prePage && _ctx.prePage(...args)))
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["icon-arrow_left c-icon c-icon--fsSm", [
                  _ctx.paginationData.page > 1
                    ? 'u-pointer c-icon--gy60'
                    : 'u-notAllowed c-icon--gy40'
                ]])
                    }, null, 2)
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _createElementVNode("div", _hoisted_34, [
                      _withDirectives(_createElementVNode("select", {
                        class: "c-toolSelect",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.paginationData.page) = $event)),
                        onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goPage($event.target.value)))
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.lastPage, (page) => {
                          return (_openBlock(), _createElementBlock("option", {
                            value: page,
                            key: page
                          }, _toDisplayString(page), 9, _hoisted_35))
                        }), 128))
                      ], 544), [
                        [_vModelSelect, _ctx.paginationData.page]
                      ])
                    ]),
                    _cache[18] || (_cache[18] = _createElementVNode("span", { class: "c-pager__text" }, "頁", -1)),
                    _cache[19] || (_cache[19] = _createElementVNode("span", { class: "c-pager__separator" }, "，", -1)),
                    _cache[20] || (_cache[20] = _createElementVNode("span", { class: "c-pager__text" }, "共", -1)),
                    _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.paginationData.total), 1),
                    _cache[21] || (_cache[21] = _createElementVNode("span", { class: "c-pager__text" }, "筆", -1))
                  ]),
                  _createElementVNode("span", {
                    class: "c-pager__arrow",
                    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["icon-arrow_right c-icon c-icon--fsSm", [
                  _ctx.paginationData.page < _ctx.paginationData.lastPage
                    ? 'u-pointer c-icon--gy60'
                    : 'u-notAllowed c-icon--gy40'
                ]])
                    }, null, 2)
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}