import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "c-tabContent" }
const _hoisted_2 = { class: "l-section l-section--sm" }
const _hoisted_3 = { class: "l-section__head" }
const _hoisted_4 = { class: "l-section__main" }
const _hoisted_5 = { class: "l-section__title" }
const _hoisted_6 = { class: "c-heading c-heading--fsSm" }
const _hoisted_7 = { class: "l-section__actions" }
const _hoisted_8 = { class: "l-section__content" }
const _hoisted_9 = { class: "l-grid u-pNone" }
const _hoisted_10 = { class: "l-grid__row" }
const _hoisted_11 = { class: "l-grid__item" }
const _hoisted_12 = { class: "c-gradientCard" }
const _hoisted_13 = { class: "c-gradientCard__main" }
const _hoisted_14 = { class: "c-gradientCard__num" }
const _hoisted_15 = { class: "l-grid__item" }
const _hoisted_16 = { class: "c-gradientCard" }
const _hoisted_17 = { class: "c-gradientCard__main" }
const _hoisted_18 = { class: "c-gradientCard__num" }
const _hoisted_19 = { class: "l-section l-section--sm" }
const _hoisted_20 = { class: "l-section__head" }
const _hoisted_21 = { class: "l-section__main" }
const _hoisted_22 = { class: "l-section__title" }
const _hoisted_23 = { class: "c-heading c-heading--fsSm" }
const _hoisted_24 = { class: "l-section__content" }
const _hoisted_25 = {
  key: 0,
  class: "l-grid u-pNone"
}
const _hoisted_26 = {
  key: 0,
  class: "c-gradientCard"
}
const _hoisted_27 = { class: "c-gradientCard__main" }
const _hoisted_28 = { class: "c-gradientCard__desc" }
const _hoisted_29 = { class: "c-gradientCard__num" }
const _hoisted_30 = { class: "u-textRd60" }
const _hoisted_31 = { class: "l-section l-section--sm" }
const _hoisted_32 = { class: "l-section__head" }
const _hoisted_33 = { class: "l-section__main" }
const _hoisted_34 = { class: "l-section__title" }
const _hoisted_35 = { class: "c-heading c-heading--fsSm" }
const _hoisted_36 = { class: "l-section__content" }
const _hoisted_37 = { class: "l-grid u-pNone" }
const _hoisted_38 = {
  key: 0,
  class: "c-gradientCard"
}
const _hoisted_39 = { class: "c-gradientCard__main" }
const _hoisted_40 = { class: "c-gradientCard__desc" }
const _hoisted_41 = { class: "c-gradientCard__num" }
const _hoisted_42 = { class: "l-section l-section--md" }
const _hoisted_43 = { class: "l-section__head" }
const _hoisted_44 = { class: "l-section__main" }
const _hoisted_45 = { class: "l-section__title" }
const _hoisted_46 = { class: "c-heading c-heading--fsMd c-heading--fwNormal" }
const _hoisted_47 = { class: "l-section__content" }
const _hoisted_48 = { class: "l-table" }
const _hoisted_49 = { class: "c-table c-table--singleData" }
const _hoisted_50 = ["onClick"]
const _hoisted_51 = { class: "u-textRight" }
const _hoisted_52 = { class: "c-pager" }
const _hoisted_53 = ["onClick"]
const _hoisted_54 = { class: "c-pager" }
const _hoisted_55 = ["onClick"]
const _hoisted_56 = { class: "c-pager__content" }
const _hoisted_57 = { class: "c-pager__select" }
const _hoisted_58 = ["onUpdate:modelValue", "onChange"]
const _hoisted_59 = ["value"]
const _hoisted_60 = ["onClick"]
const _hoisted_61 = { class: "c-table__accordion" }
const _hoisted_62 = { colspan: "2" }
const _hoisted_63 = {
  key: 0,
  width: "120px"
}
const _hoisted_64 = { key: 0 }
const _hoisted_65 = { key: 0 }
const _hoisted_66 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h3", _hoisted_6, [
              _createElementVNode("i", {
                class: _normalizeClass(["c-icon c-icon--fsXs c-icon--gy60 u-pointer", [_ctx.areaShow[0] ? 'icon-minus' : 'icon-add']]),
                ref: _ctx.setRef,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.areaShow[0] = !_ctx.areaShow[0]))
              }, null, 2),
              _cache[5] || (_cache[5] = _createTextVNode(" 結帳次數現況 "))
            ])
          ])
        ]),
        _createElementVNode("ul", _hoisted_7, [
          _createElementVNode("li", null, [
            _createElementVNode("button", {
              type: "button",
              class: "c-btn c-btn--primary c-btn--thin",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exportStatisticsExcelClick && _ctx.exportStatisticsExcelClick(...args)))
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("span", null, "Excel報表匯出", -1)
            ]))
          ])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "總結帳次數")
                  ], -1)),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.totalQuantity), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "總作廢次數")
                  ], -1)),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.totalVoidedQuantity), 1)
                ])
              ])
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "l-grid__item" }, null, -1)),
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "l-grid__item" }, null, -1))
          ])
        ])
      ], 512), [
        [_vShow, _ctx.areaShow[0]]
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("h3", _hoisted_23, [
              _createElementVNode("i", {
                class: _normalizeClass(["c-icon c-icon--fsXs c-icon--gy60 u-pointer", [_ctx.areaShow[1] ? 'icon-minus' : 'icon-add']]),
                ref: _ctx.setRef,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.areaShow[1] = !_ctx.areaShow[1]))
              }, null, 2),
              _cache[11] || (_cache[11] = _createTextVNode(" 結帳次數/作廢次數 "))
            ])
          ])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_24, [
        (_ctx.paymentGroups.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.ceil(_ctx.paymentGroups.length / 5), (rowCount) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "l-grid__row",
                  key: rowCount
                }, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (num) => {
                    return _createElementVNode("div", {
                      class: "l-grid__item",
                      key: num
                    }, [
                      (_ctx.paymentGroups[(rowCount - 1) * 5 + (num - 1)])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("div", _hoisted_28, [
                                _createElementVNode("span", null, [
                                  _createElementVNode("span", {
                                    class: _normalizeClass([
                          _ctx.paymentGroups[(rowCount - 1) * 5 + (num - 1)].isEnable
                            ? 'u-textOg50'
                            : ''
                        ])
                                  }, _toDisplayString(_ctx.paymentsMap[
                            _ctx.paymentGroups[(rowCount - 1) * 5 + (num - 1)]
                              .payment
                          ]), 3),
                                  _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                                  _cache[13] || (_cache[13] = _createTextVNode(" 結帳/作廢 "))
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.paymentGroups[(rowCount - 1) * 5 + (num - 1)]
                          .quantity ?? 0), 1),
                                _cache[14] || (_cache[14] = _createTextVNode("/ ")),
                                _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.paymentGroups[(rowCount - 1) * 5 + (num - 1)]
                          .voidedQuantity ?? 0), 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  }), 64))
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 512), [
        [_vShow, _ctx.areaShow[1]]
      ])
    ]),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("h3", _hoisted_35, [
              _createElementVNode("i", {
                class: _normalizeClass(["c-icon c-icon--fsXs c-icon--gy60 u-pointer", [_ctx.areaShow[2] ? 'icon-minus' : 'icon-add']]),
                ref: _ctx.setRef,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.areaShow[2] = !_ctx.areaShow[2]))
              }, null, 2),
              _cache[15] || (_cache[15] = _createTextVNode(" 信用卡營業淨額 "))
            ])
          ])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_36, [
        _createElementVNode("div", _hoisted_37, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.ceil(Object.keys(_ctx.cardTypesMap).length / 5), (rowCount) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "l-grid__row",
              key: rowCount
            }, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (num) => {
                return _createElementVNode("div", {
                  class: "l-grid__item",
                  key: num
                }, [
                  (
                  _ctx.cardTypesMap[
                    Object.keys(_ctx.cardTypesMap)[(rowCount - 1) * 5 + (num - 1)]
                  ]
                )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                        _createElementVNode("div", _hoisted_39, [
                          _createElementVNode("div", _hoisted_40, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.cardTypesMap[
                          Object.keys(_ctx.cardTypesMap)[
                            (rowCount - 1) * 5 + (num - 1)
                          ]
                        ]) + " 營業淨額", 1)
                          ]),
                          _createElementVNode("div", _hoisted_41, " ＄" + _toDisplayString(_ctx.formatNumber(
                        _ctx.creditCardGroups[
                          Object.keys(_ctx.cardTypesMap)[
                            (rowCount - 1) * 5 + (num - 1)
                          ]
                        ]
                          ? _ctx.creditCardGroups[
                              Object.keys(_ctx.cardTypesMap)[
                                (rowCount - 1) * 5 + (num - 1)
                              ]
                            ].totalReceived -
                              _ctx.creditCardGroups[
                                Object.keys(_ctx.cardTypesMap)[
                                  (rowCount - 1) * 5 + (num - 1)
                                ]
                              ].totalChange
                          : 0
                      )), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              }), 64))
            ]))
          }), 128))
        ])
      ], 512), [
        [_vShow, _ctx.areaShow[2]]
      ])
    ]),
    _createElementVNode("div", _hoisted_42, [
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", _hoisted_44, [
          _createElementVNode("div", _hoisted_45, [
            _createElementVNode("h2", _hoisted_46, [
              _createElementVNode("i", {
                class: _normalizeClass(["c-icon c-icon--fsXs c-icon--gy60 u-pointer", [_ctx.areaShow[3] ? 'icon-minus' : 'icon-add']]),
                ref: _ctx.setRef,
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.areaShow[3] = !_ctx.areaShow[3]))
              }, null, 2),
              _cache[16] || (_cache[16] = _createTextVNode(" 所有支付紀錄 "))
            ])
          ])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_47, [
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("table", _hoisted_49, [
            _cache[28] || (_cache[28] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "支付方式"),
                _createElementVNode("th")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentGroups, (group, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createElementVNode("tr", {
                    class: _normalizeClass([group.isOpen ? 'is-open' : ''])
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("i", {
                        onClick: ($event: any) => (_ctx.GroupClick(group)),
                        class: _normalizeClass(["c-icon c-icon--fsXs c-icon--gy60 u-pointer", [group.isOpen ? 'icon-minus' : 'icon-add']])
                      }, null, 10, _hoisted_50),
                      _createElementVNode("span", {
                        class: _normalizeClass(["u-mlXs u-alignMiddle", [group.isEnable ? 'u-textOg50' : '']])
                      }, _toDisplayString(_ctx.paymentsMap[group.payment] ?? group.payment), 3)
                    ]),
                    _createElementVNode("td", _hoisted_51, [
                      _withDirectives(_createElementVNode("div", _hoisted_52, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "c-btn c-btn--primary c-btn--thin",
                          onClick: ($event: any) => (_ctx.exportExcelClick(group.payment))
                        }, _cache[17] || (_cache[17] = [
                          _createElementVNode("span", null, "Excel報表匯出", -1)
                        ]), 8, _hoisted_53)
                      ], 512), [
                        [_vShow, !group.isOpen]
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_54, [
                        (group.pagination !== null)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("span", {
                                class: "c-pager__arrow",
                                onClick: ($event: any) => (
                            _ctx.prePage(group.pagination, group.paginationData)
                          )
                              }, [
                                _createElementVNode("i", {
                                  class: _normalizeClass(["icon-arrow_left c-icon c-icon--fsSm", [
                              group.paginationData.page > 1
                                ? 'u-pointer c-icon--gy60'
                                : 'u-notAllowed c-icon--gy40'
                            ]])
                                }, null, 2)
                              ], 8, _hoisted_55),
                              _createElementVNode("div", _hoisted_56, [
                                _createElementVNode("div", _hoisted_57, [
                                  _withDirectives(_createElementVNode("select", {
                                    class: "c-toolSelect",
                                    "onUpdate:modelValue": ($event: any) => ((group.paginationData.page) = $event),
                                    onChange: ($event: any) => (
                                _ctx.goPage(
                                  group.pagination,
                                  group.paginationData,
                                  $event.target.value
                                )
                              )
                                  }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.paginationData.lastPage, (page) => {
                                      return (_openBlock(), _createElementBlock("option", {
                                        value: page,
                                        key: page
                                      }, _toDisplayString(page), 9, _hoisted_59))
                                    }), 128))
                                  ], 40, _hoisted_58), [
                                    [_vModelSelect, group.paginationData.page]
                                  ])
                                ]),
                                _cache[18] || (_cache[18] = _createElementVNode("span", { class: "c-pager__text" }, "頁", -1))
                              ]),
                              _createElementVNode("span", {
                                class: "c-pager__arrow",
                                onClick: ($event: any) => (
                            _ctx.nextPage(group.pagination, group.paginationData)
                          )
                              }, [
                                _createElementVNode("i", {
                                  class: _normalizeClass(["icon-arrow_right c-icon c-icon--fsSm", [
                              group.paginationData.page <
                              group.paginationData.lastPage
                                ? 'u-pointer c-icon--gy60'
                                : 'u-notAllowed c-icon--gy40'
                            ]])
                                }, null, 2)
                              ], 8, _hoisted_60)
                            ], 64))
                          : _createCommentVNode("", true)
                      ], 512), [
                        [_vShow, group.isOpen]
                      ])
                    ])
                  ], 2),
                  _createElementVNode("tr", _hoisted_61, [
                    _createElementVNode("td", _hoisted_62, [
                      _createElementVNode("table", null, [
                        _createElementVNode("colgroup", null, [
                          _cache[19] || (_cache[19] = _createElementVNode("col", { width: "200px" }, null, -1)),
                          _cache[20] || (_cache[20] = _createElementVNode("col", { width: "100px" }, null, -1)),
                          _cache[21] || (_cache[21] = _createElementVNode("col", { width: "120px" }, null, -1)),
                          _cache[22] || (_cache[22] = _createElementVNode("col", { width: "180px" }, null, -1)),
                          (group.payment === 'creditCard')
                            ? (_openBlock(), _createElementBlock("col", _hoisted_63))
                            : _createCommentVNode("", true),
                          _cache[23] || (_cache[23] = _createElementVNode("col", null, null, -1))
                        ]),
                        _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _cache[24] || (_cache[24] = _createElementVNode("th", null, "結帳時間", -1)),
                            _cache[25] || (_cache[25] = _createElementVNode("th", null, "發票號碼", -1)),
                            _cache[26] || (_cache[26] = _createElementVNode("th", null, "訂單編號", -1)),
                            _cache[27] || (_cache[27] = _createElementVNode("th", null, "支付金額", -1)),
                            (group.payment === 'creditCard')
                              ? (_openBlock(), _createElementBlock("th", _hoisted_64, " 卡號後四碼 "))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        (group.pagination !== null)
                          ? (_openBlock(), _createElementBlock("tbody", _hoisted_65, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.paginationData
                            .orderRecords, (paginateRecord, index) => {
                                return (_openBlock(), _createElementBlock("tr", {
                                  key: `${group.paginationData.currentPage}_${index}`
                                }, [
                                  _createElementVNode("td", null, _toDisplayString(paginateRecord.createdAt), 1),
                                  _createElementVNode("td", null, _toDisplayString(paginateRecord.invoiceNo), 1),
                                  _createElementVNode("td", null, _toDisplayString(paginateRecord.no), 1),
                                  _createElementVNode("td", null, " $" + _toDisplayString(_ctx.formatNumber(
                                Number(paginateRecord.moneyReceived) -
                                  Number(paginateRecord.makeChange)
                              )), 1),
                                  (group.payment === 'creditCard')
                                    ? (_openBlock(), _createElementBlock("td", _hoisted_66, _toDisplayString(paginateRecord.creditCardCode), 1))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ], 64))
              }), 128))
            ])
          ])
        ])
      ], 512), [
        [_vShow, _ctx.areaShow[3]]
      ])
    ])
  ]))
}