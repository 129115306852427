import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/logo/waca_logo.svg'
import _imports_1 from '@/assets/images/logo/waca_logo_wt.svg'


const _hoisted_1 = { class: "l-container l-container--login" }
const _hoisted_2 = { class: "l-login" }
const _hoisted_3 = { class: "l-login__wrap" }
const _hoisted_4 = { class: "l-login__area" }
const _hoisted_5 = { class: "c-login" }
const _hoisted_6 = { class: "c-login__content" }
const _hoisted_7 = { class: "c-login__box" }
const _hoisted_8 = {
  key: 0,
  class: "c-alertBox u-mbLg"
}
const _hoisted_9 = { class: "c-alertBox__text" }
const _hoisted_10 = { class: "c-login__formGroup" }
const _hoisted_11 = { class: "c-login__input" }
const _hoisted_12 = { class: "c-login__formGroup" }
const _hoisted_13 = { class: "c-login__input" }
const _hoisted_14 = { class: "c-login__msg" }
const _hoisted_15 = { class: "c-login__forget" }
const _hoisted_16 = { class: "c-login__recaptcha" }
const _hoisted_17 = { class: "c-login__btn" }
const _hoisted_18 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoogleSignInButton = _resolveComponent("GoogleSignInButton")!
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_VueRecaptcha = _resolveComponent("VueRecaptcha")!
  const _component_modal = _resolveComponent("modal")!
  const _component_ForgetPwdLightbox = _resolveComponent("ForgetPwdLightbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[11] || (_cache[11] = _createElementVNode("header", { class: "l-header" }, [
        _createElementVNode("div", { class: "l-header__item" }, [
          _createElementVNode("div", { class: "l-header__wrap l-header__wrap--login" }, [
            _createElementVNode("img", {
              src: _imports_0,
              class: "c-logo",
              alt: ""
            })
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"c-login__head\"><div class=\"c-login__main\"><div class=\"c-login__logo\"><img src=\"" + _imports_1 + "\" alt=\"logo\"></div></div><div class=\"c-login__desc\">登入您的商店管理後台</div></div>", 1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.errors.detail)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "c-alertBox__icon" }, [
                          _createElementVNode("i", { class: "icon-alert" })
                        ], -1)),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.errors.detail), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_GoogleSignInButton),
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "c-line u-mvMd" }, [
                    _createElementVNode("span", { class: "c-line__text" }, "或")
                  ], -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "c-login__title" }, "電子信箱", -1)),
                    _createElementVNode("div", _hoisted_11, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "c-textBox",
                        placeholder: "請輸入帳號(必填)",
                        autocomplete: "loginAccount",
                        id: "loginAccount",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.account) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.form.account]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "c-login__title" }, "密碼", -1)),
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_PasswordInput, {
                        placeholder: "請輸入密碼(必填)",
                        modelValue: _ctx.form.password,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("a", {
                          href: "#",
                          class: "c-link",
                          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.forgetPwdRef.lightboxRef.open && _ctx.forgetPwdRef.lightboxRef.open(...args)))
                        }, "忘記密碼?")
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    (_ctx.siteKey)
                      ? (_openBlock(), _createBlock(_component_VueRecaptcha, {
                          key: 0,
                          sitekey: _ctx.siteKey,
                          onVerify: _ctx.onCaptchaVertified,
                          onExpired: _ctx.onCaptchaExpired,
                          loadRecaptchaScript: true,
                          ref: "recaptchaRef"
                        }, null, 8, ["sitekey", "onVerify", "onExpired"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("button", {
                      class: "c-btn c-btn--primary c-btn--full",
                      onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
                      disabled: _ctx.loginButtonDisabled
                    }, _cache[7] || (_cache[7] = [
                      _createElementVNode("span", null, "立即登入", -1)
                    ]), 8, _hoisted_18)
                  ])
                ])
              ]),
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "c-login__footer" }, [
                _createElementVNode("span", { class: "u-mrXs u-textBk" }, "還沒有開店或註冊嗎？"),
                _createElementVNode("a", {
                  href: "https://www.waca.net/register",
                  target: "_blank",
                  rel: "noreferrer noopenner",
                  class: "c-link"
                }, "我要開店")
              ], -1))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_modal),
    _createVNode(_component_ForgetPwdLightbox, { ref: "forgetPwdRef" }, null, 512)
  ], 64))
}