import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/logo/waca_logo.svg'
import _imports_1 from '@/assets/images/icons/member_avatar.svg'


const _hoisted_1 = { class: "l-header" }
const _hoisted_2 = { class: "l-header__item" }
const _hoisted_3 = { class: "l-header__wrap" }
const _hoisted_4 = { class: "l-header__option" }
const _hoisted_5 = { class: "l-header__select" }
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "l-header__admin" }
const _hoisted_9 = { class: "l-member__group" }
const _hoisted_10 = { class: "l-member__userName" }
const _hoisted_11 = { class: "l-member__dropdown" }
const _hoisted_12 = { class: "c-dropdown" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "l-header__item" }
const _hoisted_16 = { class: "l-header__wrap" }
const _hoisted_17 = { class: "l-header__navbar" }
const _hoisted_18 = { class: "l-navGroup" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "l-navGroup__dropdown" }
const _hoisted_21 = { class: "c-dropdown" }
const _hoisted_22 = { class: "l-header__tool" }
const _hoisted_23 = { class: "l-fence" }
const _hoisted_24 = { class: "l-fence__item" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "l-fence__item" }
const _hoisted_27 = { class: "c-countdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Timer = _resolveComponent("Timer")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: { name: 'index' },
          class: "l-header__logo"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("img", {
              src: _imports_0,
              class: "c-logo",
              alt: ""
            }, null, -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (Object.keys(_ctx.nowStore).length)
              ? (_openBlock(), _createElementBlock("select", {
                  key: 0,
                  class: "c-select",
                  value: _ctx.nowStore.id,
                  onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeNowStore && _ctx.changeNowStore(...args)))
                }, [
                  _cache[4] || (_cache[4] = _createElementVNode("option", {
                    value: null,
                    disabled: ""
                  }, "請選擇門市", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stores, (store, index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: store.id,
                      key: index
                    }, _toDisplayString(store.name), 9, _hoisted_7))
                  }), 128))
                ], 40, _hoisted_6))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["l-member", { 'is-open': _ctx.isMemberNavOpen }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isMemberNavOpen = !_ctx.isMemberNavOpen))
            }, [
              _createElementVNode("div", _hoisted_9, [
                _cache[5] || (_cache[5] = _createElementVNode("img", {
                  src: _imports_1,
                  class: "l-member__avatar"
                }, null, -1)),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.user.name), 1),
                _cache[6] || (_cache[6] = _createElementVNode("i", { class: "icon-arrow_down l-member__arrow" }, null, -1))
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("ul", _hoisted_12, [
                  (_ctx.isStoreAdmin() || _ctx.isAdmin())
                    ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                        _createVNode(_component_router_link, {
                          to: { name: 'Bill' },
                          class: "c-dropdown__link"
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode(" 帳務中心 ")
                          ])),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isAdmin())
                    ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                        _createVNode(_component_router_link, {
                          to: { name: 'Monitor' },
                          class: "c-dropdown__link"
                        }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode(" 分店現況 ")
                          ])),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)), ["prevent"])),
                      class: "c-dropdown__link"
                    }, "登出")
                  ])
                ])
              ])
            ], 2)), [
              [_directive_click_outside, () => (_ctx.isMemberNavOpen = false)]
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("nav", _hoisted_17, [
          _createElementVNode("ul", _hoisted_18, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
              return _withDirectives((_openBlock(), _createElementBlock("li", {
                key: menu.title,
                class: _normalizeClass({ 'is-open': menu.isOpen }),
                onClick: ($event: any) => (menu.isOpen = !menu.isOpen)
              }, [
                (menu.subLists && menu.subLists.length > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["l-navGroup__link", {
                    'is-active': menu.activeRoutes.includes(String(_ctx.route.name))
                  }])
                      }, _toDisplayString(menu.title), 3),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("ul", _hoisted_21, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.subLists, (subList) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: subList.title,
                              class: _normalizeClass({ 'c-dropdown__line': subList.hasDropLine })
                            }, [
                              _createVNode(_component_router_link, {
                                to: { name: subList.routeName },
                                class: "c-dropdown__link"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(subList.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ], 2))
                          }), 128))
                        ])
                      ])
                    ], 64))
                  : (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      to: { name: menu.routeName },
                      class: "l-navGroup__link is-active"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(menu.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
              ], 10, _hoisted_19)), [
                [_directive_click_outside, () => (menu.isOpen = false)]
              ])
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_router_link, {
                to: { name: 'history' },
                custom: ""
              }, {
                default: _withCtx(({ navigate }) => [
                  _createElementVNode("button", {
                    type: "button",
                    class: "c-btn c-btn--primary c-btn--thin",
                    role: "link",
                    onClick: navigate
                  }, _cache[9] || (_cache[9] = [
                    _createElementVNode("span", null, "歷程紀錄", -1)
                  ]), 8, _hoisted_25)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _cache[10] || (_cache[10] = _createElementVNode("i", { class: "icon-stopwatch c-countdown__icon" }, null, -1)),
                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "c-countdown__title" }, "登出倒數： ", -1)),
                _createVNode(_component_Timer, { ref: "timer" }, null, 512)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}