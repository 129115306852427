import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "l-card l-card--pvSm" }
const _hoisted_2 = { class: "l-section l-section--md" }
const _hoisted_3 = { class: "l-section__head l-section__head--underLine" }
const _hoisted_4 = { class: "l-section__actions" }
const _hoisted_5 = { class: "l-section__content" }
const _hoisted_6 = { class: "l-table" }
const _hoisted_7 = { class: "c-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DatePicker, {
      "prop-date": _ctx.date,
      "onUpdate:propDate": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
      onUpdate: _ctx.updateDate,
      onPreDate: _ctx.preDate,
      onNextDate: _ctx.nextDate
    }, null, 8, ["prop-date", "onUpdate", "onPreDate", "onNextDate"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "l-section__main" }, [
            _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h2", { class: "c-heading c-heading--fsMd c-heading--fwNormal" }, " 庫存警示 ")
            ])
          ], -1)),
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", null, [
              _createElementVNode("button", {
                type: "button",
                class: "c-btn c-btn--primary c-btn--thin",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("span", null, "Excel報表匯出", -1)
              ]))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("table", _hoisted_7, [
              _cache[4] || (_cache[4] = _createElementVNode("colgroup", null, [
                _createElementVNode("col", { width: "170px" }),
                _createElementVNode("col", { width: "170px" }),
                _createElementVNode("col", { width: "170px" }),
                _createElementVNode("col", { width: "100px" })
              ], -1)),
              _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "商品編號"),
                  _createElementVNode("th", null, "商品名稱"),
                  _createElementVNode("th", null, "規格"),
                  _createElementVNode("th", null, "數量")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oversoldRecords, (oversoldRecord, index) => {
                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                    _createElementVNode("td", null, _toDisplayString(oversoldRecord.products_no), 1),
                    _createElementVNode("td", null, _toDisplayString(oversoldRecord.products_name), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getProductStandardName(oversoldRecord)), 1),
                    _createElementVNode("td", null, _toDisplayString(oversoldRecord.number), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}