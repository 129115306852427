import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/others/invoice.png'


const _hoisted_1 = { class: "l-section" }
const _hoisted_2 = { class: "l-section__content" }
const _hoisted_3 = { class: "l-card l-card--noSpace" }
const _hoisted_4 = { class: "l-grid l-grid--splitV l-grid--gXl" }
const _hoisted_5 = { class: "l-grid__row" }
const _hoisted_6 = { class: "l-grid__item l-grid__item--8col" }
const _hoisted_7 = { class: "c-outlineBox__head" }
const _hoisted_8 = { class: "c-outlineBox__action" }
const _hoisted_9 = { class: "c-outlineBox__accordion" }
const _hoisted_10 = { class: "l-uploadImg" }
const _hoisted_11 = { class: "l-uploadImg__box" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "l-uploadImg__content" }
const _hoisted_14 = { class: "l-uploadImg__action" }
const _hoisted_15 = { class: "c-outlineBox__head" }
const _hoisted_16 = { class: "c-outlineBox__action" }
const _hoisted_17 = { class: "c-outlineBox__accordion" }
const _hoisted_18 = { class: "l-form u-mvMd" }
const _hoisted_19 = { class: "l-form__wrap" }
const _hoisted_20 = { class: "l-formGroup" }
const _hoisted_21 = { class: "l-formGroup__content l-formGroup__content--level" }
const _hoisted_22 = { class: "l-formGroup__input" }
const _hoisted_23 = { class: "l-formGroup__switch" }
const _hoisted_24 = { class: "c-switch" }
const _hoisted_25 = { class: "l-formGroup" }
const _hoisted_26 = { class: "l-formGroup__content l-formGroup__content--level" }
const _hoisted_27 = { class: "l-formGroup__switch" }
const _hoisted_28 = { class: "c-switch" }
const _hoisted_29 = { class: "l-formGroup" }
const _hoisted_30 = { class: "l-formGroup__content l-formGroup__content--level" }
const _hoisted_31 = { class: "l-formGroup__input" }
const _hoisted_32 = { class: "l-formGroup__switch" }
const _hoisted_33 = { class: "c-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fixed = _resolveComponent("Fixed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[23] || (_cache[23] = _createElementVNode("div", { class: "l-section__head" }, [
        _createElementVNode("div", { class: "l-section__main" }, [
          _createElementVNode("div", { class: "l-section__title" }, [
            _createElementVNode("h1", { class: "c-heading" }, "門市資訊設定")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: _normalizeClass(["c-outlineBox", [_ctx.showLogo ? 'is-open' : 'is-close']])
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "c-outlineBox__main" }, [
                      _createElementVNode("div", { class: "c-outlineBox__title" }, "單據Logo")
                    ], -1)),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("button", {
                        type: "button",
                        class: _normalizeClass(["c-btn c-btn--primary c-btn--thin c-btn--sm", [_ctx.showLogo ? 'is-active' : '']]),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLogo = !_ctx.showLogo))
                      }, _cache[9] || (_cache[9] = [
                        _createElementVNode("span", null, "設定", -1)
                      ]), 2)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("img", {
                          src: _ctx.storePrinter.src
                        }, null, 8, _hoisted_12)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "l-uploadImg__desc" }, " 建議尺寸：384 x 100px (PNG) 200 KB ", -1)),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "c-btn c-btn--outlinePrimary",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$refs.file.click()))
                          }, _cache[11] || (_cache[11] = [
                            _createElementVNode("span", null, "上傳圖片", -1)
                          ])),
                          _createElementVNode("input", {
                            type: "file",
                            accept: "image/*",
                            ref: "file",
                            class: "u-hidden",
                            onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleUploadImg && _ctx.handleUploadImg(...args)))
                          }, null, 544)
                        ])
                      ])
                    ])
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["c-outlineBox", [_ctx.showBottom ? 'is-open' : 'is-close']])
                }, [
                  _createElementVNode("div", _hoisted_15, [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "c-outlineBox__main" }, [
                      _createElementVNode("div", { class: "c-outlineBox__title" }, " 電子發票底部門市資訊欄 ")
                    ], -1)),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("button", {
                        type: "button",
                        class: _normalizeClass(["c-btn c-btn--primary c-btn--thin c-btn--sm", [_ctx.showBottom ? 'is-active' : '']]),
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showBottom = !_ctx.showBottom))
                      }, _cache[13] || (_cache[13] = [
                        _createElementVNode("span", null, "設定", -1)
                      ]), 2)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _cache[16] || (_cache[16] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                            _createElementVNode("span", null, "門市資訊欄")
                          ], -1)),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "c-textBox",
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.storePrinter.printStore) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.storePrinter.printStore]
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_23, [
                              _createElementVNode("label", _hoisted_24, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "checkbox",
                                  class: "c-switch__input",
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.storePrinter.isPrintStore) = $event))
                                }, null, 512), [
                                  [_vModelCheckbox, _ctx.storePrinter.isPrintStore]
                                ]),
                                _cache[15] || (_cache[15] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                          _cache[19] || (_cache[19] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                            _createElementVNode("span", null, "經手人員")
                          ], -1)),
                          _createElementVNode("div", _hoisted_26, [
                            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "l-formGroup__input" }, [
                              _createElementVNode("input", {
                                type: "text",
                                class: "c-textBox",
                                placeholder: "系統員工編號",
                                readonly: ""
                              })
                            ], -1)),
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("label", _hoisted_28, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "checkbox",
                                  class: "c-switch__input",
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.storePrinter.isPrintEmployee) = $event))
                                }, null, 512), [
                                  [_vModelCheckbox, _ctx.storePrinter.isPrintEmployee]
                                ]),
                                _cache[17] || (_cache[17] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_29, [
                          _cache[21] || (_cache[21] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                            _createElementVNode("span", null, "資訊欄")
                          ], -1)),
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("div", _hoisted_31, [
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "c-textBox",
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.storePrinter.printInfo) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.storePrinter.printInfo]
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_32, [
                              _createElementVNode("label", _hoisted_33, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "checkbox",
                                  class: "c-switch__input",
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.storePrinter.isPrintInfo) = $event))
                                }, null, 512), [
                                  [_vModelCheckbox, _ctx.storePrinter.isPrintInfo]
                                ]),
                                _cache[20] || (_cache[20] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ], 2)
              ]),
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "l-grid__item l-grid__item--4col" }, [
                _createElementVNode("div", { class: "c-imgBox u-mhMd" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "invoice"
                  })
                ])
              ], -1))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Fixed, {
      onSave: _ctx.save,
      onCancel: _ctx.cancel
    }, null, 8, ["onSave", "onCancel"])
  ], 64))
}