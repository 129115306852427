import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "c-tabContent" }
const _hoisted_2 = { class: "l-table" }
const _hoisted_3 = { class: "c-table" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { style: {"max-width":"500px","width":"100%"} }
const _hoisted_6 = { class: "u-text-ellipsis-1 u-w100" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "u-textCenter" }
const _hoisted_9 = { class: "c-switch" }
const _hoisted_10 = ["disabled", "onUpdate:modelValue", "onChange"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_description = _resolveComponent("el-description")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("colgroup", null, [
            _createElementVNode("col", { width: "280px" }),
            _createElementVNode("col"),
            _createElementVNode("col", { width: "100px" })
          ], -1)),
          _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", { class: "u-textCenter" }, "活動期間"),
              _createElementVNode("th", null, "優惠劵名稱"),
              _createElementVNode("th", { class: "u-textCenter" }, "門市啟用")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coupons, (coupon) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: coupon.id
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", {
                    innerHTML: coupon.availablePeriod
                  }, null, 8, _hoisted_4)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("a", {
                        href: "#",
                        class: "c-link u-inline",
                        onClick: _withModifiers(($event: any) => (_ctx.viewCouponDetail(coupon)), ["prevent"])
                      }, _toDisplayString(coupon.name), 9, _hoisted_7)
                    ])
                  ])
                ]),
                _createElementVNode("td", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      class: "c-switch__input",
                      disabled: coupon.isReadonly,
                      "onUpdate:modelValue": ($event: any) => ((coupon.isEnable) = $event),
                      onChange: ($event: any) => (_ctx.switchCouponEnable(coupon))
                    }, null, 40, _hoisted_10), [
                      [_vModelCheckbox, coupon.isEnable]
                    ]),
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.couponDetailModalVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.couponDetailModalVisible) = $event)),
      title: _ctx.couponDetailModalTitle,
      cancelBtnText: "關閉"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_description, { label: "優惠券名稱" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.couponDetailModalList?.code
            }, null, 8, _hoisted_11)
          ]),
          _: 1
        }),
        _createVNode(_component_el_description, { label: "優惠期間" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.couponDetailModalList?.availablePeriod
            }, null, 8, _hoisted_12)
          ]),
          _: 1
        }),
        _createVNode(_component_el_description, { label: "適用通路" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.couponDetailModalList?.channelDescription
            }, null, 8, _hoisted_13)
          ]),
          _: 1
        }),
        _createVNode(_component_el_description, { label: "使用對象" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.couponDetailModalList?.usedTarget
            }, null, 8, _hoisted_14)
          ]),
          _: 1
        }),
        _createVNode(_component_el_description, { label: "使用方式" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.couponDetailModalList?.slogan
            }, null, 8, _hoisted_15)
          ]),
          _: 1
        }),
        _createVNode(_component_el_description, { label: "活動說明" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.couponDetailModalList?.manual), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_description, { label: "注意事項" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.couponDetailModalList?.memo), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}