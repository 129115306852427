import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "c-sortbar" }
const _hoisted_2 = {
  class: "c-sortbar__content",
  style: {"width":"calc(100% - 20px)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nested_draggable = _resolveComponent("nested-draggable")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createBlock(_component_draggable, {
    ref: "rootElement",
    class: "l-sortbar",
    tag: "ul",
    list: _ctx.tasks,
    group: "nested",
    "item-key": "id",
    "ghost-class": "ghost",
    forceFallback: true,
    move: _ctx.checkMove,
    disabled: _ctx.disable,
    onStart: _ctx.startDrag,
    onEnd: _ctx.endDrag
  }, {
    item: _withCtx(({ element, index }) => [
      _createElementVNode("li", null, [
        _createElementVNode("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "c-sortbar__icon" }, [
            _createElementVNode("i", { class: "icon-move c-icon c-icon--gy60 c-icon--fsSm" })
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "content", {
              row: element,
              index: index,
              parentIndex: _ctx.parentIndex
            }, undefined, true)
          ])
        ]),
        (!_ctx.limit)
          ? (_openBlock(), _createBlock(_component_nested_draggable, {
              key: 0,
              tasks: element.tasks,
              limit: true,
              disabled: _ctx.disable,
              parentIndex: index,
              onStart: _ctx.startDrag,
              onEnd: _ctx.endDrag,
              "data-draggable": ""
            }, {
              content: _withCtx(({ row, index, parentIndex }) => [
                _renderSlot(_ctx.$slots, "content", {
                  row: row,
                  index: index,
                  parentIndex: parentIndex
                }, undefined, true)
              ]),
              _: 2
            }, 1032, ["tasks", "disabled", "parentIndex", "onStart", "onEnd"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["list", "move", "disabled", "onStart", "onEnd"]))
}