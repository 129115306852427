import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-card l-card--pvSm" }
const _hoisted_2 = { class: "l-section l-section--sm" }
const _hoisted_3 = { class: "l-section__content" }
const _hoisted_4 = { class: "l-grid u-pNone" }
const _hoisted_5 = { class: "l-grid__row" }
const _hoisted_6 = { class: "l-grid__item" }
const _hoisted_7 = { class: "c-gradientCard" }
const _hoisted_8 = { class: "c-gradientCard__main" }
const _hoisted_9 = { class: "c-gradientCard__num" }
const _hoisted_10 = { class: "l-grid__item" }
const _hoisted_11 = { class: "c-gradientCard" }
const _hoisted_12 = { class: "c-gradientCard__main" }
const _hoisted_13 = { class: "c-gradientCard__num" }
const _hoisted_14 = { class: "l-grid__item" }
const _hoisted_15 = { class: "c-gradientCard" }
const _hoisted_16 = { class: "c-gradientCard__main" }
const _hoisted_17 = { class: "c-gradientCard__num" }
const _hoisted_18 = { class: "l-grid__row" }
const _hoisted_19 = { class: "l-grid__item" }
const _hoisted_20 = { class: "c-gradientCard" }
const _hoisted_21 = { class: "c-gradientCard__main" }
const _hoisted_22 = { class: "c-gradientCard__num" }
const _hoisted_23 = { class: "l-grid__item" }
const _hoisted_24 = { class: "c-gradientCard" }
const _hoisted_25 = { class: "c-gradientCard__main" }
const _hoisted_26 = { class: "c-gradientCard__num" }
const _hoisted_27 = { class: "l-grid__item" }
const _hoisted_28 = { class: "c-gradientCard" }
const _hoisted_29 = { class: "c-gradientCard__main" }
const _hoisted_30 = { class: "c-gradientCard__num" }
const _hoisted_31 = { class: "l-grid__item" }
const _hoisted_32 = { class: "c-gradientCard" }
const _hoisted_33 = { class: "c-gradientCard__main" }
const _hoisted_34 = { class: "c-gradientCard__num" }
const _hoisted_35 = { class: "l-grid__row" }
const _hoisted_36 = { class: "l-grid__item" }
const _hoisted_37 = { class: "c-gradientCard" }
const _hoisted_38 = { class: "c-gradientCard__main" }
const _hoisted_39 = { class: "c-gradientCard__num" }
const _hoisted_40 = { class: "l-grid__item" }
const _hoisted_41 = { class: "c-gradientCard" }
const _hoisted_42 = { class: "c-gradientCard__main" }
const _hoisted_43 = { class: "c-gradientCard__num" }
const _hoisted_44 = { class: "l-grid__item" }
const _hoisted_45 = { class: "c-gradientCard" }
const _hoisted_46 = { class: "c-gradientCard__main" }
const _hoisted_47 = { class: "c-gradientCard__num" }
const _hoisted_48 = { class: "l-grid__item" }
const _hoisted_49 = { class: "c-gradientCard" }
const _hoisted_50 = { class: "c-gradientCard__main" }
const _hoisted_51 = { class: "c-gradientCard__num" }
const _hoisted_52 = { class: "l-grid__row" }
const _hoisted_53 = { class: "l-grid__item" }
const _hoisted_54 = { class: "c-gradientCard" }
const _hoisted_55 = { class: "c-gradientCard__main" }
const _hoisted_56 = { class: "c-gradientCard__num" }
const _hoisted_57 = { class: "l-section l-section--sm" }
const _hoisted_58 = { class: "l-section__content" }
const _hoisted_59 = { class: "l-table" }
const _hoisted_60 = { class: "c-table c-table--evenly" }
const _hoisted_61 = { style: {"overflow":"hidden"} }
const _hoisted_62 = { style: {"overflow":"hidden"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "l-section__head" }, [
        _createElementVNode("div", { class: "l-section__main" }, [
          _createElementVNode("div", { class: "l-section__title" }, [
            _createElementVNode("h3", { class: "c-heading c-heading--fsSm" }, "營業現況")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "總營業額")
                  ], -1)),
                  _createElementVNode("div", _hoisted_9, " ＄" + _toDisplayString(_ctx.formatNumber(
                        _ctx.getNumber(
                          _ctx.sales.storeSale +
                            _ctx.sales.storePre -
                            _ctx.sales.voidedOrder -
                            _ctx.sales.voidedPreOrder +
                            _ctx.sales.storeOnline -
                            _ctx.sales.voidedOnlineOrder +
                            _ctx.sales.storePreOnline -
                            _ctx.sales.voidedPreOnlineOrder +
                            _ctx.sales.storePartialPickup -
                            _ctx.sales.voidedPartialPickup
                        )
                      )), 1)
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "c-gradientCard__icon c-gradientCard__icon--rd" }, [
                  _createElementVNode("i", { class: "icon-coin_bag c-icon c-icon--fsLg c-icon--rd40" })
                ], -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "門市銷售")
                  ], -1)),
                  _createElementVNode("div", _hoisted_13, " ＄" + _toDisplayString(_ctx.formatNumber(
                        _ctx.getNumber(
                          _ctx.sales.storeSale +
                            _ctx.sales.storePre +
                            _ctx.sales.storePartialPickup -
                            _ctx.sales.voidedOrder -
                            _ctx.sales.voidedPreOrder -
                            _ctx.sales.voidedPartialPickup
                        )
                      )), 1)
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "c-gradientCard__icon c-gradientCard__icon--og" }, [
                  _createElementVNode("i", { class: "icon-store c-icon c-icon--fsLg c-icon--og50" })
                ], -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "線上店取付款")
                  ], -1)),
                  _createElementVNode("div", _hoisted_17, " ＄" + _toDisplayString(_ctx.formatNumber(
                        _ctx.getNumber(
                          _ctx.sales.storeOnline +
                            _ctx.sales.storePreOnline -
                            _ctx.sales.voidedOnlineOrder -
                            _ctx.sales.voidedPreOnlineOrder
                        )
                      )), 1)
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "c-gradientCard__icon c-gradientCard__icon--bu" }, [
                  _createElementVNode("i", { class: "icon-screen c-icon c-icon--fsLg c-icon--bu40" })
                ], -1))
              ])
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "l-grid__item" }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "現場折扣金額")
                  ], -1)),
                  _createElementVNode("div", _hoisted_22, " ＄" + _toDisplayString(_ctx.formatNumber(_ctx.getNumber(_ctx.sales.onSaleAmount))), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "門市訂金總額")
                  ], -1)),
                  _createElementVNode("div", _hoisted_26, " ＄" + _toDisplayString(_ctx.formatNumber(
                        _ctx.getNumber(_ctx.sales.storePre - _ctx.sales.voidedPreOrder)
                      )), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "線上預購店取額")
                  ], -1)),
                  _createElementVNode("div", _hoisted_30, " ＄" + _toDisplayString(_ctx.formatNumber(
                        _ctx.getNumber(
                          _ctx.sales.storePreOnline - _ctx.sales.voidedPreOnlineOrder
                        )
                      )), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "寄存訂單總額")
                  ], -1)),
                  _createElementVNode("div", _hoisted_34, "＄" + _toDisplayString(_ctx.formatNumber(
                    _ctx.getNumber(
                    _ctx.sales.storePartialPickup - _ctx.sales.voidedPartialPickup
                    )
                    )), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "門市結帳次數")
                  ], -1)),
                  _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.getNumber(_ctx.sales.quantity + _ctx.sales.preQuantity + _ctx.sales.partialPickupQuantity)), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "門市作廢次數")
                  ], -1)),
                  _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.getNumber(
                        _ctx.sales.voidedQuantity + _ctx.sales.voidedPreOrderQuantity + _ctx.sales.voidedPartialPickupQuantity
                      )), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, [
                  _cache[13] || (_cache[13] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "線上店取結帳次數")
                  ], -1)),
                  _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.getNumber(_ctx.sales.onlineQuantity + _ctx.sales.preOnlineQuantity)), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_48, [
              _createElementVNode("div", _hoisted_49, [
                _createElementVNode("div", _hoisted_50, [
                  _cache[14] || (_cache[14] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "線上店取作廢次數")
                  ], -1)),
                  _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.getNumber(
                        _ctx.sales.voidedOnlineOrderQuantity +
                          _ctx.sales.voidedPreOnlineOrderQuantity
                      )), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_52, [
            _createElementVNode("div", _hoisted_53, [
              _createElementVNode("div", _hoisted_54, [
                _createElementVNode("div", _hoisted_55, [
                  _cache[15] || (_cache[15] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                    _createElementVNode("span", null, "小結次數")
                  ], -1)),
                  _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.getNumber(_ctx.sales.totalTimes)), 1)
                ])
              ])
            ]),
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "l-grid__item" }, null, -1)),
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "l-grid__item" }, null, -1)),
            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "l-grid__item" }, null, -1))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_57, [
      _cache[22] || (_cache[22] = _createElementVNode("div", { class: "l-section__head" }, [
        _createElementVNode("div", { class: "l-section__main" }, [
          _createElementVNode("div", { class: "l-section__title" }, [
            _createElementVNode("h3", { class: "c-heading c-heading--fsSm" }, "商品銷售排行")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_58, [
        _createElementVNode("div", _hoisted_59, [
          _createElementVNode("table", _hoisted_60, [
            _cache[20] || (_cache[20] = _createElementVNode("colgroup", null, [
              _createElementVNode("col", { width: "350px" }),
              _createElementVNode("col", { width: "350px" }),
              _createElementVNode("col"),
              _createElementVNode("col")
            ], -1)),
            _cache[21] || (_cache[21] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "品名"),
                _createElementVNode("th", null, "規格"),
                _createElementVNode("th", null, "銷售量"),
                _createElementVNode("th", null, "銷售額")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", _hoisted_61, _toDisplayString(index + 1) + ". " + _toDisplayString(product.productsName), 1),
                  _createElementVNode("td", _hoisted_62, _toDisplayString(_ctx.getProductStandardName(product)), 1),
                  _createElementVNode("td", null, _toDisplayString(product.quantity), 1),
                  _createElementVNode("td", null, "$ " + _toDisplayString(_ctx.formatNumber(product.price)), 1)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}