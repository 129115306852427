import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-mutiTable__input" }
const _hoisted_2 = { class: "u-textCenter" }
const _hoisted_3 = { class: "c-mutiTable__action" }
const _hoisted_4 = { class: "c-switch" }
const _hoisted_5 = { class: "u-textCenter" }
const _hoisted_6 = { class: "c-mutiTable__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.device.name) = $event)),
          class: "c-textBox"
        }, null, 512), [
          [_vModelText, _ctx.device.name]
        ])
      ])
    ]),
    _createElementVNode("td", null, _toDisplayString(_ctx.device.ipadId), 1),
    _cache[4] || (_cache[4] = _createElementVNode("td", null, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("td", null, null, -1)),
    _cache[6] || (_cache[6] = _createElementVNode("td", null, null, -1)),
    _cache[7] || (_cache[7] = _createElementVNode("td", null, null, -1)),
    _createElementVNode("td", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            class: "c-switch__input",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.device.isEnable) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.device.isEnable]
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
        ])
      ])
    ]),
    _createElementVNode("td", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("i", {
          class: "icon-garbage c-icon c-icon--rd40 u-pointer",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emit('deleteDeviceClick', _ctx.device)))
        })
      ])
    ])
  ]))
}