import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-section u-mvSm u-mhMd" }
const _hoisted_2 = { class: "l-section__content" }
const _hoisted_3 = { class: "l-formGroup" }
const _hoisted_4 = { class: "l-formGroup__content" }
const _hoisted_5 = { class: "l-formGroup__input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fixed = _resolveComponent("Fixed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "l-section__head" }, [
        _createElementVNode("div", { class: "l-section__main" }, [
          _createElementVNode("div", { class: "l-section__title" }, [
            _createElementVNode("h1", { class: "c-heading" }, "群組設定")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("label", { class: "l-formGroup__title l-formGroup__title--wSm" }, [
            _createElementVNode("span", null, "群組名稱")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "c-textBox c-textBox--full",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.groupName) = $event)),
                placeholder: "請輸入名稱"
              }, null, 512), [
                [_vModelText, _ctx.groupName]
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Fixed, {
      onSave: _ctx.save,
      onCancel: _ctx.cancel
    }, null, 8, ["onSave", "onCancel"])
  ], 64))
}