import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "c-outlineList" }
const _hoisted_2 = { class: "c-outlineList__head" }
const _hoisted_3 = { class: "c-outlineList__main" }
const _hoisted_4 = { class: "c-outlineList__checkbox" }
const _hoisted_5 = { class: "c-checkbox" }
const _hoisted_6 = { class: "c-outlineList__list" }
const _hoisted_7 = { class: "c-outlineList__item" }
const _hoisted_8 = { class: "c-outlineList__main" }
const _hoisted_9 = { class: "c-outlineList__checkbox" }
const _hoisted_10 = { class: "c-checkbox" }
const _hoisted_11 = ["id", "onInput"]
const _hoisted_12 = { class: "c-checkbox__text" }
const _hoisted_13 = { class: "c-outlineList__action" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "c-outlineList__subList" }
const _hoisted_16 = { class: "c-outlineList__item" }
const _hoisted_17 = { class: "c-outlineList__main" }
const _hoisted_18 = { class: "c-outlineList__checkbox" }
const _hoisted_19 = { class: "c-checkbox" }
const _hoisted_20 = ["id", "value"]
const _hoisted_21 = { class: "c-checkbox__text" }
const _hoisted_22 = { class: "c-outlineList__item" }
const _hoisted_23 = { class: "c-outlineList__main" }
const _hoisted_24 = { class: "c-outlineList__checkbox" }
const _hoisted_25 = { class: "c-checkbox" }
const _hoisted_26 = ["id"]
const _hoisted_27 = { class: "c-outlineList__action" }
const _hoisted_28 = { class: "c-outlineList__subList" }
const _hoisted_29 = { class: "c-outlineList__item" }
const _hoisted_30 = { class: "c-outlineList__main" }
const _hoisted_31 = { class: "c-outlineList__checkbox" }
const _hoisted_32 = { class: "c-checkbox" }
const _hoisted_33 = ["id", "value"]
const _hoisted_34 = { class: "c-checkbox__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, [
            _createElementVNode("input", {
              type: "checkbox",
              class: "c-checkbox__input",
              onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkAllGroup($event.target.checked)))
            }, null, 32),
            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "c-checkbox__text c-checkbox__text--strong" }, "全選", -1))
          ])
        ])
      ])
    ]),
    _createElementVNode("ul", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, groupIndex) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `${_ctx.aclItem.id}_group_${groupIndex}`,
          class: _normalizeClass({ 'is-open': _ctx.groupShow[groupIndex] })
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    class: "c-checkbox__input",
                    id: `${_ctx.aclItem.id}_group_${groupIndex}`,
                    onInput: ($event: any) => (_ctx.checkGroup(group, $event.target.checked)),
                    ref_for: true,
                    ref: _ctx.setRef
                  }, null, 40, _hoisted_11),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(group.name), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("i", {
                class: _normalizeClass(["icon-arrow_up c-icon u-pointer", [
                  _ctx.groupShow[groupIndex] ? 'icon-arrow_up' : 'icon-arrow_down'
                ]]),
                onClick: ($event: any) => (_ctx.groupShow[groupIndex] = !_ctx.groupShow[groupIndex])
              }, null, 10, _hoisted_14)
            ])
          ]),
          _withDirectives(_createElementVNode("ul", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validEmployees(group), (employee, employeeIndex) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `${_ctx.aclItem.id}_${groupIndex}_${employeeIndex}`
              }, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("label", _hoisted_19, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          class: "c-checkbox__input",
                          id: `${_ctx.aclItem.id}_${groupIndex}_${employeeIndex}`,
                          value: employee.id,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.allowUsers) = $event))
                        }, null, 8, _hoisted_20), [
                          [_vModelCheckbox, _ctx.allowUsers]
                        ]),
                        _createElementVNode("span", _hoisted_21, _toDisplayString(employee.name), 1)
                      ])
                    ])
                  ])
                ])
              ]))
            }), 128))
          ], 512), [
            [_vShow, _ctx.groupShow[groupIndex]]
          ])
        ], 2))
      }), 128)),
      _createElementVNode("li", {
        class: _normalizeClass({ 'is-open': _ctx.noGroup.show })
      }, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("label", _hoisted_25, [
                _createElementVNode("input", {
                  type: "checkbox",
                  class: "c-checkbox__input",
                  id: `${_ctx.aclItem.id}_noGroup`,
                  onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkNoGroup(_ctx.groups, $event.target.checked))),
                  ref: _ctx.setRef
                }, null, 40, _hoisted_26),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "c-checkbox__text" }, "未分類", -1))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("i", {
              class: _normalizeClass(["icon-arrow_up c-icon u-pointer", [_ctx.noGroup.show ? 'icon-arrow_up' : 'icon-arrow_down']]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.noGroup.show = !_ctx.noGroup.show))
            }, null, 2)
          ])
        ]),
        _withDirectives(_createElementVNode("ul", _hoisted_28, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.noGroupEmployees(_ctx.groups), (employee, employeeIndex) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `${_ctx.aclItem.id}_noGroup_${employeeIndex}`
            }, [
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("label", _hoisted_32, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        class: "c-checkbox__input",
                        id: `${_ctx.aclItem.id}_noGroup_${employeeIndex}`,
                        value: employee.id,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.allowUsers) = $event))
                      }, null, 8, _hoisted_33), [
                        [_vModelCheckbox, _ctx.allowUsers]
                      ]),
                      _createElementVNode("span", _hoisted_34, _toDisplayString(employee.name), 1)
                    ])
                  ])
                ])
              ])
            ]))
          }), 128))
        ], 512), [
          [_vShow, _ctx.noGroup.show]
        ])
      ], 2)
    ])
  ]))
}