import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-statusPage" }
const _hoisted_2 = { class: "c-statusPage__main" }
const _hoisted_3 = { class: "c-statusPage__title" }
const _hoisted_4 = ["innerHTML"]

import { useRoute } from 'vue-router'
import { ref } from 'vue'

interface IStatus {
  [key: number]: {
    title: string
    message: string
  }
}


export default /*@__PURE__*/_defineComponent({
  __name: 'common',
  setup(__props) {

const status: IStatus = {
  403: {
    title: '權限不足',
    message: '您沒有檢視權限 <br /> 請與您的商店管理者確認權限'
  }
}

const route = useRoute()

const { code } = route.query
const statusCode = code ? +code : 403
const title = ref(status[statusCode].title)
const message = ref(status[statusCode].message)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "c-statusPage__icon" }, [
        _createElementVNode("i", { class: "icon-alert c-icon c-icon--fsXl c-icon--og40" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, _toDisplayString(title.value), 1),
      _createElementVNode("p", {
        class: "c-statusPage__desc",
        innerHTML: message.value
      }, null, 8, _hoisted_4)
    ])
  ]))
}
}

})