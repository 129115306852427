import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "l-numBoard" }
const _hoisted_2 = { class: "l-numBoard__container" }
const _hoisted_3 = { class: "l-numBoard__item" }
const _hoisted_4 = { class: "c-numBoard" }
const _hoisted_5 = { class: "c-numBoard__num" }
const _hoisted_6 = { class: "l-numBoard__item" }
const _hoisted_7 = { class: "c-numBoard" }
const _hoisted_8 = { class: "c-numBoard__num" }
const _hoisted_9 = { class: "l-numBoard__item" }
const _hoisted_10 = { class: "c-numBoard" }
const _hoisted_11 = { class: "c-numBoard__num" }
const _hoisted_12 = { class: "l-numBoard__item" }
const _hoisted_13 = { class: "c-numBoard" }
const _hoisted_14 = { class: "c-numBoard__num" }
const _hoisted_15 = { class: "c-numBoard" }
const _hoisted_16 = { class: "c-numBoard__num" }
const _hoisted_17 = { class: "c-numBoard__desc" }
const _hoisted_18 = { class: "l-card l-card--pvSm" }
const _hoisted_19 = { class: "l-section l-section--md" }
const _hoisted_20 = { class: "l-section__head l-section__head--underLine" }
const _hoisted_21 = { class: "l-section__actions" }
const _hoisted_22 = { class: "l-section__content" }
const _hoisted_23 = { class: "l-table" }
const _hoisted_24 = { class: "c-table c-table--firstColumn" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { class: "u-flex u-justifyContentBetween" }
const _hoisted_27 = { class: "u-flex u-justifyContentBetween" }
const _hoisted_28 = {
  key: 0,
  class: "u-flex u-justifyContentBetween"
}
const _hoisted_29 = {
  key: 1,
  class: "u-flex u-justifyContentBetween"
}
const _hoisted_30 = {
  key: 2,
  class: "u-flex u-justifyContentBetween"
}
const _hoisted_31 = { class: "l-tool" }
const _hoisted_32 = { class: "c-pager" }
const _hoisted_33 = { class: "c-pager__content" }
const _hoisted_34 = { class: "c-pager__select" }
const _hoisted_35 = ["value"]
const _hoisted_36 = { class: "c-pager__totalNum" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DateRangePicker, {
      propRange: _ctx.range,
      onUpdate: _ctx.updateRange
    }, null, 8, ["propRange", "onUpdate"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formatNumber(_ctx.statistics.storeOrderQuantity)), 1),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "c-numBoard__desc" }, [
              _createTextVNode("門市訂單張數 "),
              _createElementVNode("br")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, " $" + _toDisplayString(_ctx.formatNumber(_ctx.statistics.storeOrder)), 1),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "c-numBoard__desc" }, [
              _createTextVNode("門市結帳金額總計 "),
              _createElementVNode("br")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.formatNumber(_ctx.statistics.onlineOrderQuantity)), 1),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "c-numBoard__desc" }, [
              _createTextVNode("線上訂單張數 "),
              _createElementVNode("br")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, " $" + _toDisplayString(_ctx.formatNumber(_ctx.statistics.onlineOrder)), 1),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "c-numBoard__desc" }, [
              _createTextVNode("線上結帳金額總計 "),
              _createElementVNode("br")
            ], -1))
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statistics.discounts, (discount, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "l-numBoard__item",
            key: index
          }, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, "$" + _toDisplayString(discount.amount), 1),
              _createElementVNode("div", _hoisted_17, [
                _cache[9] || (_cache[9] = _createTextVNode(" 門市訂單 ")),
                _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.discountsMap[index] ?? index) + "金額總計 ", 1)
              ])
            ])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "l-section__main" }, [
            _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h2", { class: "c-heading c-heading--fsMd c-heading--fwNormal" }, " 結帳紀錄 ")
            ])
          ], -1)),
          _createElementVNode("ul", _hoisted_21, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "l-section__title u-textRight" }, [
              _createElementVNode("span", { class: "u-textRight u-textRd60" }, "此列表(含)已作廢訂單。")
            ], -1)),
            _createElementVNode("li", null, [
              _createElementVNode("button", {
                type: "button",
                class: "c-btn c-btn--primary c-btn--thin",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
              }, _cache[11] || (_cache[11] = [
                _createElementVNode("span", null, "Excel報表匯出", -1)
              ]))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("table", _hoisted_24, [
              _cache[18] || (_cache[18] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "訂單編號"),
                  _createElementVNode("th", null, "結帳時間"),
                  _createElementVNode("th", null, "訂單來源"),
                  _createElementVNode("th", null, "發票號碼"),
                  _createElementVNode("th", null, "支付方式"),
                  _createElementVNode("th", null, "結帳資訊"),
                  _createElementVNode("th", null, "經手人員"),
                  _createElementVNode("th", null, "設備名稱"),
                  _createElementVNode("th", null, "會員名稱")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.orderRecords, (orderRecord, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `${_ctx.paginationData.page}_${index}`
                  }, [
                    _createElementVNode("td", null, _toDisplayString(orderRecord.no), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatDateTime(orderRecord.createdAt)), 1),
                    _createElementVNode("td", {
                      innerHTML: _ctx.orderPosTypeRawHtml(orderRecord.orderPosType)
                    }, null, 8, _hoisted_25),
                    _createElementVNode("td", null, _toDisplayString(orderRecord.invoiceNo), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.paymentsMap[orderRecord.payment] ?? orderRecord.paymentLabel), 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_26, [
                        _cache[14] || (_cache[14] = _createElementVNode("span", null, "實收：", -1)),
                        _createElementVNode("span", null, "$" + _toDisplayString(_ctx.formatNumber(orderRecord.moneyReceived)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _cache[15] || (_cache[15] = _createElementVNode("span", null, "找零：", -1)),
                        _createElementVNode("span", null, "$" + _toDisplayString(_ctx.formatNumber(orderRecord.makeChange)), 1)
                      ]),
                      (
                      orderRecord.creditCard !== null ||
                      orderRecord.creditCardCode !== null
                    )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.cardTypesMap[orderRecord.creditCard] ??
                      orderRecord.creditCard), 1),
                            _createElementVNode("span", null, "#" + _toDisplayString(orderRecord.creditCardCode), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (orderRecord.approvalCode !== null)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                            _cache[16] || (_cache[16] = _createElementVNode("span", null, "授權碼：", -1)),
                            _createElementVNode("span", null, _toDisplayString(orderRecord.approvalCode), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (orderRecord.referenceNo !== null)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                            _cache[17] || (_cache[17] = _createElementVNode("span", null, "簽單序號：", -1)),
                            _createElementVNode("span", null, _toDisplayString(orderRecord.referenceNo), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getEmployeeName(orderRecord.employeeNo) ?? '-'), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getDeviceName(orderRecord.deviceId)), 1),
                    _createElementVNode("td", null, _toDisplayString(orderRecord.memberName ?? ''), 1)
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("span", {
                class: "c-pager__arrow",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prePage && _ctx.prePage(...args)))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["icon-arrow_left c-icon c-icon--fsSm", [
                  _ctx.paginationData.page > 1
                    ? 'u-pointer c-icon--gy60'
                    : 'u-notAllowed c-icon--gy40'
                ]])
                }, null, 2)
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _withDirectives(_createElementVNode("select", {
                    class: "c-toolSelect",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.paginationData.page) = $event)),
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goPage($event.target.value)))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.lastPage, (page) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: page,
                        key: page
                      }, _toDisplayString(page), 9, _hoisted_35))
                    }), 128))
                  ], 544), [
                    [_vModelSelect, _ctx.paginationData.page]
                  ])
                ]),
                _cache[19] || (_cache[19] = _createElementVNode("span", { class: "c-pager__text" }, "頁", -1)),
                _cache[20] || (_cache[20] = _createElementVNode("span", { class: "c-pager__separator" }, "，", -1)),
                _cache[21] || (_cache[21] = _createElementVNode("span", { class: "c-pager__text" }, "共", -1)),
                _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.paginationData.total), 1),
                _cache[22] || (_cache[22] = _createElementVNode("span", { class: "c-pager__text" }, "筆", -1))
              ]),
              _createElementVNode("span", {
                class: "c-pager__arrow",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["icon-arrow_right c-icon c-icon--fsSm", [
                  _ctx.paginationData.page < _ctx.paginationData.lastPage
                    ? 'u-pointer c-icon--gy60'
                    : 'u-notAllowed c-icon--gy40'
                ]])
                }, null, 2)
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}