import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-permission"
}
const _hoisted_2 = { class: "c-permission__content" }

import { computed, PropType } from 'vue'
import { useStore } from 'vuex'
import helper from '@/helper'

export interface action {
  permissionKey: string
  name: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'reportPermission',
  props: {
  actions: Array as PropType<Array<action>>
},
  setup(__props) {

const store = useStore()
const storeAdminPermissionKeys = computed(
  () => store.getters.storeAdminPermissionKeys
)

const props = __props

function hasPermission(action: action) {
  return storeAdminPermissionKeys.value.includes(action.permissionKey)
}

function showPermissionArea() {
  return !(helper.isAdmin() || helper.isStoreAdmin())
}


return (_ctx: any,_cache: any) => {
  return (showPermissionArea())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "c-permission__title" }, "目前權限", -1)),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.actions, (action) => {
            return (_openBlock(), _createElementBlock("span", {
              class: _normalizeClass(["c-permission__tag", { 'is-allow': hasPermission(action) }]),
              key: action.permissionKey
            }, _toDisplayString(action.name), 3))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})