import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'analysisPerformance' }]),
        to: { name: 'analysisPerformance' }
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, "業績概況", -1)
        ])),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'analysisDiscount' }]),
        to: { name: 'analysisDiscount' }
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("span", null, "折扣概況", -1)
        ])),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'analysisRevenue' }]),
        to: { name: 'analysisRevenue' }
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("span", null, "營收/短溢", -1)
        ])),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'analysisStoreOrder' }]),
        to: { name: 'analysisStoreOrder' }
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("span", null, "門市訂單結帳紀錄", -1)
        ])),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'analysisOnlineOrder' }]),
        to: { name: 'analysisOnlineOrder' }
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("span", null, "線上訂單結帳紀錄", -1)
        ])),
        _: 1
      }, 8, ["class"])
    ])
  ]))
}