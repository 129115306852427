import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-status__icon" }
const _hoisted_2 = { class: "c-status__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["c-status", _ctx.classType.class])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("i", {
            class: _normalizeClass(["c-icon c-icon--fsXs", _ctx.classType.iconClass])
          }, null, 2)
        ]),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.message), 1),
        (_ctx.closeAble)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "c-status__close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("i", { class: "icon-close" }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ], 2), [
        [_vShow, _ctx.time > 0 && _ctx.show]
      ])
    ]),
    _: 1
  }))
}