import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-tabContent c-tabContent--noSpace" }
const _hoisted_2 = { class: "l-grid l-grid--splitV l-grid--gXl" }
const _hoisted_3 = { class: "l-grid__row" }
const _hoisted_4 = { class: "l-grid__item l-grid__item--3col" }
const _hoisted_5 = { class: "l-grid__item l-grid__item--9col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_nav = _resolveComponent("app-nav")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_nav),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Sidebar)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createBlock(_component_router_view, {
              key: _ctx.route.path
            }))
          ])
        ])
      ])
    ])
  ], 64))
}