import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "c-tabs" }
const _hoisted_2 = { class: "c-tabContent" }
const _hoisted_3 = { class: "l-gradientCard" }
const _hoisted_4 = { class: "l-gradientCard__item" }
const _hoisted_5 = { class: "c-gradientCard" }
const _hoisted_6 = { class: "c-gradientCard__main" }
const _hoisted_7 = { class: "c-gradientCard__num" }
const _hoisted_8 = { class: "l-gradientCard__item" }
const _hoisted_9 = { class: "c-gradientCard" }
const _hoisted_10 = { class: "c-gradientCard__main" }
const _hoisted_11 = { class: "c-gradientCard__num" }
const _hoisted_12 = { class: "l-gradientCard__item" }
const _hoisted_13 = { class: "c-gradientCard" }
const _hoisted_14 = { class: "c-gradientCard__main" }
const _hoisted_15 = { class: "c-gradientCard__num" }
const _hoisted_16 = { class: "l-section l-section--md" }
const _hoisted_17 = { class: "l-section__content" }
const _hoisted_18 = { class: "l-table" }
const _hoisted_19 = { class: "c-table c-table--mutiData" }
const _hoisted_20 = { class: "l-tool" }
const _hoisted_21 = { class: "c-pager" }
const _hoisted_22 = { class: "c-pager__content" }
const _hoisted_23 = { class: "c-pager__select" }
const _hoisted_24 = ["value"]
const _hoisted_25 = { class: "c-pager__totalNum" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'partialpickup' }]),
          to: { name: 'partialpickup' }
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createElementVNode("span", null, "寄存概況", -1)
          ])),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'partialpickupRecord' }]),
          to: { name: 'partialpickupRecord' }
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("span", null, "寄存紀錄", -1)
          ])),
          _: 1
        }, 8, ["class"])
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                _createElementVNode("span", null, "寄存商品總數")
              ], -1)),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.formatNumber(_ctx.statistics.unclaimed + _ctx.statistics.claimed)), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                _createElementVNode("span", null, "已取總數")
              ], -1)),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.formatNumber(_ctx.statistics.claimed)), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                _createElementVNode("span", null, "未取總數")
              ], -1)),
              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formatNumber(_ctx.statistics.unclaimed)), 1)
            ])
          ])
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "l-gradientCard__item" }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "l-section__head" }, [
          _createElementVNode("div", { class: "l-section__main" }, [
            _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h2", { class: "c-heading c-heading--fsMd c-heading--fwNormal" }, " 目前寄存商品 ")
            ])
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("table", _hoisted_19, [
              _cache[10] || (_cache[10] = _createElementVNode("colgroup", null, [
                _createElementVNode("col", { width: "200px" }),
                _createElementVNode("col", { width: "200px" }),
                _createElementVNode("col", { width: "100px" }),
                _createElementVNode("col", { width: "100px" }),
                _createElementVNode("col", { width: "100px" })
              ], -1)),
              _cache[11] || (_cache[11] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "品巷名稱"),
                  _createElementVNode("th", null, "規格"),
                  _createElementVNode("th", null, "寄存數"),
                  _createElementVNode("th", null, "已取數"),
                  _createElementVNode("th", null, "未取數")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.products, (product, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `${_ctx.paginationData.page}_${index}`
                  }, [
                    _createElementVNode("td", null, _toDisplayString(product.productName), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getProductStandardName(product)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatNumber(product.unclaimed + product.claimed)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatNumber(product.claimed)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatNumber(product.unclaimed)), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("span", {
              class: "c-pager__arrow",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prePage && _ctx.prePage(...args)))
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(["icon-arrow_left c-icon c-icon--fsSm", [
                _ctx.paginationData.page > 1
                  ? 'u-pointer c-icon--gy60'
                  : 'u-notAllowed c-icon--gy40'
              ]])
              }, null, 2)
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _withDirectives(_createElementVNode("select", {
                  class: "c-toolSelect",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.paginationData.page) = $event)),
                  onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goPage($event.target.value)))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.lastPage, (page) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: page,
                      key: page
                    }, _toDisplayString(page), 9, _hoisted_24))
                  }), 128))
                ], 544), [
                  [_vModelSelect, _ctx.paginationData.page]
                ])
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("span", { class: "c-pager__text" }, "頁", -1)),
              _cache[13] || (_cache[13] = _createElementVNode("span", { class: "c-pager__separator" }, "，", -1)),
              _cache[14] || (_cache[14] = _createElementVNode("span", { class: "c-pager__text" }, "共", -1)),
              _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.paginationData.total), 1),
              _cache[15] || (_cache[15] = _createElementVNode("span", { class: "c-pager__text" }, "筆", -1))
            ]),
            _createElementVNode("span", {
              class: "c-pager__arrow",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(["icon-arrow_right c-icon c-icon--fsSm", [
                _ctx.paginationData.page < _ctx.paginationData.lastPage
                  ? 'u-pointer c-icon--gy60'
                  : 'u-notAllowed c-icon--gy40'
              ]])
              }, null, 2)
            ])
          ])
        ])
      ])
    ])
  ], 64))
}