import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "l-mask__container" }
const _hoisted_2 = { class: "c-modal__container" }
const _hoisted_3 = { class: "l-actions" }
const _hoisted_4 = { class: "l-actions" }
const _hoisted_5 = { class: "l-actions__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: ".l-container"
      }, [
        _createElementVNode("div", {
          class: "l-mask",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close('mask'))),
          onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.close('mask')), ["esc"])),
          tabindex: "0"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["c-modal", _ctx.sizeMap[_ctx.modalSize]]),
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "header"),
                _createElementVNode("div", {
                  class: _normalizeClass(["c-modal__content", _ctx.alignClassMap[_ctx.contentAlign]])
                }, [
                  _renderSlot(_ctx.$slots, "content")
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["c-modal__footer", _ctx.alignClassMap[_ctx.btnAlign]])
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "button", {}, () => [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "c-btn c-btn--secondary",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close('modal')))
                          }, _cache[4] || (_cache[4] = [
                            _createElementVNode("span", null, "關閉", -1)
                          ]))
                        ])
                      ])
                    ])
                  ])
                ], 2)
              ])
            ], 2)
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}