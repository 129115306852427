import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "c-settingTabs" }
const _hoisted_2 = { class: "c-settingTabs__action" }
const _hoisted_3 = ["onDrop"]
const _hoisted_4 = { class: "c-settingList" }
const _hoisted_5 = { class: "c-settingList__title" }
const _hoisted_6 = { class: "c-settingList__actions" }
const _hoisted_7 = { class: "c-gadget" }
const _hoisted_8 = ["onDragstart"]
const _hoisted_9 = { class: "c-settingList__title" }
const _hoisted_10 = { class: "c-settingList__actions" }
const _hoisted_11 = { class: "c-gadget" }
const _hoisted_12 = ["onDragstart"]
const _hoisted_13 = { class: "c-settingList__title" }
const _hoisted_14 = { class: "c-settingList__actions" }
const _hoisted_15 = { class: "c-gadget" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        to: { name: 'authPermission' },
        class: _normalizeClass(["c-settingTabs__panel", { 'is-active': _ctx.route.name === 'authPermission' }])
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createElementVNode("div", { class: "c-settingTabs__main" }, [
            _createElementVNode("span", { class: "c-settingTabs__title" }, "門市權限設定")
          ], -1)
        ])),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        to: { name: 'authGroupCreate' },
        class: _normalizeClass(["c-settingTabs__panel", { 'is-active': _ctx.route.name === 'authGroupCreate' }])
      }, {
        default: _withCtx(() => [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "c-settingTabs__main" }, [
            _createElementVNode("span", { class: "c-settingTabs__title" }, "群組")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("i", {
              class: _normalizeClass(["icon-add c-icon c-icon--fsSm", [
              _ctx.route.name === 'authGroupCreate' ? 'c-icon--og70' : 'c-icon--gy60'
            ]])
            }, null, 2)
          ])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        onDrop: ($event: any) => (_ctx.employeeOnDrop($event, group)),
        onDragenter: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
        onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", {
            class: _normalizeClass(["is-first", [
            (_ctx.route.name === 'authEmployeeCreate' &&
              parseInt(_ctx.route.query.groupId) === parseInt(group.id)) ||
            (_ctx.route.name === 'authGroup' &&
              parseInt(_ctx.route.params.groupId) === parseInt(group.id))
              ? 'is-active'
              : ''
          ]])
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(group.name), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("ul", _hoisted_7, [
                _createElementVNode("li", null, [
                  _createVNode(_component_router_link, {
                    to: {
                    name: 'authEmployeeCreate',
                    query: { groupId: group.id }
                  }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("i", {
                        class: _normalizeClass(["icon-add c-icon c-icon--fsSm", [
                      (_ctx.route.name === 'authEmployeeCreate' &&
                        parseInt(_ctx.route.query.groupId) === parseInt(group.id)) ||
                      (_ctx.route.name === 'authGroup' &&
                        parseInt(_ctx.route.params.groupId) === parseInt(group.id))
                        ? 'c-icon--og70'
                        : 'c-icon--gy60'
                    ]])
                      }, null, 2)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _createElementVNode("li", null, [
                  _createVNode(_component_router_link, {
                    to: { name: 'authGroup', params: { groupId: group.id } }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("i", {
                        class: _normalizeClass(["icon-editor c-icon c-icon--fsSm", [
                      (_ctx.route.name === 'authEmployeeCreate' &&
                        parseInt(_ctx.route.query.groupId) === parseInt(group.id)) ||
                      (_ctx.route.name === 'authGroup' &&
                        parseInt(_ctx.route.params.groupId) === parseInt(group.id))
                        ? 'c-icon--og70'
                        : 'c-icon--gy60'
                    ]])
                      }, null, 2)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ])
            ])
          ], 2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validEmployees(group), (employee, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `${group.name}_${index}`,
              draggable: "true",
              onDragstart: ($event: any) => (_ctx.employeeStartDrag($event, group, employee)),
              class: _normalizeClass([
            _ctx.route.name === 'authEmployee' &&
            parseInt(_ctx.route.params.employeeId) === parseInt(employee.id)
              ? 'is-active'
              : ''
          ])
            }, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(employee.name), 1),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("ul", _hoisted_11, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, {
                      to: {
                    name: 'authEmployee',
                    params: { employeeId: employee.id }
                  }
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("i", {
                          class: _normalizeClass(["icon-editor c-icon c-icon--fsSm", [
                      _ctx.route.name === 'authEmployee' &&
                      parseInt(_ctx.route.params.employeeId) ===
                        parseInt(employee.id)
                        ? 'c-icon--og70'
                        : 'c-icon--gy60'
                    ]])
                        }, null, 2)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["icon-move c-icon c-icon--fsSm u-move", [
                    _ctx.route.name === 'authEmployee' &&
                    parseInt(_ctx.route.params.employeeId) === parseInt(employee.id)
                      ? 'c-icon--og70'
                      : 'c-icon--gy60'
                  ]])
                    }, null, 2)
                  ])
                ])
              ])
            ], 42, _hoisted_8))
          }), 128))
        ])
      ], 40, _hoisted_3))
    }), 128)),
    _createElementVNode("li", null, [
      _createElementVNode("ul", {
        class: "c-settingList",
        onDrop: _cache[4] || (_cache[4] = ($event: any) => (_ctx.employeeOnDrop($event, 'null'))),
        onDragenter: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
        onDragover: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _cache[9] || (_cache[9] = _createElementVNode("li", { class: "is-first" }, [
          _createElementVNode("span", { class: "c-settingList__title" }, "未分類"),
          _createElementVNode("div", { class: "c-settingList__actions" }, [
            _createElementVNode("ul", { class: "c-gadget" })
          ])
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.noGroupEmployees(_ctx.groups), (employee, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: `noGroupEmployees_${index}`,
            draggable: "true",
            onDragstart: ($event: any) => (_ctx.employeeStartDrag($event, null, employee)),
            onDragenter: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
            onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
            class: _normalizeClass([
            _ctx.route.name === 'authEmployee' &&
            parseInt(_ctx.route.params.employeeId) === parseInt(employee.id)
              ? 'is-active'
              : ''
          ])
          }, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(employee.name), 1),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("ul", _hoisted_15, [
                _createElementVNode("li", null, [
                  _createVNode(_component_router_link, {
                    to: {
                    name: 'authEmployee',
                    params: { employeeId: employee.id }
                  }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("i", {
                        class: _normalizeClass(["icon-editor c-icon c-icon--fsSm", [
                      _ctx.route.name === 'authEmployee' &&
                      parseInt(_ctx.route.params.employeeId) ===
                        parseInt(employee.id)
                        ? 'c-icon--og70'
                        : 'c-icon--gy60'
                    ]])
                      }, null, 2)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("i", {
                    class: _normalizeClass(["icon-move c-icon c-icon--fsSm u-move", [
                    _ctx.route.name === 'authEmployee' &&
                    parseInt(_ctx.route.params.employeeId) === parseInt(employee.id)
                      ? 'c-icon--og70'
                      : 'c-icon--gy60'
                  ]])
                  }, null, 2)
                ])
              ])
            ])
          ], 42, _hoisted_12))
        }), 128))
      ], 32)
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["c-settingTabs__panel", { 'is-active': _ctx.route.name === 'authResigned' }]),
        to: { name: 'authResigned' }
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createElementVNode("div", { class: "c-settingTabs__main" }, [
            _createElementVNode("span", { class: "c-settingTabs__title" }, "離職員工列表")
          ], -1)
        ])),
        _: 1
      }, 8, ["class"])
    ])
  ]))
}