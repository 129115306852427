import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, vModelDynamic as _vModelDynamic } from "vue"

const _hoisted_1 = { class: "l-section__content" }
const _hoisted_2 = { class: "c-stripCard__head" }
const _hoisted_3 = { class: "c-stripCard__item" }
const _hoisted_4 = { class: "c-stripCard__main" }
const _hoisted_5 = { class: "c-stripCard__title" }
const _hoisted_6 = {
  key: 0,
  class: "c-stripCard__actions"
}
const _hoisted_7 = { class: "c-gadget c-gadget--gLg" }
const _hoisted_8 = { class: "c-switch" }
const _hoisted_9 = ["onUpdate:modelValue", "data-payment-id"]
const _hoisted_10 = {
  key: 0,
  class: "c-stripBox__content"
}
const _hoisted_11 = { class: "l-form u-mvLg" }
const _hoisted_12 = { class: "l-form__wrap" }
const _hoisted_13 = {
  key: 0,
  class: "l-formGroup"
}
const _hoisted_14 = { class: "l-formGroup__content l-formGroup__content--level" }
const _hoisted_15 = { class: "l-formGroup__input" }
const _hoisted_16 = ["data-clipboard-text"]
const _hoisted_17 = { class: "l-formGroup__title" }
const _hoisted_18 = { class: "l-formGroup__content" }
const _hoisted_19 = { class: "l-formGroup__input" }
const _hoisted_20 = ["type", "name", "onUpdate:modelValue"]
const _hoisted_21 = {
  key: 1,
  class: "l-form u-mvLg"
}
const _hoisted_22 = { class: "l-formAction" }
const _hoisted_23 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_head = _resolveComponent("app-head")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentTypes, (paymentType, paymentTypeIndex) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "l-section",
      key: paymentTypeIndex
    }, [
      _createVNode(_component_app_head, {
        "prop-title": paymentType.name
      }, null, 8, ["prop-title"]),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paymentType.payments, (payment, paymentIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "c-stripCard",
            key: paymentIndex
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.paymentsMap[payment.id] ?? payment.id), 1)
                ]),
                (payment.isUpdate)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("ul", _hoisted_7, [
                        _createElementVNode("li", null, [
                          _createElementVNode("label", _hoisted_8, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              class: "c-switch__input",
                              "onUpdate:modelValue": ($event: any) => ((payment.isEnable) = $event),
                              "data-payment-id": payment.id,
                              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changePaymentEnable && _ctx.changePaymentEnable(...args)))
                            }, null, 40, _hoisted_9), [
                              [_vModelCheckbox, payment.isEnable]
                            ]),
                            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (payment.setting.length > 0 && payment.isEnable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        (payment.id == 'jkoPay-offline')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              _cache[5] || (_cache[5] = _createElementVNode("label", { class: "l-formGroup__title" }, [
                                _createElementVNode("span", null, "Store ID")
                              ], -1)),
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("div", _hoisted_15, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "text",
                                    class: "c-textBox c-textBox--full",
                                    name: "storeId",
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.storeUid) = $event)),
                                    disabled: ""
                                  }, null, 512), [
                                    [_vModelText, _ctx.storeUid]
                                  ])
                                ]),
                                _createElementVNode("a", {
                                  href: "#",
                                  class: "u-mlSm t2 u-textGy60 js_copy_store",
                                  "data-clipboard-text": _ctx.storeUid,
                                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyStoreUid && _ctx.copyStoreUid(...args)))
                                }, _cache[4] || (_cache[4] = [
                                  _createElementVNode("i", { class: "icon-copy" }, null, -1)
                                ]), 8, _hoisted_16)
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(payment.setting, (setting, settingIndex) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "l-formGroup",
                            key: settingIndex
                          }, [
                            _createElementVNode("label", _hoisted_17, [
                              _createElementVNode("span", null, _toDisplayString(setting.label), 1)
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("div", _hoisted_19, [
                                _withDirectives(_createElementVNode("input", {
                                  type: setting.isPassword ? 'password' : 'text',
                                  class: "c-textBox c-textBox--full",
                                  name: setting.key,
                                  "onUpdate:modelValue": ($event: any) => ((setting.value) = $event),
                                  autocomplete: "off"
                                }, null, 8, _hoisted_20), [
                                  [_vModelDynamic, setting.value]
                                ])
                              ])
                            ])
                          ]))
                        }), 128)),
                        (payment.info != '')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(payment.info), 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "c-btn c-btn--primary",
                            onClick: ($event: any) => (_ctx.updateSetting(payment))
                          }, " 儲存 ", 8, _hoisted_23)
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ])
    ]))
  }), 128))
}