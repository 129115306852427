import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "c-tabs" }
const _hoisted_2 = { class: "l-card l-card--pvSm" }
const _hoisted_3 = { class: "l-section l-section--md" }
const _hoisted_4 = { class: "l-section__head l-section__head--underLine" }
const _hoisted_5 = { class: "l-section__actions" }
const _hoisted_6 = { class: "l-section__content" }
const _hoisted_7 = { class: "l-table" }
const _hoisted_8 = { class: "c-table c-table--firstColumn" }
const _hoisted_9 = { class: "l-tool" }
const _hoisted_10 = { class: "c-pager" }
const _hoisted_11 = { class: "c-pager__content" }
const _hoisted_12 = { class: "c-pager__select" }
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "c-pager__totalNum" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DateRangePicker, {
      propRange: _ctx.range,
      onUpdate: _ctx.updateRange
    }, null, 8, ["propRange", "onUpdate"]),
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'partialpickup' }]),
          to: { name: 'partialpickup' }
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("span", null, "寄存概況", -1)
          ])),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'partialpickupRecord' }]),
          to: { name: 'partialpickupRecord' }
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createElementVNode("span", null, "寄存紀錄", -1)
          ])),
          _: 1
        }, 8, ["class"])
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "l-section__main" }, [
            _createElementVNode("span", { class: "u-textLeft u-textRd60" }, "此列表為全門市寄存商品數量統計概況。")
          ], -1)),
          _createElementVNode("ul", _hoisted_5, [
            _createElementVNode("li", null, [
              _createElementVNode("button", {
                type: "button",
                class: "c-btn c-btn--primary c-btn--thin",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("span", null, "Excel報表匯出", -1)
              ]))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("table", _hoisted_8, [
              _cache[9] || (_cache[9] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "品項名稱"),
                  _createElementVNode("th", null, "規格"),
                  _createElementVNode("th", null, "寄存數"),
                  _createElementVNode("th", null, "取貨數"),
                  _createElementVNode("th", null, "作廢數"),
                  _createElementVNode("th", null, "作廢回補數")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.records, (record, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `${_ctx.paginationData.page}_${index}`
                  }, [
                    _createElementVNode("td", null, _toDisplayString(record.productName), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getProductStandardName(record)), 1),
                    _createElementVNode("td", null, _toDisplayString(record.New ?? 0), 1),
                    _createElementVNode("td", null, _toDisplayString(record.Pickup ?? 0), 1),
                    _createElementVNode("td", null, _toDisplayString(record.Cancel ?? 0), 1),
                    _createElementVNode("td", null, _toDisplayString(record.Refund ?? 0), 1)
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", {
                class: "c-pager__arrow",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prePage && _ctx.prePage(...args)))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["icon-arrow_left c-icon c-icon--fsSm", [
                  _ctx.paginationData.page > 1
                    ? 'u-pointer c-icon--gy60'
                    : 'u-notAllowed c-icon--gy40'
                ]])
                }, null, 2)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _withDirectives(_createElementVNode("select", {
                    class: "c-toolSelect",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.paginationData.page) = $event)),
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goPage($event.target.value)))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.lastPage, (page) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: page,
                        key: page
                      }, _toDisplayString(page), 9, _hoisted_13))
                    }), 128))
                  ], 544), [
                    [_vModelSelect, _ctx.paginationData.page]
                  ])
                ]),
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "c-pager__text" }, "頁", -1)),
                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "c-pager__separator" }, "，", -1)),
                _cache[12] || (_cache[12] = _createElementVNode("span", { class: "c-pager__text" }, "共", -1)),
                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.paginationData.total), 1),
                _cache[13] || (_cache[13] = _createElementVNode("span", { class: "c-pager__text" }, "筆", -1))
              ]),
              _createElementVNode("span", {
                class: "c-pager__arrow",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["icon-arrow_right c-icon c-icon--fsSm", [
                  _ctx.paginationData.page < _ctx.paginationData.lastPage
                    ? 'u-pointer c-icon--gy60'
                    : 'u-notAllowed c-icon--gy40'
                ]])
                }, null, 2)
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}