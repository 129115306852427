import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-tabContent" }
const _hoisted_2 = { class: "c-outlineBox" }
const _hoisted_3 = { class: "c-outlineBox__content" }
const _hoisted_4 = { class: "l-sort" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "l-sort__content" }
const _hoisted_7 = { class: "u-text-ellipsis-1 u-w100" }
const _hoisted_8 = {
  style: {"width":"85px"},
  class: "u-mlSm"
}
const _hoisted_9 = ["onUpdate:modelValue", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nested_draggable = _resolveComponent("nested-draggable")!
  const _component_notification = _resolveComponent("notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "c-outlineBox__head" }, [
        _createElementVNode("div", { class: "c-outlineBox__main" }, [
          _createElementVNode("div", { class: "c-outlineBox__title" }, "分類名稱")
        ]),
        _createElementVNode("div", { class: "c-outlineBox__title u-mrLg" }, "APP顯示")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_nested_draggable, {
          tasks: _ctx.lists,
          disable: _ctx.draggableDisable,
          onUpdate: _ctx.updateListsSort
        }, {
          content: _withCtx(({ row, index, parentIndex }) => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "l-sort__message",
                "data-message": "",
                id: 
                  parentIndex !== undefined
                    ? `item-${parentIndex}-${index}`
                    : `item-${index}`
                
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(row.name), 1),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", {
                    class: "c-switch",
                    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.draggableDisable = true)),
                    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.draggableDisable = false))
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      class: "c-switch__input",
                      "onUpdate:modelValue": ($event: any) => ((row.isVisible) = $event),
                      onChange: ($event: any) => (
                        _ctx.switchAppVisible(
                          row.id,
                          row.isVisible,
                          index,
                          parentIndex
                        )
                      )
                    }, null, 40, _hoisted_9), [
                      [_vModelCheckbox, row.isVisible]
                    ]),
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                  ], 32)
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["tasks", "disable", "onUpdate"]),
        (_ctx.messageVisible)
          ? (_openBlock(), _createBlock(_Teleport, {
              key: 0,
              to: _ctx.teleportTarget
            }, [
              _createVNode(_component_notification)
            ], 8, ["to"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}