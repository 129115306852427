import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-card" }
const _hoisted_2 = { class: "l-section l-section--md" }
const _hoisted_3 = { class: "l-section__head" }
const _hoisted_4 = { class: "l-section__main" }
const _hoisted_5 = { class: "l-section__title" }
const _hoisted_6 = { class: "c-heading c-heading--fsMd c-heading--fwNormal" }
const _hoisted_7 = { class: "c-note u-mlSm u-bold" }
const _hoisted_8 = { class: "l-section__actions" }
const _hoisted_9 = { class: "l-section__content" }
const _hoisted_10 = { class: "l-grid u-mbLg u-pNone" }
const _hoisted_11 = { class: "l-grid__row" }
const _hoisted_12 = { class: "l-grid__item" }
const _hoisted_13 = { class: "c-gradientCard" }
const _hoisted_14 = { class: "c-gradientCard__main" }
const _hoisted_15 = { class: "c-gradientCard__num" }
const _hoisted_16 = { class: "l-grid__item" }
const _hoisted_17 = { class: "c-gradientCard" }
const _hoisted_18 = { class: "c-gradientCard__main" }
const _hoisted_19 = { class: "c-gradientCard__num" }
const _hoisted_20 = { class: "l-grid__item" }
const _hoisted_21 = { class: "c-gradientCard" }
const _hoisted_22 = { class: "c-gradientCard__main" }
const _hoisted_23 = { class: "c-gradientCard__num" }
const _hoisted_24 = { class: "l-grid__item" }
const _hoisted_25 = { class: "c-gradientCard" }
const _hoisted_26 = { class: "c-gradientCard__main" }
const _hoisted_27 = { class: "c-gradientCard__num" }
const _hoisted_28 = { class: "l-grid__row" }
const _hoisted_29 = { class: "l-grid__item" }
const _hoisted_30 = { class: "c-gradientCard" }
const _hoisted_31 = { class: "c-gradientCard__main" }
const _hoisted_32 = { class: "c-gradientCard__num" }
const _hoisted_33 = { class: "l-grid__item" }
const _hoisted_34 = { class: "c-gradientCard" }
const _hoisted_35 = { class: "c-gradientCard__main" }
const _hoisted_36 = { class: "c-gradientCard__num" }
const _hoisted_37 = { class: "l-grid__item" }
const _hoisted_38 = { class: "c-gradientCard" }
const _hoisted_39 = { class: "c-gradientCard__main" }
const _hoisted_40 = { class: "c-gradientCard__num" }
const _hoisted_41 = { class: "l-grid__item" }
const _hoisted_42 = { class: "c-gradientCard" }
const _hoisted_43 = { class: "c-gradientCard__main" }
const _hoisted_44 = { class: "c-gradientCard__num" }
const _hoisted_45 = { class: "l-section__head" }
const _hoisted_46 = { class: "l-section__main" }
const _hoisted_47 = { class: "l-section__switch" }
const _hoisted_48 = { class: "l-section__accordion" }
const _hoisted_49 = { class: "l-table" }
const _hoisted_50 = { class: "c-table" }
const _hoisted_51 = { class: "l-section__head" }
const _hoisted_52 = { class: "l-section__main" }
const _hoisted_53 = { class: "l-section__switch" }
const _hoisted_54 = { class: "l-section__accordion" }
const _hoisted_55 = { class: "l-table" }
const _hoisted_56 = { class: "c-table" }
const _hoisted_57 = { class: "l-section__head" }
const _hoisted_58 = { class: "l-section__main" }
const _hoisted_59 = { class: "l-section__switch" }
const _hoisted_60 = { class: "l-section__accordion" }
const _hoisted_61 = { class: "l-table" }
const _hoisted_62 = { class: "c-table" }
const _hoisted_63 = { class: "l-section__head" }
const _hoisted_64 = { class: "l-section__main" }
const _hoisted_65 = { class: "l-section__switch" }
const _hoisted_66 = { class: "l-section__accordion" }
const _hoisted_67 = { class: "l-table" }
const _hoisted_68 = { class: "c-table" }
const _hoisted_69 = { class: "l-section__head" }
const _hoisted_70 = { class: "l-section__main" }
const _hoisted_71 = { class: "l-section__switch" }
const _hoisted_72 = { class: "l-section__accordion" }
const _hoisted_73 = { class: "l-table" }
const _hoisted_74 = { class: "c-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      to: {
      name: 'handover',
      params: {
        date: _ctx.date
      }
    },
      class: "c-iconWord u-mbSm"
    }, {
      default: _withCtx(() => _cache[6] || (_cache[6] = [
        _createElementVNode("span", { class: "c-iconWord__icon" }, [
          _createElementVNode("i", { class: "icon-arrow_left c-icon c-icon--gy60" })
        ], -1),
        _createElementVNode("span", { class: "c-iconWord__text u-textGy60" }, "返回 小結 | 關帳", -1)
      ])),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.subject) + "明細 ", 1)
            ]),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.minStartAt + '~' + _ctx.maxEndAt), 1)
          ]),
          _createElementVNode("ul", _hoisted_8, [
            _createElementVNode("li", null, [
              _createElementVNode("button", {
                type: "button",
                class: "c-btn c-btn--primary c-btn--thin",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("span", null, "Excel報表匯出", -1)
              ]))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                      _createElementVNode("span", null, "總營業額")
                    ], -1)),
                    _createElementVNode("div", _hoisted_15, " $" + _toDisplayString(_ctx.formatNumber(_ctx.totalAmount)), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                      _createElementVNode("span", null, "門市銷售")
                    ], -1)),
                    _createElementVNode("div", _hoisted_19, " $" + _toDisplayString(_ctx.formatNumber(_ctx.totalStoreAmount)), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                      _createElementVNode("span", null, "線上店取付款")
                    ], -1)),
                    _createElementVNode("div", _hoisted_23, " $" + _toDisplayString(_ctx.formatNumber(_ctx.totalWebAmount)), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                      _createElementVNode("span", null, "短溢金額")
                    ], -1)),
                    _createElementVNode("div", _hoisted_27, " $" + _toDisplayString(_ctx.formatNumber(_ctx.totalOverShort)), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                      _createElementVNode("span", null, "總折扣額")
                    ], -1)),
                    _createElementVNode("div", _hoisted_32, " $" + _toDisplayString(_ctx.formatNumber(_ctx.totalDiscount)), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, [
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                      _createElementVNode("span", null, "門市訂金總額")
                    ], -1)),
                    _createElementVNode("div", _hoisted_36, " $" + _toDisplayString(_ctx.formatNumber(_ctx.totalStorePreAmount)), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                      _createElementVNode("span", null, "寄存訂單總額")
                    ], -1)),
                    _createElementVNode("div", _hoisted_40, " $" + _toDisplayString(_ctx.formatNumber(_ctx.totalStorePartialPickupAmount)), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("div", _hoisted_43, [
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "c-gradientCard__desc" }, [
                      _createElementVNode("span", null, "作廢發票數")
                    ], -1)),
                    _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.totalVoidQuantity), 1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["l-section l-section--md", { 'is-open': _ctx.isActiveSection('結算人員') }])
      }, [
        _createElementVNode("div", _hoisted_45, [
          _createElementVNode("div", _hoisted_46, [
            _createElementVNode("div", _hoisted_47, [
              _createElementVNode("i", {
                class: _normalizeClass(["icon-minus c-icon u-pointer", [_ctx.isActiveSection('結算人員') ? 'icon-minus' : 'icon-add']]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleActiveSection('結算人員')))
              }, null, 2)
            ]),
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h2", { class: "c-heading c-heading--fsSm" }, "結算人員")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("table", _hoisted_50, [
              _cache[17] || (_cache[17] = _createElementVNode("colgroup", null, [
                _createElementVNode("col"),
                _createElementVNode("col", { width: "200px" }),
                _createElementVNode("col", { width: "200px" })
              ], -1)),
              _cache[18] || (_cache[18] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "操作時間"),
                  _createElementVNode("th", null, "經手人員"),
                  _createElementVNode("th", null, "設備名稱")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.handOverRecords, (handOverRecord, idx) => {
                  return (_openBlock(), _createElementBlock("tr", { key: idx }, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatDateTime(handOverRecord.createdAt)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getEmployeeName(handOverRecord.employeeNo)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.getDeviceName(handOverRecord.deviceId)), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["l-section l-section--md", { 'is-open': _ctx.isActiveSection('訂單資訊') }])
      }, [
        _createElementVNode("div", _hoisted_51, [
          _createElementVNode("div", _hoisted_52, [
            _createElementVNode("div", _hoisted_53, [
              _createElementVNode("i", {
                class: _normalizeClass(["icon-minus c-icon u-pointer", [_ctx.isActiveSection('訂單資訊') ? 'icon-minus' : 'icon-add']]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleActiveSection('訂單資訊')))
              }, null, 2)
            ]),
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h2", { class: "c-heading c-heading--fsSm" }, "訂單資訊")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_54, [
          _createElementVNode("div", _hoisted_55, [
            _createElementVNode("table", _hoisted_56, [
              _cache[20] || (_cache[20] = _createElementVNode("colgroup", null, [
                _createElementVNode("col"),
                _createElementVNode("col", { width: "200px" }),
                _createElementVNode("col", { width: "200px" }),
                _createElementVNode("col", { width: "200px" })
              ], -1)),
              _cache[21] || (_cache[21] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "項目"),
                  _createElementVNode("th", null, "訂單成立數"),
                  _createElementVNode("th", null, "訂單作廢數"),
                  _createElementVNode("th", null, "作廢總金額")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderRecords, (orderRecord, orderType) => {
                  return (_openBlock(), _createElementBlock("tr", { key: orderType }, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.orderTypesMap[orderType] ?? orderType), 1),
                    _createElementVNode("td", null, _toDisplayString(orderRecord.quantity), 1),
                    _createElementVNode("td", null, _toDisplayString(orderRecord.voided_quantity), 1),
                    _createElementVNode("td", null, "$" + _toDisplayString(_ctx.formatNumber(orderRecord.voided_amount)), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["l-section l-section--md", { 'is-open': _ctx.isActiveSection('折扣資訊') }])
      }, [
        _createElementVNode("div", _hoisted_57, [
          _createElementVNode("div", _hoisted_58, [
            _createElementVNode("div", _hoisted_59, [
              _createElementVNode("i", {
                class: _normalizeClass(["icon-minus c-icon u-pointer", [_ctx.isActiveSection('折扣資訊') ? 'icon-minus' : 'icon-add']]),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleActiveSection('折扣資訊')))
              }, null, 2)
            ]),
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h2", { class: "c-heading c-heading--fsSm" }, "折扣資訊")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_60, [
          _createElementVNode("div", _hoisted_61, [
            _createElementVNode("table", _hoisted_62, [
              _cache[23] || (_cache[23] = _createElementVNode("colgroup", null, [
                _createElementVNode("col"),
                _createElementVNode("col", { width: "200px" })
              ], -1)),
              _cache[24] || (_cache[24] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "項目"),
                  _createElementVNode("th", null, "折扣金額")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.discountRecords, (discountRecord, discountType) => {
                  return (_openBlock(), _createElementBlock("tr", { key: discountType }, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.discountsMap[discountType] ?? discountType), 1),
                    _createElementVNode("td", null, " $" + _toDisplayString(_ctx.formatNumber(
                      discountRecord.amount - discountRecord.voided_amount
                    )), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["l-section l-section--md", { 'is-open': _ctx.isActiveSection('錢櫃資訊') }])
      }, [
        _createElementVNode("div", _hoisted_63, [
          _createElementVNode("div", _hoisted_64, [
            _createElementVNode("div", _hoisted_65, [
              _createElementVNode("i", {
                class: _normalizeClass(["icon-minus c-icon u-pointer", [_ctx.isActiveSection('錢櫃資訊') ? 'icon-minus' : 'icon-add']]),
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleActiveSection('錢櫃資訊')))
              }, null, 2)
            ]),
            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h2", { class: "c-heading c-heading--fsSm" }, "錢櫃資訊")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_66, [
          _createElementVNode("div", _hoisted_67, [
            _createElementVNode("table", _hoisted_68, [
              _cache[26] || (_cache[26] = _createElementVNode("colgroup", null, [
                _createElementVNode("col"),
                _createElementVNode("col", { width: "200px" }),
                _createElementVNode("col", { width: "200px" })
              ], -1)),
              _cache[27] || (_cache[27] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "營收總金額"),
                  _createElementVNode("th", null, "取出金額"),
                  _createElementVNode("th", null, "留存金額")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.handOverRecords, (handOverRecord, idx) => {
                  return (_openBlock(), _createElementBlock("tr", { key: idx }, [
                    _createElementVNode("td", null, "$" + _toDisplayString(_ctx.formatNumber(handOverRecord.totalCash)), 1),
                    _createElementVNode("td", null, "$" + _toDisplayString(_ctx.formatNumber(handOverRecord.withdrawCash)), 1),
                    _createElementVNode("td", null, " $" + _toDisplayString(_ctx.formatNumber(
                      handOverRecord.totalCash - handOverRecord.withdrawCash
                    )), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["l-section l-section--md", { 'is-open': _ctx.isActiveSection('支付資訊') }])
      }, [
        _createElementVNode("div", _hoisted_69, [
          _createElementVNode("div", _hoisted_70, [
            _createElementVNode("div", _hoisted_71, [
              _createElementVNode("i", {
                class: _normalizeClass(["icon-minus c-icon u-pointer", [_ctx.isActiveSection('支付資訊') ? 'icon-minus' : 'icon-add']]),
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleActiveSection('支付資訊')))
              }, null, 2)
            ]),
            _cache[28] || (_cache[28] = _createElementVNode("div", { class: "l-section__title" }, [
              _createElementVNode("h2", { class: "c-heading c-heading--fsSm" }, "支付資訊")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_72, [
          _createElementVNode("div", _hoisted_73, [
            _createElementVNode("table", _hoisted_74, [
              _cache[29] || (_cache[29] = _createElementVNode("colgroup", null, [
                _createElementVNode("col"),
                _createElementVNode("col", { width: "200px" }),
                _createElementVNode("col", { width: "200px" }),
                _createElementVNode("col")
              ], -1)),
              _cache[30] || (_cache[30] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "項目"),
                  _createElementVNode("th", null, "應收金額"),
                  _createElementVNode("th", null, "短溢金額"),
                  _createElementVNode("th", null, "短溢原因")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentRecords, (paymentRecord, paymentType) => {
                  return (_openBlock(), _createElementBlock("tr", { key: paymentType }, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.paymentsMap[paymentType] ?? paymentType), 1),
                    _createElementVNode("td", null, " $" + _toDisplayString(_ctx.formatNumber(
                      paymentRecord.amount - paymentRecord.voided_amount
                    )), 1),
                    _createElementVNode("td", null, "$" + _toDisplayString(_ctx.formatNumber(paymentRecord.over_short)), 1),
                    _createElementVNode("td", null, _toDisplayString(paymentRecord.memo.join('/')), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ], 2)
    ])
  ], 64))
}