import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-mutiTable__input" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("input", {
          type: "text",
          value: _ctx.device.name,
          class: "c-textBox",
          disabled: ""
        }, null, 8, _hoisted_2)
      ])
    ]),
    _createElementVNode("td", null, _toDisplayString(_ctx.device.ipadId), 1),
    _cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("td", null, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("td", null, null, -1)),
    _cache[4] || (_cache[4] = _createElementVNode("td", { class: "u-textCenter" }, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("td", { class: "u-textCenter" }, null, -1))
  ]))
}