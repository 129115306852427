import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/logo/waca_logo.svg'
import _imports_1 from '@/assets/images/logo/waca_logo_wt.svg'


const _hoisted_1 = { class: "l-container l-container--login" }
const _hoisted_2 = {
  key: 0,
  class: "l-login"
}
const _hoisted_3 = { class: "l-login__wrap" }
const _hoisted_4 = { class: "l-login__area" }
const _hoisted_5 = { class: "c-login" }
const _hoisted_6 = { class: "c-login__content" }
const _hoisted_7 = { class: "c-login__box" }
const _hoisted_8 = { class: "c-login__formGroup" }
const _hoisted_9 = { class: "c-login__input" }
const _hoisted_10 = { class: "c-login__btn u-textCenter" }
const _hoisted_11 = { class: "l-actions" }
const _hoisted_12 = { class: "l-actions__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_modal),
    _cache[6] || (_cache[6] = _createElementVNode("header", { class: "l-header" }, [
      _createElementVNode("div", { class: "l-header__item" }, [
        _createElementVNode("div", { class: "l-header__wrap l-header__wrap--login" }, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "c-logo",
            alt: ""
          })
        ])
      ])
    ], -1)),
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"c-login__head\"><div class=\"c-login__main\"><div class=\"c-login__logo\"><img src=\"" + _imports_1 + "\" alt=\"logo\"></div></div><div class=\"c-login__desc\">檔案下載</div></div>", 1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", null, "檔案類型: " + _toDisplayString(_ctx.fileType), 1),
                        _createElementVNode("div", null, "檔案名稱: " + _toDisplayString(_ctx.filePrefix), 1),
                        _createElementVNode("div", null, "下載期限: " + _toDisplayString(_ctx.deadTime), 1),
                        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                        _withDirectives(_createElementVNode("input", {
                          type: "number",
                          class: "c-textBox c-textBox--full",
                          placeholder: "請輸入員工識別碼",
                          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputPassword && _ctx.inputPassword(...args))),
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
                        }, null, 544), [
                          [_vModelText, _ctx.password]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("button", {
                            class: "c-btn c-btn--primary",
                            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)))
                          }, _cache[4] || (_cache[4] = [
                            _createElementVNode("span", { class: "c-btn__icon" }, [
                              _createElementVNode("i", { class: "icon-lock_wt" })
                            ], -1),
                            _createElementVNode("span", null, "確定送出", -1)
                          ]))
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}