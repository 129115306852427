import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "c-tabContent c-tabContent--pvSm" }
const _hoisted_2 = { class: "l-section l-section--md" }
const _hoisted_3 = { class: "l-section__head" }
const _hoisted_4 = { class: "l-section__actions" }
const _hoisted_5 = { class: "l-section__content" }
const _hoisted_6 = { class: "l-table" }
const _hoisted_7 = { class: "c-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", null, [
            _createElementVNode("button", {
              type: "button",
              class: "c-btn c-btn--primary c-btn--thin",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("span", null, "Excel報表匯出", -1)
            ]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("table", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("colgroup", null, [
              _createElementVNode("col", { width: "204px" }),
              _createElementVNode("col", { width: "260px" }),
              _createElementVNode("col", { width: "240px" }),
              _createElementVNode("col")
            ], -1)),
            _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "次序"),
                _createElementVNode("th", null, "折扣-折抵名稱"),
                _createElementVNode("th", null, "折扣金額"),
                _createElementVNode("th", null, "折扣淨額佔比")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.discounts, (discount, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.discountsMap[discount.key] ?? discount.key), 1),
                  _createElementVNode("td", null, "$" + _toDisplayString(_ctx.formatNumber(discount.amount - discount.voidedAmount)), 1),
                  _createElementVNode("td", null, _toDisplayString(`${discount.percentage.toFixed(2)}%`), 1)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}