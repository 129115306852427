import { defineComponent as _defineComponent } from 'vue'
import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-suffix" }
const _hoisted_2 = { class: "c-suffix__input" }
const _hoisted_3 = ["type", "placeholder"]

import { ref, computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'passwordInput',
  props: {
  placeholder: {
    type: String
  },
  modelValue: {
    type: String
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const props = __props

const password = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
const passwordType = ref('password')

const changePasswordType = () => {
  if (passwordType.value === 'password') {
    passwordType.value = 'text'
  } else {
    passwordType.value = 'password'
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: passwordType.value,
        class: "c-textBox c-textBox--full c-textBox--suffix",
        placeholder: props.placeholder,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((password).value = $event)),
        autocomplete: "off"
      }, null, 8, _hoisted_3), [
        [_vModelDynamic, password.value]
      ])
    ]),
    _createElementVNode("div", {
      class: "c-suffix__icon u-pointer",
      onClick: changePasswordType
    }, [
      _createElementVNode("i", {
        class: _normalizeClass({
          'icon-eye_new_close': passwordType.value === 'password',
          'icon-eye_new_open': passwordType.value === 'text'
        })
      }, null, 2)
    ])
  ]))
}
}

})