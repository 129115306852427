import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/logo/waca_logo.svg'
import _imports_1 from '@/assets/images/logo/waca_logo_wt.svg'


const _hoisted_1 = { class: "l-container l-container--login" }
const _hoisted_2 = { class: "l-login" }
const _hoisted_3 = { class: "l-login__wrap" }
const _hoisted_4 = {
  key: 0,
  class: "c-login"
}
const _hoisted_5 = {
  key: 0,
  class: "c-login__content"
}
const _hoisted_6 = { class: "c-login__box" }
const _hoisted_7 = { class: "c-checkbox" }
const _hoisted_8 = { class: "c-login__btn" }
const _hoisted_9 = { class: "l-actions u-w100" }
const _hoisted_10 = { class: "l-actions__item u-flex-grow-1" }
const _hoisted_11 = { class: "l-actions__item u-flex-grow-1" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = {
  key: 1,
  class: "c-login__content"
}
const _hoisted_14 = { class: "c-login__box" }
const _hoisted_15 = { class: "l-qrcode" }
const _hoisted_16 = { class: "c-qrcode" }
const _hoisted_17 = { class: "c-qrcode__qrcode" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "c-login__box" }
const _hoisted_20 = { class: "c-checkbox c-checkbox--init" }
const _hoisted_21 = { class: "c-login__btn" }
const _hoisted_22 = { class: "l-actions u-w100" }
const _hoisted_23 = { class: "l-actions__item u-flex-grow-1" }
const _hoisted_24 = { class: "l-actions__item u-flex-grow-1" }
const _hoisted_25 = ["disabled"]
const _hoisted_26 = {
  key: 2,
  class: "c-login__content"
}
const _hoisted_27 = { class: "c-login__box" }
const _hoisted_28 = {
  key: 0,
  class: "c-alertBox u-mvLg"
}
const _hoisted_29 = { class: "c-alertBox__text" }
const _hoisted_30 = { class: "c-login__formGroup u-mtLg u-mbXl" }
const _hoisted_31 = { class: "c-login__input" }
const _hoisted_32 = { class: "c-login__msg" }
const _hoisted_33 = { class: "c-login__forget" }
const _hoisted_34 = { class: "c-login__box" }
const _hoisted_35 = { class: "c-checkbox c-checkbox--init" }
const _hoisted_36 = { class: "c-login__btn" }
const _hoisted_37 = { class: "l-actions u-w100" }
const _hoisted_38 = { class: "l-actions__item u-flex-grow-1" }
const _hoisted_39 = { class: "l-actions__item u-flex-grow-1" }
const _hoisted_40 = ["disabled"]
const _hoisted_41 = {
  key: 1,
  class: "c-login"
}
const _hoisted_42 = { class: "c-login__content" }
const _hoisted_43 = { class: "c-login__box" }
const _hoisted_44 = {
  key: 0,
  class: "c-alertBox u-mbLg"
}
const _hoisted_45 = { class: "c-alertBox__text" }
const _hoisted_46 = {
  key: 1,
  class: "c-login__formGroup"
}
const _hoisted_47 = { class: "c-login__input" }
const _hoisted_48 = {
  key: 2,
  class: "c-info"
}
const _hoisted_49 = { class: "c-login__btn" }
const _hoisted_50 = { class: "l-actions u-w100" }
const _hoisted_51 = { class: "l-actions__item u-flex-grow-1" }
const _hoisted_52 = { class: "l-actions__item u-flex-grow-1" }
const _hoisted_53 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_ForgetPwdLightbox = _resolveComponent("ForgetPwdLightbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_modal),
      _cache[37] || (_cache[37] = _createElementVNode("header", { class: "l-header" }, [
        _createElementVNode("div", { class: "l-header__item" }, [
          _createElementVNode("div", { class: "l-header__wrap l-header__wrap--login" }, [
            _createElementVNode("img", {
              src: _imports_0,
              class: "c-logo",
              alt: ""
            })
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["l-login__area", { ' l-login__area--step': _ctx.isSetVertifiedCode }])
          }, [
            (_ctx.isSetVertifiedCode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _cache[29] || (_cache[29] = _createStaticVNode("<div class=\"c-login__head\"><div class=\"c-login__main\"><div class=\"c-login__logo\"><img src=\"" + _imports_1 + "\" alt=\"logo\"></div></div><div class=\"c-login__desc\">設定兩步驟驗證</div></div>", 1)),
                  (_ctx.step.current === 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _cache[18] || (_cache[18] = _createStaticVNode("<div class=\"c-login__box\"><ul class=\"l-stepBar\"><li><span class=\"c-stepBar is-active\">STEP1</span></li><li><span class=\"c-stepBar\">STEP2</span></li><li><span class=\"c-stepBar\">STEP3</span></li></ul><div class=\"c-info u-mbMd\"><p>請用手機下載</p><p>Google Authenticator APP</p></div><ul class=\"l-qrcode\"><li><div class=\"c-qrcode\"><div class=\"c-qrcode__qrcode\"><img class=\"google2fa_qrcode\" src=\"https://hinetcdn2.waca.ec/backend/images/img_ios_app.jpg?v=202203280225\" alt=\"\"></div><div class=\"c-qrcode__download\"><img src=\"https://hinetcdn2.waca.ec/backend/images/img_appstore.png?v=202203280225\"></div></div></li><li><div class=\"c-qrcode\"><div class=\"c-qrcode__qrcode\"><img class=\"google2fa_qrcode\" src=\"https://hinetcdn2.waca.ec/backend/images/img_android_app.jpg?v=202203280225\" alt=\"\"></div><div class=\"c-qrcode__download\"><img src=\"https://hinetcdn2.waca.ec/backend/images/img_googleplay.png?v=202203280225\"></div></div></li></ul></div>", 1)),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("label", _hoisted_7, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              class: "c-checkbox__input",
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.step.agreeCheck.step1) = $event))
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.step.agreeCheck.step1]
                            ]),
                            _cache[15] || (_cache[15] = _createElementVNode("span", { class: "c-checkbox__text" }, "我已成功下載安裝 Google Authenticator APP 至手機", -1))
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("button", {
                                  class: "c-btn c-btn--bu c-btn--full",
                                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setVertifiedCode(false)))
                                }, _cache[16] || (_cache[16] = [
                                  _createElementVNode("span", null, "上一步", -1)
                                ]))
                              ]),
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("button", {
                                  class: "c-btn c-btn--primary c-btn--full",
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.nextStep())),
                                  disabled: !_ctx.step.agreeCheck.step1
                                }, _cache[17] || (_cache[17] = [
                                  _createElementVNode("span", null, "下一步", -1)
                                ]), 8, _hoisted_12)
                              ])
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.step.current === 2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _cache[19] || (_cache[19] = _createStaticVNode("<ul class=\"l-stepBar\"><li><span class=\"c-stepBar is-active\">STEP1</span></li><li><span class=\"c-stepBar is-active\"> STEP2 </span></li><li><span class=\"c-stepBar\">STEP3</span></li></ul><div class=\"c-info u-mbMd\"><p>透過 Google Authenticator APP</p><p>掃描 QR Code 條碼</p></div>", 2)),
                          _createElementVNode("ul", _hoisted_15, [
                            _createElementVNode("li", null, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, [
                                  (_ctx.google2faUrl)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        class: "google2fa_qrcode",
                                        src: _ctx.google2faUrl,
                                        alt: "",
                                        loading: "eager"
                                      }, null, 8, _hoisted_18))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("label", _hoisted_20, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              class: "c-checkbox__input",
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.step.agreeCheck.step2) = $event))
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.step.agreeCheck.step2]
                            ]),
                            _cache[20] || (_cache[20] = _createElementVNode("span", { class: "c-checkbox__text" }, " 我已成功透過 Google Authenticator APP 掃描上方 QR Code ", -1))
                          ]),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("button", {
                                  class: "c-btn c-btn--bu c-btn--full",
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.backStep()))
                                }, _cache[21] || (_cache[21] = [
                                  _createElementVNode("span", null, "上一步", -1)
                                ]))
                              ]),
                              _createElementVNode("div", _hoisted_24, [
                                _createElementVNode("button", {
                                  class: "c-btn c-btn--primary c-btn--full",
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.nextStep())),
                                  disabled: !_ctx.step.agreeCheck.step2
                                }, _cache[22] || (_cache[22] = [
                                  _createElementVNode("span", null, "下一步", -1)
                                ]), 8, _hoisted_25)
                              ])
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.step.current === 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _cache[25] || (_cache[25] = _createStaticVNode("<ul class=\"l-stepBar\"><li><span class=\"c-stepBar is-active\">STEP1</span></li><li><span class=\"c-stepBar is-active\"> STEP2 </span></li><li><span class=\"c-stepBar is-active\">STEP3</span></li></ul><div class=\"c-info\"><p>為確認身份及保護您的帳號</p><p>請再次輸入您商店的登入密碼</p></div>", 2)),
                          (_ctx.bindCodeErrorMessage)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                _cache[23] || (_cache[23] = _createElementVNode("div", { class: "c-alertBox__icon" }, [
                                  _createElementVNode("i", { class: "icon-alert" })
                                ], -1)),
                                _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.bindCodeErrorMessage), 1)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_30, [
                            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "c-login__title" }, "商店登入密碼", -1)),
                            _createElementVNode("div", _hoisted_31, [
                              _createVNode(_component_PasswordInput, {
                                placeholder: "請輸入商店登入密碼 (必填)",
                                modelValue: _ctx.confirmPwdInfo.password,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.confirmPwdInfo.password) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_32, [
                              _createElementVNode("div", _hoisted_33, [
                                _createElementVNode("a", {
                                  href: "#",
                                  class: "c-link",
                                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.forgetPwdRef.lightboxRef.open && _ctx.forgetPwdRef.lightboxRef.open(...args)))
                                }, "忘記密碼?")
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("label", _hoisted_35, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              class: "c-checkbox__input",
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.step.agreeCheck.step3) = $event))
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.step.agreeCheck.step3]
                            ]),
                            _cache[26] || (_cache[26] = _createElementVNode("span", { class: "c-checkbox__text" }, " 我知道下次登入時，要輸入六位數的兩步驟驗證碼 ", -1))
                          ]),
                          _createElementVNode("div", _hoisted_36, [
                            _createElementVNode("div", _hoisted_37, [
                              _createElementVNode("div", _hoisted_38, [
                                _createElementVNode("button", {
                                  class: "c-btn c-btn--bu c-btn--full",
                                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.backStep()))
                                }, _cache[27] || (_cache[27] = [
                                  _createElementVNode("span", null, "上一步", -1)
                                ]))
                              ]),
                              _createElementVNode("div", _hoisted_39, [
                                _createElementVNode("button", {
                                  class: "c-btn c-btn--primary c-btn--full",
                                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.bindVertifiedCode())),
                                  disabled: 
                          !_ctx.step.agreeCheck.step3 ||
                          _ctx.confirmPwdInfo.password.trim() === ''
                        
                                }, _cache[28] || (_cache[28] = [
                                  _createElementVNode("span", null, "確定綁定", -1)
                                ]), 8, _hoisted_40)
                              ])
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_41, [
                  _cache[36] || (_cache[36] = _createStaticVNode("<div class=\"c-login__head\"><div class=\"c-login__main\"><div class=\"c-login__logo\"><img src=\"" + _imports_1 + "\" alt=\"logo\"></div></div><div class=\"c-login__desc\">兩步驟驗證碼</div></div>", 1)),
                  _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("div", _hoisted_43, [
                      (_ctx.submitCodeErrorMessage)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                            _cache[30] || (_cache[30] = _createElementVNode("div", { class: "c-alertBox__icon" }, [
                              _createElementVNode("i", { class: "icon-alert" })
                            ], -1)),
                            _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.submitCodeErrorMessage), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.hasVertifiedCode)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                            _cache[31] || (_cache[31] = _createElementVNode("div", { class: "c-login__title" }, " 輸入Google Authenticator 所產生的驗證碼 ", -1)),
                            _createElementVNode("div", _hoisted_47, [
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "c-textBox",
                                placeholder: "請輸入兩步驟驗證碼",
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.vertifiedCodeInfo.code) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.vertifiedCodeInfo.code]
                              ])
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_48, _cache[32] || (_cache[32] = [
                            _createElementVNode("p", null, "請先設定兩步驟驗證", -1),
                            _createElementVNode("p", null, "才能使用Google帳號登入喔!", -1)
                          ]))),
                      _createElementVNode("div", _hoisted_49, [
                        _createElementVNode("div", _hoisted_50, [
                          _createElementVNode("div", _hoisted_51, [
                            _createElementVNode("button", {
                              class: "c-btn c-btn--bu c-btn--full g_id_signout",
                              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                            }, _cache[33] || (_cache[33] = [
                              _createElementVNode("span", null, "登出", -1)
                            ]))
                          ]),
                          _createElementVNode("div", _hoisted_52, [
                            (_ctx.hasVertifiedCode)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  class: "c-btn c-btn--primary c-btn--full",
                                  onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.submitVertifiedCode && _ctx.submitVertifiedCode(...args))),
                                  disabled: _ctx.vertifiedCodeInfo.code.trim() === ''
                                }, _cache[34] || (_cache[34] = [
                                  _createElementVNode("span", null, "確定送出", -1)
                                ]), 8, _hoisted_53))
                              : (_openBlock(), _createElementBlock("button", {
                                  key: 1,
                                  class: "c-btn c-btn--primary c-btn--full",
                                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setVertifiedCode(true)))
                                }, _cache[35] || (_cache[35] = [
                                  _createElementVNode("span", null, "立即設定", -1)
                                ])))
                          ])
                        ])
                      ])
                    ])
                  ])
                ]))
          ], 2)
        ])
      ])
    ]),
    _createVNode(_component_ForgetPwdLightbox, { ref: "forgetPwdRef" }, null, 512)
  ], 64))
}