
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    modelValue: { type: Boolean, required: true },
    title: { type: String, required: true },
    closeAble: { type: Boolean, default: true },
    confirmBtn: { type: Boolean, default: false },
    cancelBtn: { type: Boolean, default: true },
    confirmBtnText: { type: String, default: '確定' },
    cancelBtnText: { type: String, default: '取消' },
    propConfirmEvent: { type: Function, default: () => ({}) },
    propCancelEvent: { type: Function, default: () => ({}) }
  },
  setup(props, { emit }) {
    const show = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })

    const handleCancel = async () => {
      await props.propCancelEvent()
      show.value = false
      console.log('cancel3')
    }

    const handleConfirm = async () => {
      await props.propConfirmEvent()
      show.value = false
    }

    return {
      show,
      handleCancel,
      handleConfirm
    }
  }
})
