import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/google.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "c-btn c-btn--google c-btn--full",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)))
  }, _cache[1] || (_cache[1] = [
    _createElementVNode("span", { class: "c-btn__icon" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "google"
      })
    ], -1),
    _createElementVNode("span", null, "Google 登入", -1)
  ])))
}