import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "u-textGy80" }
const _hoisted_2 = {
  key: 0,
  class: "u-mlXl"
}
const _hoisted_3 = { class: "c-switch" }
const _hoisted_4 = ["value", "checked", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Collapse = _resolveComponent("Collapse")!
  const _component_listBox = _resolveComponent("listBox")!
  const _component_Fixed = _resolveComponent("Fixed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "c-note u-mbSm u-textRight" }, " ※權限開啟時，操作APP相關功能需輸入員工識別碼。 ", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, categoryIndex) => {
      return (_openBlock(), _createBlock(_component_listBox, {
        key: categoryIndex,
        title: category.title,
        lists: category.aclItems,
        hasCollapse: true
      }, {
        minorSide: _withCtx(({ row }) => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.allowUserName(row)), 1),
          (_ctx.supportSwitchAcls.includes(row.id))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("label", _hoisted_3, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    class: "c-switch__input",
                    value: row.id,
                    checked: !_ctx.exceptionAcls.includes(row.id),
                    onChange: ($event: any) => (_ctx.toggleExceptionAcl(row.id))
                  }, null, 40, _hoisted_4),
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "c-switch__box" }, null, -1))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        collapseContent: _withCtx(({ row }) => [
          _createVNode(_component_Collapse, {
            "prop-acl-item": row,
            "prop-employees": _ctx.propEmployees,
            "prop-groups": _ctx.propGroups,
            "prop-allow-users": _ctx.allowUsers[row.id],
            "onUpdate:propAllowUsers": ($event: any) => ((_ctx.allowUsers[row.id]) = $event)
          }, null, 8, ["prop-acl-item", "prop-employees", "prop-groups", "prop-allow-users", "onUpdate:propAllowUsers"])
        ]),
        _: 2
      }, 1032, ["title", "lists"]))
    }), 128)),
    _createVNode(_component_Fixed, {
      onSave: _ctx.save,
      onCancel: _ctx.cancel
    }, null, 8, ["onSave", "onCancel"])
  ], 64))
}