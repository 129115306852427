import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "l-card l-card--pvSm" }
const _hoisted_2 = { class: "l-section l-section--md" }
const _hoisted_3 = { class: "l-section__head l-section__head--underLine" }
const _hoisted_4 = { class: "l-section__actions" }
const _hoisted_5 = { class: "l-section__content" }
const _hoisted_6 = { class: "l-table" }
const _hoisted_7 = { class: "c-table" }
const _hoisted_8 = { class: "l-tool" }
const _hoisted_9 = { class: "c-pager" }
const _hoisted_10 = { class: "c-pager__content" }
const _hoisted_11 = { class: "c-pager__select" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "c-pager__totalNum" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "l-section__main" }, [
          _createElementVNode("div", { class: "l-section__title u-textRight" }, [
            _createElementVNode("span", { class: "u-textRight u-textRd60" }, "此列表僅門市相關訂單之統計。 ")
          ])
        ], -1)),
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", null, [
            _createElementVNode("button", {
              type: "button",
              class: "c-btn c-btn--primary c-btn--thin",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportExcelClick && _ctx.exportExcelClick(...args)))
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("span", null, "Excel報表匯出", -1)
            ]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("table", _hoisted_7, [
            _cache[7] || (_cache[7] = _createElementVNode("colgroup", null, [
              _createElementVNode("col", { width: "170px" }),
              _createElementVNode("col", { width: "170px" }),
              _createElementVNode("col", { width: "170px" }),
              _createElementVNode("col", { width: "170px" }),
              _createElementVNode("col", { width: "170px" }),
              _createElementVNode("col", { width: "170px" }),
              _createElementVNode("col", { width: "170px" }),
              _createElementVNode("col", { width: "170px" }),
              _createElementVNode("col", { width: "170px" }),
              _createElementVNode("col", { width: "100px" })
            ], -1)),
            _cache[8] || (_cache[8] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "訂單編號"),
                _createElementVNode("th", null, "結帳時間"),
                _createElementVNode("th", null, "發票號碼"),
                _createElementVNode("th", null, "發票金額"),
                _createElementVNode("th", null, "銷售額"),
                _createElementVNode("th", null, "稅額"),
                _createElementVNode("th", null, "稅別"),
                _createElementVNode("th", null, "載具號碼"),
                _createElementVNode("th", null, "愛心碼")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.invoiceRecords, (invoiceRecord, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: `${_ctx.paginationData.page}_${index}_${invoiceRecord.orderNo}`
                }, [
                  _createElementVNode("td", null, _toDisplayString(invoiceRecord.orderNo), 1),
                  _createElementVNode("td", null, _toDisplayString(invoiceRecord.createdAt), 1),
                  _createElementVNode("td", null, _toDisplayString(invoiceRecord.invoiceNumber), 1),
                  _createElementVNode("td", null, "＄" + _toDisplayString(_ctx.formatNumber(invoiceRecord.invoiceAmount)), 1),
                  _createElementVNode("td", null, "＄" + _toDisplayString(_ctx.formatNumber(invoiceRecord.salesAmount)), 1),
                  _createElementVNode("td", null, "＄" + _toDisplayString(_ctx.formatNumber(invoiceRecord.taxAmount)), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.taxTypeMap[invoiceRecord.taxType] ?? ''), 1),
                  _createElementVNode("td", null, _toDisplayString(invoiceRecord.carrierId), 1),
                  _createElementVNode("td", null, _toDisplayString(invoiceRecord.npoban), 1)
                ]))
              }), 128))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", {
            class: "c-pager__arrow",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prePage && _ctx.prePage(...args)))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["icon-arrow_left c-icon c-icon--fsSm", [
                _ctx.paginationData.page > 1
                  ? 'u-pointer c-icon--gy60'
                  : 'u-notAllowed c-icon--gy40'
              ]])
            }, null, 2)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("select", {
                class: "c-toolSelect",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.paginationData.page) = $event)),
                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goPage($event.target.value)))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.lastPage, (page) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: page,
                    key: page
                  }, _toDisplayString(page), 9, _hoisted_12))
                }), 128))
              ], 544), [
                [_vModelSelect, _ctx.paginationData.page]
              ])
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "c-pager__text" }, "頁", -1)),
            _cache[10] || (_cache[10] = _createElementVNode("span", { class: "c-pager__separator" }, "，", -1)),
            _cache[11] || (_cache[11] = _createElementVNode("span", { class: "c-pager__text" }, "共", -1)),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.paginationData.total), 1),
            _cache[12] || (_cache[12] = _createElementVNode("span", { class: "c-pager__text" }, "筆", -1))
          ]),
          _createElementVNode("span", {
            class: "c-pager__arrow",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["icon-arrow_right c-icon c-icon--fsSm", [
                _ctx.paginationData.page < _ctx.paginationData.lastPage
                  ? 'u-pointer c-icon--gy60'
                  : 'u-notAllowed c-icon--gy40'
              ]])
            }, null, 2)
          ])
        ])
      ])
    ])
  ]))
}