import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-settingTabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        to: { name: 'storeMarketing_memberLogin' },
        class: _normalizeClass(["c-settingTabs__panel", { 'is-active': _ctx.route.name === 'storeMarketing_memberLogin' }])
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "c-settingTabs__main" }, [
            _createElementVNode("span", { class: "c-settingTabs__title" }, "會員註冊")
          ], -1)
        ])),
        _: 1
      }, 8, ["class"])
    ])
  ]))
}