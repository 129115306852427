import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "l-section" }
const _hoisted_2 = { class: "l-section__content" }
const _hoisted_3 = { class: "l-card" }
const _hoisted_4 = { class: "c-outlineList" }
const _hoisted_5 = { class: "c-outlineList__head" }
const _hoisted_6 = { class: "c-outlineList__update" }
const _hoisted_7 = { class: "c-outlineList__reload" }
const _hoisted_8 = { class: "c-outlineList__list" }
const _hoisted_9 = { class: "c-outlineList__status" }
const _hoisted_10 = { class: "c-outlineStatus__text" }
const _hoisted_11 = { class: "c-outlineList__main c-outlineList__main--vertical" }
const _hoisted_12 = { class: "c-outlineList__info" }
const _hoisted_13 = { class: "c-outlineList__desc" }
const _hoisted_14 = {
  key: 0,
  class: "c-outlineList__edit"
}
const _hoisted_15 = { class: "c-outlineList__desc" }
const _hoisted_16 = { class: "c-outlineList__action" }
const _hoisted_17 = { class: "l-actions l-actions--gSm" }
const _hoisted_18 = { class: "l-actions__item" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "l-actions__item" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = {
  key: 1,
  class: "c-outlineList__edit"
}
const _hoisted_23 = { class: "c-outlineList__action" }
const _hoisted_24 = { class: "l-actions l-actions--gSm" }
const _hoisted_25 = { class: "l-actions__item" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "l-tool" }
const _hoisted_28 = { class: "c-pager" }
const _hoisted_29 = { class: "c-pager__content" }
const _hoisted_30 = { class: "c-pager__select" }
const _hoisted_31 = ["value"]
const _hoisted_32 = { class: "c-pager__totalNum" }
const _hoisted_33 = { class: "c-modal__header" }
const _hoisted_34 = { class: "c-modal__title" }
const _hoisted_35 = { class: "u-mbSm" }
const _hoisted_36 = { class: "u-mbSm" }
const _hoisted_37 = {
  key: 0,
  class: "u-mbSm"
}
const _hoisted_38 = { class: "u-bold u-textRd60" }
const _hoisted_39 = {
  key: 0,
  class: "u-mbSm"
}
const _hoisted_40 = {
  key: 1,
  class: "u-mbSm"
}
const _hoisted_41 = {
  key: 2,
  class: "u-mbSm"
}
const _hoisted_42 = {
  key: 3,
  class: "u-mbSm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lightbox = _resolveComponent("Lightbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "l-section__head" }, [
        _createElementVNode("div", { class: "l-section__main" }, [
          _createElementVNode("div", { class: "l-section__title" }, [
            _createElementVNode("h1", { class: "c-heading" }, "歷程紀錄")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "c-outlineList__main" }, [
                _createElementVNode("span", { class: "c-outlineList__subject" }, "批次處理作業")
              ], -1)),
              _createElementVNode("div", _hoisted_6, "最近更新時間：" + _toDisplayString(_ctx.now), 1),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("i", {
                  class: "icon-reload c-icon c-icon--fsSm u-pointer",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args)))
                })
              ])
            ]),
            _createElementVNode("ul", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.jobRecords, (jobRecord, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: `${_ctx.paginationData.page}_${index}`
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["c-outlineList__item", { 'is-disabled': jobRecord.status !== 3 }])
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["c-outlineStatus", _ctx.statusMap[jobRecord.status].class])
                      }, [
                        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.statusMap[jobRecord.status].text), 1)
                      ], 2)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(jobRecord.name), 1),
                      _createElementVNode("span", _hoisted_13, " -於 " + _toDisplayString(jobRecord.createdAt) + " 提出請求 ", 1)
                    ]),
                    (jobRecord.status === 3)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createElementVNode("span", _hoisted_15, " (檢視期限 ~ " + _toDisplayString(jobRecord.deadAt) + ") ", 1),
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "c-btn c-btn--primary c-btn--thin",
                                  onClick: ($event: any) => (_ctx.download(jobRecord))
                                }, _cache[6] || (_cache[6] = [
                                  _createElementVNode("span", null, "下載", -1)
                                ]), 8, _hoisted_19)
                              ]),
                              _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "c-btn c-btn--gn c-btn--thin",
                                  onClick: ($event: any) => (_ctx.preview(jobRecord))
                                }, _cache[7] || (_cache[7] = [
                                  _createElementVNode("span", null, "檢視", -1)
                                ]), 8, _hoisted_21)
                              ])
                            ])
                          ])
                        ]))
                      : (jobRecord.status === 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                            _createElementVNode("div", _hoisted_23, [
                              _createElementVNode("div", _hoisted_24, [
                                _createElementVNode("div", _hoisted_25, [
                                  _createElementVNode("button", {
                                    type: "button",
                                    class: "c-btn c-btn--rd c-btn--thin",
                                    onClick: ($event: any) => (_ctx.cancel(jobRecord))
                                  }, _cache[8] || (_cache[8] = [
                                    _createElementVNode("span", null, "取消", -1)
                                  ]), 8, _hoisted_26)
                                ])
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                  ], 2)
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("span", {
                class: "c-pager__arrow",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prePage && _ctx.prePage(...args)))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["icon-arrow_left c-icon c-icon--fsSm", [
                  _ctx.paginationData.page > 1
                    ? 'u-pointer c-icon--gy60'
                    : 'u-notAllowed c-icon--gy40'
                ]])
                }, null, 2)
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _withDirectives(_createElementVNode("select", {
                    class: "c-toolSelect",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.paginationData.page) = $event)),
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goPage($event.target.value)))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.lastPage, (page) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: page,
                        key: page
                      }, _toDisplayString(page), 9, _hoisted_31))
                    }), 128))
                  ], 544), [
                    [_vModelSelect, _ctx.paginationData.page]
                  ])
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "c-pager__text" }, "頁", -1)),
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "c-pager__separator" }, "，", -1)),
                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "c-pager__text" }, "共", -1)),
                _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.paginationData.total), 1),
                _cache[12] || (_cache[12] = _createElementVNode("span", { class: "c-pager__text" }, "筆", -1))
              ]),
              _createElementVNode("span", {
                class: "c-pager__arrow",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["icon-arrow_right c-icon c-icon--fsSm", [
                  _ctx.paginationData.page < _ctx.paginationData.lastPage
                    ? 'u-pointer c-icon--gy60'
                    : 'u-notAllowed c-icon--gy40'
                ]])
                }, null, 2)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Lightbox, _mergeProps({ ref: "previewRef" }, _ctx.previewModal), {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.previewJobRecord.name), 1)
        ])
      ]),
      content: _withCtx(() => [
        _createElementVNode("p", _hoisted_35, "操作帳號：" + _toDisplayString(_ctx.previewJobRecord.shopAdminEmail), 1),
        _createElementVNode("p", _hoisted_36, "產生時間：" + _toDisplayString(_ctx.previewJobRecord.updatedAt), 1),
        (_ctx.previewJobRecord.total !== null)
          ? (_openBlock(), _createElementBlock("p", _hoisted_37, [
              _cache[14] || (_cache[14] = _createTextVNode(" 匯出筆數：共 ")),
              _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.previewJobRecord.total), 1),
              _cache[15] || (_cache[15] = _createTextVNode(" 筆 "))
            ]))
          : _createCommentVNode("", true),
        (_ctx.previewJobRecord.request !== null)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (
            _ctx.previewJobRecord.request.startTime &&
            _ctx.previewJobRecord.request.endTime
          )
                ? (_openBlock(), _createElementBlock("p", _hoisted_39, " 搜尋時間區隔：" + _toDisplayString(_ctx.previewJobRecord.request.startTime) + " - " + _toDisplayString(_ctx.previewJobRecord.request.endTime), 1))
                : _createCommentVNode("", true),
              (_ctx.previewJobRecord.request.detailItemKey)
                ? (_openBlock(), _createElementBlock("p", _hoisted_40, " 支付方式：" + _toDisplayString(_ctx.paymentsMap[_ctx.previewJobRecord.request.detailItemKey] ??
            _ctx.previewJobRecord.request.detailItemKey), 1))
                : _createCommentVNode("", true),
              (_ctx.previewJobRecord.request.date)
                ? (_openBlock(), _createElementBlock("p", _hoisted_41, " 搜尋時間：" + _toDisplayString(_ctx.previewJobRecord.request.date), 1))
                : _createCommentVNode("", true),
              (_ctx.previewJobRecord.request.employeeNo)
                ? (_openBlock(), _createElementBlock("p", _hoisted_42, " 搜尋員工：" + _toDisplayString(_ctx.getEmployeeName(_ctx.previewJobRecord.request.employeeNo)), 1))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("p", null, " 操作平台：" + _toDisplayString(_ctx.previewJobRecord.platform) + " " + _toDisplayString(_ctx.previewJobRecord.platformVersion), 1),
        _createElementVNode("p", null, "瀏覽器：" + _toDisplayString(_ctx.previewJobRecord.browser), 1),
        _createElementVNode("p", null, "裝置：" + _toDisplayString(_ctx.previewJobRecord.device), 1)
      ]),
      _: 1
    }, 16)
  ], 64))
}