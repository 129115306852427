import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'storeActivity_coupon' }]),
        to: { name: 'storeActivity_coupon' }
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, "優惠券", -1)
        ])),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'storeActivity_promotionsN' }]),
        to: { name: 'storeActivity_promotionsN' }
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("span", null, "任選活動", -1)
        ])),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        class: _normalizeClass(["c-tabs__panel", { 'is-active': _ctx.route.name === 'storeActivity_promotionsAB' }]),
        to: { name: 'storeActivity_promotionsAB' }
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("span", null, "紅配綠", -1)
        ])),
        _: 1
      }, 8, ["class"])
    ])
  ]))
}